/* Loader Csss */

.loader {
  width: 100px;
  height: 100px;
  border: 12px solid rgba(71, 173, 29, 0.1);
  border-bottom-color: #47ad1d;
  border-radius: 100%;
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-container {
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 999999;
  position: fixed;
}

@media (max-width: 1024px) {
  .loader {
    top: 45%;
    left: 45%;
  }
}

@media (max-width: 600px) {
  .loader {
    top: 40%;
    left: 35%;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Toastify__toast {
  padding: 32px 53px 28px 68px !important;
}

.Toastify__toast-container {
  z-index: 99999999999 !important;
  width: auto !important;
  max-width: 600px !important;
}

.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 0px !important;
}

.alert .alert-close-icon,
.alert .alert-error-fix,
.alert .alert-warning-fix,
.alert .alert-success-icon {
  top: 30px !important;
}

.alert-container {
  max-width: 675px;
  position: fixed;
  top: 168px;
  right: 45px;
  z-index: 999999999;
}

.alert {
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 400;
  border: 0;
  border-radius: 4px;
  margin-bottom: 21px;
  padding: 20px 72px;
  position: relative;
  box-shadow: 0px 5px 30px -2px rgba(0, 0, 0, 0.08);
  color: #000;
  background: #fff;
}

.alert h5 {
  font-size: 15px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 0px;
}

.alert p {
  margin-bottom: 0;
}

.alert .alert-icon {
  margin-right: 0;
  position: absolute;
  left: 24px;
  top: 18px;
}

.alert .alert-right {
  padding-left: 0;
  margin-left: auto;
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 25px;
  display: flex;
  width: 15px;
}

.alert .alert-close img {
  width: 100%;
}

.alert .alert-close {
  font-size: 1rem;
  line-height: 24px;
  font-weight: 300;
  width: 100%;
}

.tooltip-signin-error {
  position: absolute;
  right: 1%;
  top: 20%;
}

.padding-us-states {
  padding-left: 20px !important;
}

.view-all {
  color: #47ad1d !important;
}

.no-pointer {
  pointer-events: none !important;
  cursor: auto !important;
}

.standard-black {
  color: #1e1e1e !important;
}

.accor-font-us {
  font-size: 14px !important;
}

.ei-sideregion .accordion-button::after {
  height: 18px !important;
  width: 18px !important;
  background-size: 100% !important;
}

.down-arrow {
  transform: rotate(180deg) !important;
}

.region-search {
  max-height: 40px !important;
  display: inline !important;
  width: 80% !important;
  margin-top: 6px !important;
}

.space-between {
  justify-content: space-between !important;
}

.search-region-close {
  position: absolute !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.hover-link:hover {
  color: #47ad1d !important;
}

.border-bottom-1 {
  border-bottom: 1px solid #e4e4e4 !important;
}

.border-0 {
  border: 0px solid transparent;
}

.sort-region-listing {
  padding-bottom: 0px !important;
  margin-bottom: 5px !important;
}

.ei-grid-box-icon {
  max-width: 18px !important;
}

.blog-sm .bbc-body .des {
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
}

.nb-body .des {
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
  height: 65%;
}

.blog-box-hover .blog-box:hover {
  color: #47ad1d;
}

.org-img-fix {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  transition: all 0.5s;
}

.org-img-fix:hover {
  transform: scale(1.1);
}

.tbc-organization-box .org-img {
  object-fit: contain !important;
}

.footer-align {
  padding: 25px 0 25px 0px;
}

.caps-text {
  text-transform: capitalize;
}

.default-c {
  cursor: default;
}

.map-hero-image > svg {
  height: 100% !important;
  width: 100% !important;
}

.zrb-height {
  height: 778px !important;
}

.blog-box-image > .blog-img {
  object-fit: unset !important;
  height: unset !important;
}

.filter-content-box {
  font-size: 14px !important;
}

.gm-style .gm-style-iw-c {
  margin-top: 10px;
  padding: 10px !important;
  border-radius: 8px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}

.blog-sm .bbc-body .blog-h {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.blog-box .title-hover:hover {
  color: #47ad1d;
}

.zoo-tooltip {
  background-color: #47ad1d;
  color: #e4e4e4 !important;
}

.gm-style .gm-style-iw-c {
  margin-top: 0px !important;
  padding: 0px !important;
}

.gm-style .gm-style-iw-c button {
  height: 0px !important;
  width: 0px !important;
}

.gm-style .gm-style-iw-d {
  /* max-height: 100% !important; */
  width: fit-content !important;
  overflow: none !important;
  margin: 0px !important;
  padding: 0px !important;
}

.zrb-right {
  height: 750px !important;
}

.map-switch {
  margin-top: 9px;
  font-weight: 500;
  color: #47ad1d !important;
  text-decoration: underline !important;
  font-size: 1rem !important;
  cursor: pointer !important;
}

.nav-item .mobile-sub {
  color: #47ad1d !important;
}

.slick-slider {
  margin-bottom: 0;
  padding-bottom: 0px;
}

@media (max-width: 767.98px) {
  .zrb-left {
    margin-top: 350px;
  }
}

@media (max-width: 767.98px) {
  .zrb-left {
    margin-top: 50px;
  }
}

.environmental-inclusion-modal .modal-content {
  margin-top: 60px;
}

.environmental-inclusion-modal .modal-head {
  padding: 0px 0px 0px 35px;
}

.environmental-inclusion-modal .modal-close {
  top: 0px;
}

@media (max-width: 767.98px) {
  .environmental-inclusion-modal .modal-head {
    padding: 0px 0px 0px 30px;
  }
}

@media (max-width: 767.98px) {
  .tbc-body-inner .r-news {
    height: 215px;
  }
  .tbc-body-inner .cat-m {
    margin-bottom: 10px;
  }
}

.header-ei {
  z-index: 99999 !important;
}

.modal-close-btn {
  width: 100% !important;
}

.region-viewall-modal-search {
  width: 100%;
}

.region-viewall-modal-close-btn {
  top: 7px !important;
  right: 31px !important;
}

.region-news-card {
  min-height: 75.56px !important;
}

@media (max-width: 992px) {
  .view-all-header {
    display: block !important;
  }
  .search-view-modal {
    margin-top: 20px;
  }
  .mob-height-container {
    height: 70px !important;
  }
}

@media (min-width: 992px) {
  .region-viewall-modal-search {
    margin-right: 5rem !important;
  }
  .region-detail-slider li:not(:last-child) {
    margin-right: 3rem !important;
  }
  .home-page-left,
  .home-page-right {
    width: 25% !important;
  }
  .home-page-body {
    width: 50% !important;
  }
  /* .home-page-right .ei-green-label-block {
    top: 190px;
  } */
  .ei-category-container {
    display: flex;
    flex-wrap: nowrap;
  }
  .home-page-left,
  .home-page-right {
    position: sticky;
    top: 14%;
    height: auto;
    max-height: 70vh;
    transition: max-height 0.3s ease;
  }
  .home-page-left {
    overflow-y: auto;
  }
  .home-page-left {
    flex: 0 0 25%;
  }

  .home-page-body {
    flex: 1;
  }

  .home-page-right {
    flex: 0 0 25%;
  }
  .home-page-right {
    overflow-y: scroll !important;
  }
}

.accordion-item.news-region-sidebar {
  border-top: 0px !important;
}

.no-cursor {
  cursor: auto !important;
}

.region-detail-slider {
  justify-content: start !important;
}

.mob-active {
  text-decoration: white !important;
}

@media (max-width: 991px) {
  .region-list {
    flex-direction: column !important;
  }
}

@media ((min-width: 402px)and( max-width: 991.98px)) {
  .mob-height-container .container {
    padding-top: 18px;
  }
}

@media (max-width: 402px) {
  .ei-heading h4 {
    width: 220px;
  }
}
@media (max-width: 320px) {
  .ei-heading h4 {
    width: 180px;
  }
}

.rich-text-card.rich-text-card1 > *,
.rich-text-card.rich-text-card1 strong,
.rich-text-card.rich-text-card1 > b {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
}

.ei-map-box-skeleton {
  border: 0px !important;
}

@media (max-width: 767.98px) {
  .detail-img-box {
    width: 100% !important;
  }
}

@media (max-width: 700px) {
  .detail-img {
    width: unset;
    margin-left: 0px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 700px) {
  .detail-img > img {
    border-radius: 15px;
  }
}

.detail-img {
  object-fit: unset !important;
}

@media (max-width: 900.98px) {
  .environmentalist-details-container .ldc-image {
    width: 100% !important;
  }
}

@media (max-width: 767.98px) {
  .org-ul {
    padding-left: 12px;
  }
}

@media (max-width: 767.98px) {
  .ul-padding {
    padding-left: 22px;
  }
}

.contact-us-sticky {
  position: sticky !important;
  bottom: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.contact-now-btn {
  border-radius: 0;
  padding: 17px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

@media ((min-width: 992px) and (max-width:1200px)) {
  .contact-now-display {
    display: block !important;
  }
}

.card-box-shadow {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 36px -2px rgba(0, 0, 0, 0.06) !important;
  padding: 20px;
}

@media (max-width: 991.98px) {
  .accordion-height {
    padding: 0;
    height: calc(100% - 64px) !important;
    max-height: 100% !important;
    background: #f5f5f5;
    top: 0;
    border-radius: 0;
  }
}

.form-check-input:checked {
  background-color: #47ad1d;
  border-color: #47ad1d;
}

.pointer-event-none {
  pointer-events: none;
}

.doner-logo > .img-h {
  object-fit: contain;
}

.dropdown-item {
  color: #1e1e1e;
}

.organisation-logo > .org-img,
.cb-logo > .org-img {
  object-fit: contain;
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}

.error-p {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.media-list.org-m {
  margin-top: 0px;
}
/* .spd-image-inner > img,
.org-hero-bg > img,
.od-logo-inner > img,
.tbc-details-left > img,
.tbc-our-team-image > img,
.ei-grid-box-image > img,
.tbc-zoo-details-img > img {
  object-fit: unset !important;
} */

.c-select .css-r7wh8h-control,
.c-select .css-1gdyxss-control {
  background-color: none;
  border-color: unset !important;
  border-radius: 0;
  border-style: unset !important;
  border-width: 0px !important;
  /* border: none !important; */
  box-sizing: border-box;
  outline: 0px !important;
}

/* selected area height */

.check-group-list.custom-h {
  height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  /* margin-left: -25px; */
}
/* next btn disable color */

.btn-disable {
  background-color: #47ad1dad !important;
  border: solid 1px #47ad1dad !important;
}

.tc-search-result > .tc-friends-row:first-child {
  margin-top: -8px;
}

.lg-btn:hover {
  text-decoration: underline !important;
}

.custom-edit-h {
  height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}

.w-h-button-box .dropdown-menu {
  inset: 0px 0px auto auto !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #47ad1d;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  color: #f5f5f5 !important;
  background-color: #227300 !important;
}

.map-region-page {
  padding: 30px 10px;
  width: calc(100% - 16px);
  max-width: 1344px;
  margin-left: auto;
  margin-right: auto;
}

.bullet-points {
  width: 10px;
  margin: 0px 10px;
  font-size: 20px;
}

.bullet-points {
  padding-left: 0px;
}

.view-all-btn1 {
  display: inline;
  min-width: 100px;
  line-height: 12px;
  margin-left: 30px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}

.signup-dropdown-fix {
  width: 100%;
  padding-right: 0px;
}

.form-control:focus {
  border-color: #efefef !important;
}

.ff-update-link input#profilePicture {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
}

.disabled {
  opacity: 0.6;
  cursor: auto;
}

.modal {
  z-index: 999999 !important;
}

.modal-backdrop {
  z-index: 99999;
}

.invalid-feedback {
  display: inline !important;
}

.invalid {
  color: red !important;
}

.invalid-border {
  border-bottom: 1px solid red !important;
}

.org-detail-container {
  padding: 30px 0px 30px 0px;
}

.org-hero-bg1 > img {
  object-fit: cover !important;
}

.organisation-item,
.organisation-item1 {
  width: 33% !important;
}

.liveFeeds-item {
  width: 50% !important;
}

.fs-12 {
  font-size: 12px !important;
}

.marker-content {
  margin-right: 5px;
}

.marker-spacing {
  margin-right: 20px;
}

.map-container {
  margin: 0px 1rem;
  /* max-width: calc(100% - 25px) !important; */
}

.profile-header-img {
  margin-left: 10px;
  margin-bottom: 12px;
  height: 30px !important;
  width: 40px !important;
}

@media (max-width: 1200px) {
  .profile-header-img {
    display: none !important;
  }
  .profile-header-img1 {
    margin: 0;
    position: absolute;
    right: 70px;
    top: 50%;
    z-index: 999;
    transform: translateY(-50%);
    width: 40px !important;
  }
  .profile-header-img1 img {
    border-radius: 60% !important;
  }
  .organisation-item,
  .organisation-item1 {
    width: 100% !important;
  }
  .org-skeleton {
    width: 100% !important;
  }
  .organisation-text-footer .org-tab-mob {
    margin-top: 16px;
    margin-left: 0px !important;
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .header-flex {
    display: flex;
    align-items: center;
  }
  .profile-header-img1 {
    display: none;
  }
}

.profile-header-img img {
  height: auto;
  max-width: 100%;
  border-radius: 60%;
}

.dropdown-menu {
  border: 0;
  border-radius: 0;
  box-shadow: 0px 8px 30px -2px rgba(0, 0, 0, 0.15);
  font-size: 0.875rem;
  line-height: 1.3;
  margin-top: 20px;
}

.header-menu-icon .dropdown-menu {
  min-width: 142px;
}

.dropdown-item {
  padding: 6px 11px;
  font-size: 0.875rem;
  line-height: 1.5;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #000;
  background-color: #f5f5f5;
}

.org-skeleton {
  display: inline-block !important;
  width: 32%;
  margin-right: 17px !important;
  margin-bottom: 15px !important;
}

.nav-pill-mob-box {
  list-style: none !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.sm-img-fix {
  min-height: 70px;
  object-fit: contain !important;
}

.reports-flex-width {
  flex: 0 0 60px;
}

.friends-check-list {
  border-radius: 6px;
  overflow: auto !important;
  background: #fff;
  max-height: 508px !important;
}

.link-text {
  color: #47ad1d;
  text-decoration: none !important;
}

.link-text:hover {
  color: #47ad1d;
  text-decoration: underline !important;
}

.back-arrow {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.organisation-item {
  min-height: 200px !important;
}

.liveFeeds-item {
  min-height: 230px !important;
}

.organisation-item .organisation-box {
  height: 100%;
}

.header-profile-img {
  background: transparent !important;
}

.organisation-item .organisation-text h6 {
  min-height: 43px;
  margin-bottom: 15px;
}

.ff-image-box .upload-profile-pic {
  min-width: 100px;
  object-fit: contain !important;
}

.species-region-text {
  margin-bottom: 0px !important;
}

.species-region-text p:first-child {
  margin-bottom: 1rem !important;
}

.infoWindow-container {
  width: 100%;
  height: 100%;
}

.infoWindow {
  background-color: #000;
  padding: 15px;
  color: #fff;
  font-weight: 500;
  text-align: center;
}

.gm-style .gm-style-iw-tc::after {
  background: #000 !important;
}

.gm-style .gm-style-iw-d {
  display: contents;
}

.gm-ui-hover-effect > span {
  display: none;
}

.gm-style .gm-style-iw-c {
  padding-right: 10px !important;
  padding-bottom: 0px !important;
  max-width: 500px !important;
  max-height: 326px !important;
  min-width: 0px !important;
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: #000;
  border-radius: 8px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}

.ltb-details-item {
  margin-bottom: 25px !important;
}

.ltb-details-item p {
  font-size: 14px !important;
}

.ltb-details-item h4 {
  font-size: 18px !important;
}

.species-details-img {
  margin-left: 0px !important;
}

@media (min-width: 767.98px) {
  .ltb-details-item {
    width: 33% !important;
  }
  .ldc-image {
    width: 40% !important;
  }
  .species-detail-image {
    width: 35% !important;
  }
  .ldc-image img {
    margin-left: 0px !important;
  }
}

@media (max-width: 767px) {
  .species-region-text {
    flex-direction: column;
  }
  .species-region-text p:first-child {
    margin-left: 0rem !important;
  }
  .species-detail-image {
    width: 100% !important;
  }
  .species-details-img {
    width: 100%;
  }
}

.ltb-icon-box:hover svg {
  fill: #47ad1d;
}

.otp-resend {
  margin-top: 50px;
  margin-bottom: 20px;
}

.disable-resend-otp {
  cursor: default !important;
}

.fs-11 {
  font-size: 11px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.registration-flow-container {
  min-height: calc(90vh - 28px);
}

.min-vh-90 {
  min-height: 90vh !important;
}

@media (max-width: 567px) {
  .resend-container {
    flex-direction: column !important;
    align-items: start !important;
  }
  .resend-container p {
    margin-bottom: 15px !important;
  }
}

.ml-0 {
  margin-left: 0px !important;
}

.tbc-head .nav-pills {
  gap: 8px !important;
  justify-content: start !important;
  flex-basis: 0;
}

.tbc-body-inner {
  padding: 50px 0px 80px 0px !important;
}

.contributor-list {
  margin-top: 30px !important;
}

.rf-head-row + .rf-form-row-group {
  margin-top: 20px !important;
}

.text-transfrom {
  text-transform: capitalize !important;
}

.species-threats-img {
  max-width: 65px !important;
}

.mt-20 {
  margin-top: 20px !important;
  margin-bottom: 50px !important;
}

.media-sub-heading {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px !important;
}

.modal-transparent {
  background: transparent !important;
}

.mis-head {
  padding: 40px 130px 30px 88px;
  color: #fff;
}

.mis-head .btn-close {
  position: absolute;
  right: 5%;
  top: 5%;
  height: 30px;
  filter: brightness(0) invert(1);
  padding: 0;
  width: 30px;
}

.mis-body {
  padding: 0 88px 20px;
}

.mis-body img,
.mis-body video,
.mis-body iframe {
  width: 400px !important;
  height: 400px !important;
  object-fit: cover;
}

.mis-body .slide-wrap {
  position: relative;
  height: calc(100vh - 270px);
  padding-bottom: 0;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.w-33 {
  width: 33% !important;
  max-width: 100% !important;
}

.mh-300 {
  min-height: 300px !important;
}

.loader-style {
  display: flex;
  justify-content: center;
  margin-top: 125px !important;
}

.more-btn {
  display: flex;
  justify-content: center;
  margin: 30px 0px;
}

.customize-profile-img {
  font-weight: 500;
  height: 100%;
  width: 100%;
  /* background: antiquewhite; */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 1.3rem !important;
}

#inputGroupImg,
#inputGroupImg1,
#inputGroupImg2[type="file"] {
  cursor: pointer;
  height: 100%;
  left: auto;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  z-index: 10;
}

.mt-35 {
  margin-top: 35px !important;
}

.m-10 {
  margin: 8px !important;
}

.h-auto {
  height: auto !important;
}

.send-dm-box {
  width: 80%;
  background: #fff;
  border-radius: 5px;
}

.dmpost-close-btn {
  position: absolute;
  /* left: 100px; */
  right: 60px;
  top: 0px;
}

@media (max-width: 575.98px) {
  .send-dm-box {
    width: 100%;
    margin-top: 25px;
  }
  .dmpost-close-btn {
    right: 10px;
  }
}

.title-dm {
  margin: 15px;
  font-weight: bold;
  font-size: 20px;
  margin-left: 20px;
}

.share-post {
  position: absolute;
  inset: -15px auto auto -150px !important;
  transform: translate3d(2.4px, 24px, 0px);
}

.custom-post-comment {
  width: 80%;
  margin-top: 30%;
}

@media (max-width: 991.98px) {
  .comments-modal .modal-content {
    background: #fff !important;
  }
  .custom-modal-comment {
    background-color: #fff !important;
  }
  .custom-post-comment {
    margin-top: 2% !important;
  }
}

.hover-underline {
  text-decoration: none !important;
}

.hover-underline:hover {
  text-decoration: underline !important;
}

.no-comments-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  max-width: 100%;
}

.no-comments-message {
  max-width: 200px;
  text-align: center;
}

.profile-picture-name {
  background-color: #daefd1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #000;
  font-weight: bold;
}

.add-post-textarea {
  height: 100px;
  line-height: 1.2;
}

.edit-delete-feeds {
  padding: 3px !important;
}

.btn-follow {
  padding: 7px 10px;
}

.custom-input-final-signup-page {
  max-width: calc(100% - 100px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: none;
}

.custom-final-page-count {
  position: absolute;
  right: 10%;
  top: 45%;
}

@media (max-width: 991.98px) {
  .custom-final-page-count {
    right: 15%;
    font-size: 0.8rem;
  }
}

@media (max-width: 567px) {
  .custom-final-page-count {
    right: 20%;
    font-size: 12px;
  }
}

.custom-share-post {
  width: 100%;
}

.custom-share-dropdown-text {
  width: 0;
}

.custom-citation-text {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}

.custom-citation > div:first-child {
  margin-bottom: 8px;
  font-size: 14px;
}

.custom-citation-text li,
.custom-citation-text p {
  margin-bottom: 5px !important;
}
.custom-citation-text ul {
  margin-bottom: 0px;
}

.env-reg-img {
  max-width: 150px;
  max-height: 150px;
  width: 140px;
  height: 140px;
}

.showmodal-search-close-icon {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
  position: absolute;
  right: 3%;
  top: 50%;
  transform: translateY(-50%);
}

.map-list-env-reg {
  display: flex;
  flex-wrap: wrap;
}

.map-item-env-reg {
  width: calc(20% - 10px);
}

.map-box-env-reg {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.map-box-text-env-reg {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 991.98px) {
  .map-item-env-reg {
    width: 100%;
  }
}

.react-loading-skeleton {
  --pseudo-element-display: block !important;
}

.centered-skeleton {
  width: 80vw;
  height: 80vh;
  display: flex;
  margin: auto;
}
.fc-right2 {
  margin-top: 0;
}
@media (max-width: 567px) {
  .fc-right2 {
    margin-top: -14%;
  }
}

.show-all-zoo-map-container {
  position: relative;
}

.show-all-zoo-map {
  position: absolute;
  right: 1%;
  top: 50%;
}

.wet-market-list {
  min-height: 120px !important;
}

.ldc-text-icon,
.ldc-text-main-icon {
  position: absolute;
  width: 70px;
  right: 0px;
  top: -10px;
}

.full-width-search-row.sticky-top {
  top: 80px !important;
}

.full-width-search-row + main .filter-sidebar,
.full-width-search-row + main .ei-category-accordion {
  top: 190px;
}

.full-width-search-row {
  padding: 10px 0;
  background: #227300;
  z-index: 9999;
}

.fws-search-box > svg {
  width: 21px;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.fws-search-input {
  padding: 16px 55px;
  background: #f5f5f5;
  border-radius: 4px;
  border: 0;
  font-size: 0.875rem;
  line-height: 1;
  height: 40px;
  width: 88%;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.fws-search-input::placeholder {
  color: rgb(0 0 0 / 70%);
}

.show-more-model {
  column-count: 3;
  column-gap: 1em;
}

@media (max-width: 1199.98px) {
  .map-item-env-reg {
    width: calc(25% - 10px);
  }
  .full-width-search-row.sticky-top {
    top: 70px !important;
  }
  .full-width-search-row + main .filter-sidebar,
  .full-width-search-row + main .ei-category-accordion {
    top: 180px;
  }
}

@media (max-width: 992px) {
  .detail-right-tbc {
    margin-top: 0;
  }
  .full-width-search-row + main .filter-sidebar,
  .full-width-search-row + main .ei-category-accordion {
    top: 0px;
  }
  .full-width-search-row {
    padding: 12px 0;
  }
  .fws-search-input {
    padding: 8px 45px;
    height: 40px;
  }
  .show-more-model {
    column-count: 2;
    column-gap: 1em;
  }
}

@media (max-width: 767.98px) {
  .fc-right2 {
    margin-top: -7%;
    border-top: none;
  }
  .full-width-search-row.sticky-top {
    top: 52px !important;
  }
  .show-more-model {
    column-count: 1;
    column-gap: 1em;
  }
}

.btn-donate-org {
  position: absolute;
  right: 0px;
}

.search-list > img {
  object-fit: none !important;
}

.hover-link-near-extinct:hover {
  text-decoration: underline;
  color: #fff;
}

.search-type-text {
  display: block;
  margin-top: 5px;
}

.custom-modal-popup .modal-dialog {
  max-width: 588px;
}

.custom-modal-popup .modal-content {
  border: 0;
  border-radius: 10px;
  padding: 33px 31px 30px 30px;
  font-size: 1rem;
  background: #ffffff;
  box-shadow: 0px 5px 30px -2px rgba(0, 0, 0, 0.103472);
}

.custom-modal-popup .modal-header {
  border-bottom: none;
  position: relative;
  padding: 0;
}

.custom-modal-popup .modal-body {
  padding: 0;
  padding-top: 0px;
}

.modal-alert-box h4 {
  font-size: 1rem;
  color: #000;
  margin-bottom: 18px;
}

.btn-delete {
  background: #ff4242;
  color: #fff;
  min-width: 150px;
  padding: 16px 0px;
}

.btn-delete:hover,
.btn-delete:focus {
  background: #ff4242;
  color: #fff;
}

.modal-backdrop.show {
  z-index: 9999;
}

.modal-alert-box {
  line-height: 1.6;
  letter-spacing: 0px;
}

.modal-action-btn {
  margin-top: 30px;
}

.btn-cancel {
  padding: 15px 0;
  min-width: 150px;
  font-size: 0.875rem;
}

.media-report-div {
  margin-bottom: 20px;
}

@media (max-width: 767.98px) {
  .media-report-div {
    margin-top: 20px;
  }
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.gallery-item {
  position: relative;
  width: 150px;
  height: 150px;
}

.gallery-image,
.gallery-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-document {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 14px;
  padding: 10px;
  width: 100%;
  height: 100%;
}

.gallery-document img {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 90%;
  object-fit: contain;
}

.file-name {
  margin-top: 5px;
}

.gallery-remove-btn {
  width: 28px;
  height: 28px;
  border-radius: 5px;
  background: #fff;
  border: 0;
  position: absolute;
  top: 5px;
  right: 5px;
}

.bulk-upload-container {
  height: 200px;
  width: 100%;
  overflow: auto;
}

.bulk-upload-container h5 {
  font-weight: 600;
}

.bulk-upload-container .gallery-item {
  position: relative;
  width: 130px;
  height: 130px;
}

.btn-media {
  border: solid 1px var(--green-color);
}

.file-type-radio label {
  display: block;
  margin-bottom: 10px;
}

.file-type-radio label input {
  margin-right: 10px;
}

.btn-bulk-upload {
  position: absolute;
  right: 20px;
  bottom: 30px;
}

.header-search-close-icon {
  z-index: 9;
}

.btn-search-header {
  margin-left: 10px;
  height: 40px;
  color: var(--green-color);
}

.btn-search-header:hover {
  color: var(--green-color);
}

@media (max-width: 768px) {
  .mis-head,
  .mis-body {
    padding: 0 20px;
  }
  .mis-body img,
  .mis-body video,
  .mis-body .centered-media {
    margin-top: 15%;
  }
}

.custom-modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 80%;
  max-width: 80%;
}

.image-gallery-image > div:first-child {
  width: 100% !important;
}

.image-gallery-svg {
  width: 40px !important;
}

.image-gallery-fullscreen-button {
  display: none;
}

.custom-modal-dialog .modal-content {
  border: none;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  height: 40px;
}

.goog-te-gadget a {
  display: none !important;
}

#google_translate_element {
  height: 40px;
}

.header-category-dropdown button {
  height: 40px;
  border: #d3d4d5;
  color: var(--green-color);
  background-color: #d3d4d5;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left-color: #f5f5f5;
}

.header-category-dropdown button:hover,
.header-category-dropdown button:active {
  color: var(--green-color) !important;
  background-color: #fff !important;
  border: #d3d4d5 !important;
}

.chevron {
  position: absolute;
  display: block;
  height: 10px;
  top: 42%;
  right: 40%;
}

.chevron::before,
.chevron::after {
  position: absolute;
  display: block;
  content: "";
  border: 5px solid transparent;
}

.chevron::before {
  top: 0;
  border-top-color: var(--green-color);
}

.chevron::after {
  top: -2px;
  border-top-color: #d3d4d5;
}

.header-category-dropdown .btn-check:checked + .btn,
.header-category-dropdown .btn.active,
.header-category-dropdown .btn.show,
.header-category-dropdown .btn:first-child:active,
.header-category-dropdown :not(.btn-check) + .btn:active {
  color: var(--green-color);
  background-color: #fff;
  border-color: #fff;
}

.header-category-dropdown:hover .chevron::after,
.header-category-dropdown:active .chevron::after,
.header-category-dropdown .btn.show .chevron::after {
  border-top-color: #fff;
}

@media (max-width: 768.99px) {
  .search-category-filter {
    display: none;
  }
}

.media-box-inner div {
  width: auto !important;
  height: auto !important;
}

.media-box-inner > div > video {
  object-fit: fill;
}

.media-link-video div {
  width: 100% !important;
  height: 100% !important;
}

.dev-server-div {
  background: #6cb94c;
  font-size: 0.8125rem;
  width: 102%;
}

.transparent-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
}

.map-wet-market > div > div:nth-child(1) {
  height: 773px !important;
  margin-top: 0 !important;
}

.map-hero-detail-page {
  position: relative;
  height: 800px;
}

.map-hero-detail-page .map-wet-market > div > div:nth-child(1) {
  height: 800px !important;
}

.zrb-right-map-home {
  width: 100%;
}

.active-link {
  color: rgb(71, 173, 29);
}

.header-input {
  width: 67%;
}

.infoWindow img {
  width: 40px;
  height: 40px;
  border-radius: 5%;
}

.table-pagination {
  width: 90%;
  margin: auto;
  margin-top: 20px;
  margin-right: 0px;
  font-family: "Poppins", sans-serif;
}

.table-pagination .page-link,
.page-pagination .page-link {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  width: 35px;
  height: 35px;
  padding: 2px;
  text-align: center;
  background: transparent;
  border-radius: 0;
  border: 0;
  color: #000;
}

.table-pagination .page-item:not(:first-child) .page-link,
.page-pagination .page-item:not(:first-child) .page-link {
  margin-left: 0;
}

.table-pagination .page-item.active .page-link,
.page-pagination .page-item.active .page-link,
.table-pagination .page-item:hover .page-link,
.page-pagination .page-item:hover .page-link {
  background-color: #227300;
  border-color: #227300;
  color: #fff;
}

.disabled.page-link {
  background: transparent !important;
  cursor: no-drop !important;
}

.disabled.page-link svg {
  stroke: rgba(0, 0, 0, 0.5);
}

.page-link svg {
  stroke: currentColor;
}

.page-link:focus {
  box-shadow: none !important;
}

.page-link[aria-label="Previous"] {
  margin-right: 25px !important;
}

.page-link[aria-label="Next"] {
  margin-left: 25px !important;
}

.page-item:last-child .page-link,
.page-item:first-child .page-link {
  border-radius: 0 !important;
}

.pagination .page-item + .page-item {
  margin-left: 5px;
}

.modal-size-reg {
  --bs-modal-width: 87%;
}

.view-all-region {
  margin: 15px 0px;
}

@media (max-width: 567px) {
  .view-all-region {
    margin: 10% 0px;
  }
}

.card-img-inside svg {
  height: 100%;
  width: 256px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  object-fit: cover;
}

.card-content-feeds {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-content-feeds b {
  font-weight: 500;
}

.detail-page-map {
  padding-bottom: 58%;
}

.region-detail-map {
  margin-bottom: 4%;
  margin-top: 4%;
}

@media (max-width: 1200px) {
  .region-detail-map {
    margin-bottom: 15%;
  }
}

@media (max-width: 1024px) {
  .region-detail-map {
    margin-bottom: 25%;
  }
}

@media (max-width: 767px) {
  .detail-page-map {
    padding-bottom: 0%;
    margin-bottom: 55%;
  }
  .region-detail-map {
    margin-bottom: 85%;
  }
  .fc-right3 .footer-menu-list {
    display: block;
  }
}

@media (max-width: 567px) {
  .detail-page-map {
    padding-bottom: 0%;
    margin-bottom: 100%;
  }
  .edit-pfl-modal {
    margin-left: 3%;
    margin-bottom: 20px;
  }
}

.live-feeds-text h6 {
  margin-bottom: 5px !important;
  min-height: 30px !important;
}

.live-feed-footer {
  margin-top: 0px;
}

.live-feed-footer p {
  margin-bottom: 3px;
  margin-top: 0px;
}

.ei-filter-accordion {
  background-color: var(--bs-white);
  box-shadow: 0px 0px 36px -2px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 18px;
  position: sticky;
  top: 33% !important;
  margin-top: 46%;
  height: auto;
}

.zoo-report-container .btn-link-reg:visited {
  color: #47ad1d;
}

.threats-text {
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
}

.show-modal-species {
  margin-top: 15px;
  width: 96%;
}

.media-item-skeleton {
  margin-bottom: 8px;
}

.media-upload-modal {
  height: 600px;
}

.active-nav {
  outline: none !important;
  color: var(--green-color) !important;
  text-decoration: none !important;
}

.show-reg-map {
  position: absolute;
  top: 60%;
  right: 0%;
}

.btn-link-reg:visited {
  color: #47ad1d;
}

@media (max-width: 400px) {
  .slick-track {
    width: 1560px !important;
  }
}

.pills-tab {
  height: 35px !important;
  padding: 8px !important;
  border-radius: 35px !important;
  border: 1px solid #ffff;
  color: #ffff;
  font-weight: 400;
}

.nsv-pill-mob-inner.pills-tab:hover {
  background-color: white;
  color: #47ad1d !important;
  cursor: pointer;
}

.pills-tab-active {
  background-color: white;
  color: #47ad1d !important;
}

.header-search-icon {
  position: absolute;
  z-index: 9;
  top: 10%;
  left: 10px;
}

.view-profile-section-img {
  object-fit: cover;
  height: 100%;
}

.no-data-text {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 20px;
}

.goog-te-gadget .goog-te-combo {
  margin: 0 0 !important;
  height: 40px !important;
  border-radius: 5px !important;
}

@media (max-width: 1024px) {
  .goog-te-gadget .goog-te-combo {
    margin: 4px 0 !important;
    height: 40px !important;
    border-radius: 5px !important;
  }
  .signup-modal .modal-dialog.xs-modal {
    max-width: 638px !important;
    top: 50px;
  }
}

.search-region-modal {
  position: absolute;
  right: 35px;
}

.search-region-modal-close {
  position: absolute;
  left: 70%;
  top: 40%;
}

.comment-close {
  position: sticky;
  top: 10%;
  right: 16%;
}

.widget-member-list > span {
  width: 100%;
}

@media (max-width: 768px) {
  .search-region-modal {
    top: -5px;
  }
}

.splash-data {
  font-size: 14px;
  padding-bottom: 20px;
}

.custom-icon {
  width: 20px;
  height: 20px;
}

.species-wetmarket {
  padding-bottom: 10px !important;
}

.ei-map-box-detail {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-species-org-link {
  display: flex;
  justify-content: center;
  width: auto;
  flex: 0 0 auto;
}

.custom-species-org-link a {
  height: 35px;
}

@media (max-width: 400px) {
  .custom-species-org-link {
    flex: 0 0 0;
    height: 50px;
  }
  .custom-species-org-link a {
    height: 50px;
  }
}

.custom-contibute {
  padding-top: 0px !important;
}

.ldc-text-body-2 {
  padding: 30px 0px;
  font-size: 18px;
}

.ldc-text-2 {
  width: 58.62%;
}

@media (max-width: 769px) {
  .ldc-text-2 {
    width: 100%;
    padding-top: 15px;
  }
  .custom-page-title {
    margin-top: 16px;
  }
  .ldc-text-body-2 {
    font-size: 16px;
  }
}

.ldc-image-2 {
  width: 35% !important;
}

.splash-btn {
  color: var(--green-color);
  background-color: var(--bs-white);
  border: solid 1px var(--green-color);
}

.splash-btn:hover {
  color: var(--bs-white);
  background-color: var(--green-color);
  border-color: var(--green-color);
}

[data-tooltip] {
  position: relative;
  cursor: pointer;
}

[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  width: 300px;
}

.splash-donate-btn {
  position: absolute;
  min-width: 80px;
  line-height: 12px;
  bottom: 10px;
  left: 25%;
}

.post-comment-modal {
  max-width: var(--bs-modal-width) !important;
}

.css-1fdsijx-ValueContainer {
  padding: 2px 0px !important;
}

.google-element > *:not(:first-child) {
  display: none;
}
.google-element select {
  padding-left: 6px;
}
.smh-insider-text {
  font-size: 14px;
  font-weight: 400;
}

.placeholder-small::placeholder {
  font-size: 10px;
  padding-bottom: 10px;
}

@media (min-width: 1024px) {
  .custom-post-modal {
    --bs-modal-width: 800px;
    margin-top: 5%;
  }
}

.btn-volunteer {
  color: var(--green-color);
  background-color: var(--bs-white);
  border: solid 1px var(--green-color);
}

.volunteer-form .css-1jqq78o-placeholder {
  margin-left: 12px;
}

.volunteer-form .env-select {
  padding-right: 12px;
}

.volunteer-checkbox {
  display: flex;
  align-items: center;
  margin-top: 5%;
}

.volunteer-checkbox input {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.edit-detail-page input,
.edit-detail-page select {
  border: none;
  border-bottom: 1px solid #000;
  outline: none;
  width: 100%;
  padding: 0px 0;
}

.add-more-btn-edit-tab {
  padding-top: 10px;
  max-height: 40px;
}

.species-detail-name label {
  font-size: 16px;
  font-weight: 400;
}

.species-detail-name input {
  font-size: 20px;
  font-weight: 500;
}
.read-more-detail {
  color: #47ad1d;
  cursor: pointer;
}
.edit-org-name input {
  border-top: none;
  border-left: none;
  border-right: none;
}
.ck .ck-powered-by a {
  display: none !important;
}
.css-1gdyxss-control {
  min-height: 40px !important;
}
.w-90 {
  width: 90% !important;
}
.document-file .doc-image {
  width: 15%;
  margin-top: 10px;
}
.upload-section .ck-editor__editable {
  height: 130px;
  overflow: scroll;
}
.ck-editor ul {
  padding-left: 10px;
}
/* .region-edit .ck-editor__editable{
                height: 200px;
                overflow: scroll;
            } */
.reference-add .ck-editor__editable {
  height: 300px;
  overflow: scroll;
}
.reference-add .ck-editor__editable p {
  margin-bottom: 0px;
}
.last-edited {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}
.ck .ck-balloon-panel {
  z-index: 9999999 !important;
}
.reference-item p {
  cursor: default;
}
.reference-item a {
  cursor: pointer;
  color: #47ad1d;
}
.upload-limit-text {
  color: #47ad1d;
  font-size: 10px;
  line-height: 1.2;
}
.gallery-item-education {
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.education-upload .select-search__input-container,
.education-upload .select-search__placeholder,
.tbc-body-inner .select-search__input-container,
.tbc-body-inner .select-search__placeholder {
  padding-left: 10px;
}
.h-40 {
  height: 40px !important;
}
.education-tab .tbc-our-team-image img,
.education-tab .tbc-our-team-image video,
.education-tab .tbc-our-team-image iframe,
.education-tab .tbc-our-team-image a {
  border-radius: 10px;
}
.add-modal .css-1jqq78o-placeholder,
.add-modal .css-qbdosj-Input {
  margin-left: 12px;
}
.css-1fdsijx-ValueContainer .css-166bipr-Input {
  margin-left: 12px;
}

.eap-img-box video,
.eap-img-box iframe {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 10px;
}
.doc-background {
  background: #f2f2f2;
  border-radius: 10px;
}
.ck-content ul {
  list-style-type: none !important;
}
.home-page-image img,
.home-page-image video {
  height: 500px !important;
  width: 500px !important;
  object-fit: contain;
}
.btn-close-gallery {
  filter: none !important;
  position: absolute;
  right: 10%;
  top: auto !important;
}

@media (min-width: 1024px) {
  .comment-modal-adjust {
    height: 500px;
    overflow-y: scroll;
    border-radius: 10px;
  }
  .preference-modal-adjust {
    height: 300px;
    overflow-y: scroll;
  }
  .preference-modal-adjust-1 {
    height: 500px;
  }
}
.w-text-read-more p {
  margin-bottom: 0px;
}
.page-intro-image iframe {
  border-radius: 10px;
  min-height: 360px;
}
.filter-content-box-1 {
  font-size: 16px !important;
}
.eap-item a {
  font-size: 14px;
}
.education-upload .ck-content p,
.education-upload .ck-content ul,
.education-upload .ck-content ol {
  height: 80px;
}

.register-user-container input {
  margin-right: 10px !important;
}

.register-user-container div {
  margin-bottom: 10px !important;
}

.register-user-form {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.grid-item .eap-box {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
}
.page-holder-wrapper .splash-login-btn {
  min-width: 99px !important;
  padding: 7px 20px !important;
  border-radius: 4px !important;
}
.custom-tour-modal {
  min-width: 600px;
}
.custom-tour-modal iframe {
  min-height: 360px;
}

.custom-nav-tabs-lg {
  padding: 18px;
}
.custom-nav-tabs-lg .nav-item {
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 0.5rem;
}
.custom-nav-tabs-lg .nav-item .nav-link {
  margin-bottom: 0;
}
@media (min-width: 991.98px) and (max-width: 1199.98px) {
  .nav.nav-pills {
    display: flex !important;
  }
}

@media (max-width: 1024.98px) {
  .header-input {
    width: 100%;
  }
  .hero-container-text h1 {
    font-size: 24px;
    max-width: none;
  }
  .all-comment-model .fwm-close-btn {
    top: 35px;
    right: 35px;
  }
  .custom-tour-modal {
    min-width: 500px;
  }
  .dropdown-menu {
    background: #ffff;
  }
}
@media (max-width: 991.98px) {
  .custom-tour-modal {
    min-width: 300px;
  }
  .custom-nav-tabs {
    margin-top: 20px;
  }
  .custom-nav-tabs .nav-link-icon {
    width: 16px;
    height: 16px;
  }
  .custom-nav-tabs .nav-link-box {
    font-size: 14px;
    gap: 10px;
  }
  .map-tab-container .nav-item:not(:last-child):before {
    height: 0px;
  }
}
@media (max-width: 767.98px) {
  .tbc-body-inner {
    padding: 40px 0px !important;
  }
}
@media (max-width: 500.98px) {
  .flex-step-box {
    padding-left: 25px;
  }
  .flex-step-count {
    width: 20px;
    height: 20px;
    margin-top: -1px;
  }
  .flex-step-list {
    padding-left: 15px;
  }
  .login-head-row h1 {
    font-size: 25px;
  }

  .fws-search-input {
    padding: 0px 6px;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
  .hero-container-text h1 {
    font-size: 24px;
    max-width: 400px;
  }
  .awssld__controls {
    visibility: visible;
  }
  .awssld__controls__arrow-left,
  .awssld__controls__arrow-right {
    height: 20px;
  }
  .all-comment-model .fwm-close-btn {
    top: 0px;
    right: 6px;
  }
  .custom-tourmodal {
    top: 10%;
  }
  .environmentalist-details-img {
    width: 100%;
  }

  .btn {
    font-size: 12px !important;
  }
  #tabButton1 button {
    width: 100%;
  }
  .detail-tab-text {
    font-size: 14px;
  }
}
.css-hlgwow {
  padding: 0px !important;
}
.register-user-container label {
  display: flex;
}
.custom-volunteer-form .css-12jy34s-control {
  border-bottom: 1px solid #000;
}
.custom-volunteer-form .css-1jqq78o-placeholder {
  color: #000;
  font-weight: 400;
}
.custom-volunteer-form.filter-content-box input:checked ~ .checkmark {
  background-color: var(--green-color);
  border: 1px solid var(--green-color);
}
.signup-dropdown-fix .css-1xc3v61-indicatorContainer,
.custom-volunteer-form .css-1xc3v61-indicatorContainer {
  color: #000 !important;
}
.custom-volunteer-form .filter-content-box .checkmark:after {
  left: 10px;
  top: 5px;
}
.btn-search-header-icon {
  min-width: auto;
  height: 40px;
  margin-left: 5px;
}
@media (max-width: 400px) {
  .w-iconlist-box {
    gap: 5px;
    padding: 0px 5px;
    font-size: 10px;
  }
  .w-iconl-box {
    height: 14px;
    width: 14px;
  }
}
.tbc-details-skeleton span {
  width: 100%;
}
@media (min-width: 1440px) {
  .home-page-intro1 {
    width: 55%;
  }
  .home-page-intro2 {
    width: 45%;
  }
  .page-intro-image {
    width: 100%;
  }
}
.species-details-img {
  width: 100%;
}
.w-page-link-icon {
  width: 20px;
  height: 20px;
}
.w-page-link a {
  color: #47ad1d;
}
.listing-skelton {
  height: 220px;
}

.education-left,
.education-right {
  width: 23% !important;
}
.education-body {
  width: 52%;
  margin-left: 20px;
  margin-right: 20px;
}
.custom-mt-5 {
  margin-top: 5px;
}
.education-left {
  background-color: var(--bs-white);
  border-radius: 10px;
  box-shadow: 0 0 36px -2px rgba(0, 0, 0, 0.06);
  width: 23% !important;
  height: 10%;
}
.education-right-filter {
  background-color: var(--bs-white);
  border-radius: 10px;
  box-shadow: 0 0 36px -2px rgba(0, 0, 0, 0.06);
  padding-top: 15px;
}
.education-right-filter .nav-link {
  padding: 5px 20px 15px;
}
.education-right-filter .nav-link-icon {
  width: 16px;
  height: 16px;
}
.education-right-filter .nav-item:not(:last-child):before {
  content: "";
  width: 0px;
  height: calc(100% - 18px);
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background-color: white;
  position: absolute;
}
.education-right .modal-content, 
.education-left-new .modal-content{
  border: 2px solid rgba(71, 173, 29, 0.5);
  padding: 15px 0px;
  border-radius: 10px;
}
.education-right .ck.ck-editor__main > .ck-editor__editable {
  height: 100px !important;
}
.css-1dimb5e-singleValue ,
.css-qbdosj-Input,
.css-1jqq78o-placeholder
{
  padding-left: 8px;
}
.education-right .css-1jqq78o-placeholder,
.education-right .css-qbdosj-Input,
.education-right .css-1dimb5e-singleValue {
  padding-left: 10px;
}
.file-type-select {
  margin-bottom: 0.5rem;
}

.file-type-alert {
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;
}
.education-select-text {
  font-size: 16px;
  font-weight: 500;
  margin-top: 1rem;
}
.education-right .gallery-item-education {
  width: 70px;
  height: 70px;
}
.react-player-iframe iframe,
.react-player-iframe video,
.post-video-tag {
  border-radius: 10px;
}
.pdf-preview {
  height: 250px;
}
.custom-pill-post {
  background-color: transparent;
  border: 1px solid var(--green-color);
  color: var(--green-color);
  padding: 5px 10px;
  min-width: 50px;
  text-align: center;
}
.detail-img-skeleton {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.pdf-preview-view {
  height: 500px;
  width: 500px;
}
.custom-view-modal .react-player-iframe {
  margin-top: 20%;
}
.custom-view-modal .share-post {
  position: absolute !important;
  inset: auto 0px 0px auto !important;
  transform: translate3d(0px, -20px, 0px) !important;
}
@media (max-width: 768.98px) {
  .detail-img-skeleton {
    height: 300px;
  }
  .custom-view-modal .react-player-iframe {
    margin-top: 10%;
  }
  .no-comments-message {
    height: 130px;
  }
}
@media (max-width: 500.98px) {
  .listing-skelton {
    height: 180px;
  }
  .custom-view-modal {
    background-color: #fff;
  }
  .react-player-iframe {
    height: 200px !important;
    width: 100% !important;
    margin-top: 0px;
  }
  .pdf-preview {
    height: 200px;
  }
  .pdf-preview-view {
    height: 200px;
  }
  .custom-pill-post {
    font-size: 10px;
  }
  .widget + .widget {
    margin-bottom: 10px;
  }
  .detail-img-skeleton {
    height: 220px;
  }
  .signup-modal .modal-dialog.xs-modal {
    top: 0px;
  }
}
@media (max-width: 400.98px) {
  .listing-skelton {
    height: 150px;
  }
  .react-player-iframe {
    height: 150px !important;
  }
  .pdf-preview {
    height: 150px;
  }
  .pdf-preview-view {
    height: 150px;
  }
}
.ei-green-label-block {
  margin-top: 7px;
}
.custom-ei-accordion {
  padding-top: 0px;
}
.right-25 {
  right: 25px !important;
}
.nav-link-filter {
  font-size: 16px;
  font-weight: 500;
}
.spd-body {
  font-size: 16px;
}
.text-dangerLevel {
  font-size: 22px;
}
.pagination-count-box {
  margin: 5px 5px;
}
.species-name h1 {
  font-size: 30px;
}
.education-left .col-text-label {
  width: 60%;
}
.education-left .col-select-btn {
  width: 40%;
}
.scientific-text {
  font-size: 20px !important;
  font-weight: 500 !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.w-body-content h4 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 15px;
}
.list-with-bullets {
  position: relative;
  padding-left: 15px;
  margin-left: 8px;
}

.list-with-bullets::before {
  content: "•";
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.2em;
  color: black;
  line-height: 1;
}
.col-dropdown-menu .dropdown button {
  height: 40px;
  padding-top: 4px;
}
.user-dropdown .dl-icon svg,
.user-dropdown .chevron-down-wrap svg {
  color: #47ad1d;
}
.sponsor-content {
  font-size: 14px;
  font-weight: 400;
}
.sponsor-content p {
  margin-bottom: 8px;
}
.sponsor-content li {
  margin-bottom: 5px !important;
}
.sd-text-box h3 img {
  height: 34px;
}
.ei-grid-item-sponsor {
  width: 33%;
}
.text-sponsor {
  font-size: 20px;
  color: #47ad1d;
}
@media (min-width: 991px) and (max-width: 1024px) {
  .margin-1024 {
    margin-top: 10px;
    margin-left: 0px !important;
  }
}
.banner-image {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.fw-bold500 {
  font-weight: 500 !important;
}
.btn-read-more {
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  color: #47ad1d;
  position: relative;
  padding-bottom: 2px;
  letter-spacing: -0.02em;
  font-family: "Poppins", sans-serif;
}
.btn-read-more:before {
  content: "";
  width: 100%;
  height: 1px;
  background: currentColor;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.2s all ease;
}
.btn-read-more:hover::before {
  width: 20px;
}
.description-pannel-box-img img {
  width: 50%;
}
.sponsor-box {
  height: 150px !important;
}
.cropper-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Completely opaque background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  overflow: hidden;
  margin-top: 5%;
}

.cropper-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cropper-actions {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
}

@media (max-width: 768px) {
  .cropper-container {
    width: 95vw;
    max-width: 350px;
    padding: 10px;
  }
  .cropper-actions {
    flex-direction: column;
    gap: 8px;
  }
}
.species-modal-skelton{
  width: 250px;
}
@media (max-width: 480px) {
  .cropper-container {
    width: 100vw;
    max-width: none;
    border-radius: 0;
  }
  .species-modal-skelton{
    width: 90vw;
  }
}
.default-card-img video,
.default-card-img iframe,
.default-card-img .react-player-iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  object-fit: cover;
}
.default-card-img .react-player-iframe {
  height: auto !important;
}
.custom-dropdown-menu {
  position: absolute;
  inset: -130px auto auto 20px !important;
  transform: translate3d(2.4px, 24px, 0px);
}
.default-heading a ,
.default-cta-btn .btn-link {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: #47AD1D;
  display: inline-block;
  margin-bottom: 0;
  position: relative;
  text-align: end;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}
.default-heading a:hover,
.default-cta-btn .btn-link:hover {
  text-decoration: underline;
  color: #47AD1D; 
}
.default-image-text,
.dmr-text-box{
  font-size: 16px;
}
.default-image-box{
  height: 200px;
  width: 200px;
}
@media (max-width: 480px) {
  .hw-title h1 {
    font-size: 28px;
  }
  .hw-subtitle h2{
    font-size: 18px;
  }
}
.home-page-right .modal-content ,
.home-page-left .modal-content,
.ei-category-left .modal-content{
  border: 1px solid #227300;
  padding: 15px 0px;
  border-radius: 10px;
}
.home-page-left{
  background: var(--bs-white);
  box-shadow: 0px 0px 36px -2px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}
@media (min-width: 992px) {
  .home-page-right {
    height: 100vh;
  }
}
.custom-close-btn{
  width: 24px;
  height: 24px;
}
.custom-success-icon img{
  width: 50px;
  height: 50px;
}
.sponor-listcard .organisation-text h6{
  min-height: auto;
}
.wpw-content-img-box{
  height: 300px;
}
.custom-sidebar .accordion-button::after{
  display: none;
}
.intern-program-container {
  max-width: 800px; 
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
  padding: 28px;
  padding-left: 38px;
}

.intern-program-container h6{
  margin-bottom: 1rem; 
}

.intern-program-container p {
  text-align: justify;
  margin-bottom: 1rem;
}

.intern-program-container ol {
  /* padding-left: 1.5rem; */
  padding-left: 20px;
}

.intern-program-container a {
  color: #47ad1d;
  text-decoration: none; 
}

.intern-program-container a:hover {
  text-decoration: underline;
}
.profile-layout-left{
  height: auto;
  max-height: 70vh;
  position: sticky;
  top: 15%;
  overflow-y: scroll;
  background: var(--bs-white);
  box-shadow: 0px 0px 36px -2px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}
.profile-layout-right{
  height: auto;
  max-height: 80vh;
  position: sticky;
  top: 15%;
}
.env-press-container {
  position: relative;
  margin-bottom: 1rem;
}

.env-press-image {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 10px;
}

.env-press-overlay {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.sponsor-press-overlay{
  top:auto;
  bottom: 0;
  height: auto;
}
.env-press-heading {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 22px;
  line-height: 22px;
  margin: 14px 0;
}

.env-press-subheading {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
@media (max-width: 1024.98px) {
  .env-press-subheading {
    font-size: 1rem;
  }
}

.env-press-description {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding: 22px;
}
.grey-content-box {
  background-color: #f7f7f7;
  padding: 16px;
  border-radius: 8px;
}

.signup-modal-title {
  font-size: 16px;
  margin-bottom: 16px;
}


.grey-content-box .btn-outline-secondary {
  border: none;
  color: #333;
  background-color: white;
}
.grey-content-box .btn-outline-green:hover {
  color: var(--green-color) !important;
  background-color: var(--bs-white) !important;
  border-color: var(--green-color) !important;
}
.grey-content-box .btn-check:checked+.btn, 
.grey-content-box .btn.active, .btn.show, 
.grey-content-box .btn:first-child:active, 
.grey-content-box :not(.btn-check)+.btn:active {
  color: none;
  background-color: none;
  border-color: none;
}
.tc-search-result-row .species-item {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 5px;
  border-radius: 10px;
}

.tc-search-result-row .species-image img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px; /* Optional for rounded corners */
}

.tc-search-result-row .species-content {
  display: flex;
  flex-direction: column;
}

.tc-search-result-row .species-name {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.tc-search-result-row .species-scientific-name {
  font-size: 12px;
  color: #777;
}
.tc-search-result-row .green-background{
  border:1px solid var(--green-color) !important;
}
@media (max-width: 991.98px) {
  .post-container .ei-category-nav {
      overflow-y: none;
  }
}
.intern-program-custom a:hover{
  color: var(--green-color) !important;
}
.ei-grid-box-overlay-custom{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    color: var(--bs-white);
    display: flex;
    align-items: end;
    padding: 13px 16px;
    font-weight: 500;
    font-size: 14px;
}

.contact-intro-img img {
  border-radius: 10px;
}
.custom-form-floating.form-floating>.form-control{
  line-height: 1.5;
}
@media (min-width: 1024px) {
  .intern-program-container .hw-title h1{
    font-size: 42px;
  }
}

.edit-btn-link {
  color: var(--green-color) !important;
  text-decoration: underline;
}
.edit-btn-link:hover {
  color: black !important;
}
.sustainability-hero-content{
  padding-left: 0px;
}
.home-page-banner{
  margin-top: -8px;
}
.home-page-banner h4,
.home-page-banner h5,
.home-page-banner h6{
  font-weight: 500;
}
.home-page-left::-webkit-scrollbar ,
.home-page-right::-webkit-scrollbar,
.profile-layout-left::-webkit-scrollbar{
  display: none;
}
@media (min-width: 2024px) {
  .home-page-left,
  .home-page-right {
      position: sticky;
      top: 8%;
      height: auto;
      max-height: 70vh;
      transition: max-height 0.3s ease;
    }
}
.sponsor-list {
  width: calc(100% + 45px);
  margin-left: 0px;
  margin-bottom: -13px;
}
@media (max-width: 768px) {
 .env-press-container {
   width: 34%;
   margin-right: 12px;
   margin-left: 5px;
  }
}
@media (max-width: 500px) {
  .env-press-heading {
   font-size: 12px;
   margin: 4px 0px;
   line-height: 14px;
  }
  .env-press-subheading {
    font-size: 10px;
 }
 .env-press-description{
  padding: 0px;
  font-size: 8px;
  line-height: 8px;
  margin-bottom: 5px !important;
 }
 .tbc-organization-list{
  width: calc(100% + 12px);
 }
 .listing-content-heading-mob {
      padding-right: 0px;
 }
}
@media (max-width: 375.99px) {
  .env-press-heading {
   font-size: 10px;
  }
  .env-press-subheading {
    font-size: 8px;
 }
 .env-press-description{
  font-size: 6px;
 }
}
.fwm-content-box p{
  word-wrap: break-word;
}
.fwm-content-box a,
.widget-text-holder a{
  max-width: 100%;
}
.widget-text-holder div{
  word-wrap: break-word;
}
.payment-btn{
  padding: 20px 10px;
  border: 1px solid #47ad1d;
  border-radius: 10px;
  justify-content: center;
  margin-left: 10px;
}
.payment-btn:hover{
  background-color: #47ad1d;
  color: #fff;
}
.edit-btn img {
  width: 16px;
  height: 16px;
  object-fit: cover;
}
.edit-green-btn {
  font-size: 13px;
  font-weight: 400;
  text-align: end;
}
.sponsor-species-card{
  background: linear-gradient(358.84deg, rgba(0, 0, 0, 0.8) 1.3%, rgba(0, 0, 0, 0) 100%);
  height: 100%;
  width: 100%
}
.sponsor-species-card p{
  margin-top: 30%;
  padding-left: 5px;
}
.story-species-img{
  width: 100px;
  height: 80px;
}
.add-new-chat-widget{
  height: 100vh;
  max-height: 70vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105%;
}

@media (min-width: 1280.99px) {
  .panel-main-content-chat{
    width: 75%;
  }
}
.lb-member-img > div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.preview-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.preview-image {
  max-width: 100px;
  max-height: 100px;
  border-radius: 5px;
  object-fit: cover;
}


.skeleton-cutom>span{
  margin-top: -4px;
}
.custom-transparent-modal .modal-content {
  background: transparent;
  border: none;
  box-shadow: none;
}

.custom-transparent-modal .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}
.date-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  color: #aaa;
}

.date-divider hr {
  flex: 1;
  border: none;
  border-top: 1px solid #ccc;
  margin: 0 10px;
}

.date-label {
  font-size: 14px;
  font-weight: 600;
}
.preview-wrapper {
  position: relative; 
  display: inline-block;
  margin: 10px;
}

.preview-image {
  width: 150px;
  height: auto;
  display: block;
  border-radius: 4px;
}

.remove-preview {
  position: absolute;
  top: -5px; 
  right: -5px; 
  background-color: white;
  color: black; 
  border: 1px solid #ccc; 
  border-radius: 50%;
  width: 32px; 
  height: 32px; 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
  z-index: 2;
}

.remove-preview:hover {
  background-color: #f0f0f0; 
  transform: scale(1.1);
}
.custom-offcanvas {
  position: fixed;
  top: 0;
  right: 0;
  width: 606px !important; 
  height: 100vh;
  background: #fff;
  transition: transform 0.3s ease-in-out;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
  z-index: 99999;
}
.custom-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}
.filter-margin {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.offcanvas-header {
  padding: 40px 40px 25px;
}
.custom-intern-container{
  border: 1px solid #000;
  padding: 30px;
  border-radius: 10px;
}
.sustainability-container{
  padding: 10px;
  border: 1px solid #47AD1D;
  border-radius: 10px;
}
@media (max-width: 768px) {
 .profile-layout-right {
   top: 1%;
 }
 .panel-sidebar-right .panel-close-button {
   left: auto;
  }
  .payment-btn{
    margin-left: 0px;
  }
}
@media (max-width:474px) {
  .grey-content-box .btn{
    min-width: 75px;
    padding: 0px;
  }
}
.has-select-button button.select-button-box {
  right: 18px;
}
.corporate-sponcers-container,
.individual-sponcers-container{
  background-color: #F4F5F7;
}
.post-bottom-img video,
.post-bottom-img iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  object-fit: cover;
}
.sponsor-list-item{
  border: 1px solid #e4e4e4;
  border-radius: 10px;
}
.bsd-origin-box{
  color: #47ad1d;
}
.bsd-origin-box:before{
  background: #47AD1D;
}
.sbr-cta .btn {
  font-weight: 500;
}
.sponsor-form-title h1{
  font-size: 50px;
  font-weight: 600;
}
.content-container.sponsor-form-container{
  padding:45px 0; 
}
.picker {
  border-radius: 10px;
}
.picker-trigger .form-control-color {
  border-radius: 10px;
}
.sponsor-form-container .custom-form-floating.form-floating.xl-size > .form-control, .sponsor-form-container .custom-form-floating.form-floating.xl-size>.form-select {
  font-size: 16px;
}
.registration-flow-container .css-1dimb5e-singleValue, 
.css-qbdosj-Input, .css-1jqq78o-placeholder{
  padding-left: 0px;
}
.activity-instructions-box{
  padding: 0px;
}
.gallery-map .grid {
  width: calc(100% - 16px);
  margin-left: 10px;
}
.container1-print ,
.print-draw-page,
.print-write-page{
  display: none;
}
.condition-text{
  font-size: 10px;
  padding-left: 15px;
  line-height: initial;
  padding-top: 5px;
  width: 25%;
}
.professor-details-box{
  border-radius: 10px;
  font-size: 16px;
} 
.gc-detail-text h6 {
  font-family: 'Poppins';
}
.glb-content h5 ,
.green-dot-item{
  font-size: 18px;
}
.gc-bottom-wrapper p {
  font-size: 16px;
}
@media (max-width:991.9px) {
  .condition-text{
    width: 100%;
    padding-left: 5px;
  }
}
.divTable{
  display: none;
}
.print-map{
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
.print-species{
  display: none;
}
.print-map-none{
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
.print-region{
  display: none;
}
@media print {
  body {margin: 0;padding: 0;background: #f4f4f4;color: #1E1E1E;}
  body {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
  @page { 
    margin: 0;
    padding: 15px; 
  } 
  .btn {
    display: none !important;
  }
  .header-ei{
    display: none !important;
  }
  .print-form-head{
    display: block !important;
  }
  .print-details-head{
    display: block !important;
  }
  .navbar-toggler ,
  .header-right{
    display: none !important;
  }
  #mainNav{
    display: none !important;
  }
  .full-width-search-row{
    display: none;
  }
  .tabs-block-container{
    display: none;
  }
  .custom-citation{
    display: none;
  }
  #footer{
    display: none;
  }
  .listing-details-container{
    display: flex !important;
    flex-wrap: nowrap !important;
  }
  .ldc-image{
    width: 40% !important;
    height: 260px;
  }
  .ldc-text{
    width: 60% !important;
    padding: 0px 0px;
    margin-top: 0px;
    padding-left: 30px;
  }
  .ldc-text-head{
    width: 100% !important;
  }
  .ldc-text-main-icon,
  .print-icon{
    display: none !important;
  }
  .ldc-text-head{
    border-bottom: 1px solid gray;
    padding: 0;
  }
  .ltb-details-item{
    width: 50% !important;
    margin-bottom: 5px !important;
  }
  .species-details-img{
    height: 100%;
  }
  .species-details-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .text-dangerLevel {
    font-size: 10px;
  }
  .species-name h1{
    font-size: 22px;
  }
  .scientific-text {
    font-size: 12px !important;
  }
  .ldc-text-head>small{
    margin-top: 0px;
  }
  .print-text-small{
    font-size: 10px;
    margin-top: -8px !important;
  }
  .ltb-details-list{
    margin-top: 0px !important;
  }
  .ltb-details-item h4{
    font-size: 10px !important;
    font-weight: 500;
    margin-top: 5px !important;
  }
  .ltb-details-item p{
    font-size: 8px !important;
  }
  .print-form{
    background-color:#fff!important;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
  .print-form .content-container{
    background-color: #f4f4f4 !important;
    margin-top: 7%;
  }
  .print-form .container{
    background-color: #FFF;
    padding: 20px;
    width: 90%;
    margin: auto;
    border-radius: 10px;
  }
  .print-form .ldc-text-main{
    margin-top: 0px;
  }
  .print-form .ldc-text-head{
    border-bottom: none;
  }
  .print-form .ltb-details-list{
    display: flex;
    flex-wrap: wrap;
  }
  .print-form .ltb-details-item h4{
    font-weight: 700;
  }
  .print-form .tbc-details{
    margin-top: 20px !important;
    font-size: 12px;
  }
  .species-description-container{
    display: block !important;
  }
  .print-form .container1-print{
    display: block;
    background-color: #FFF;
    width: 90%;
    margin: auto;
    border-radius: 10px;
    margin-top: 30px;
    padding: 20px;
    break-inside: auto;
  }
  .print-form .activity-instructions-box{
    border-radius: 10px;
  }
  .print-form .activity-instructions-box ol{
    font-size: 12px;
  }
  .print-draw-page ,
  .print-write-page{
    display: block;
    break-inside: avoid;
  }
  .print-draw-page {
    break-inside: avoid;
  }
  .print-draw-page ,
  .print-write-page {
    margin: 20px;
    font-size: 16px;
    color: #333;
  }

  .print-draw-page h5 ,
  .print-write-page h5 {
    color: #000;
    margin-top: 70px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px !important;
  }

  .print-draw-page p ,
  .print-write-page p {
    margin: 15px 0;
    line-height: 1.5;
    font-size: 12px;
  }
  .drawing-box {
    margin: 20px auto;
    margin-top: 30px;
    height: calc(100vh - 300px);
    border: 1px solid #000;
    display: block;
  }
  .write-box {
    margin: 20px auto;
    height: calc(100vh - 300px);
    border: 1px solid #000;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
  }
  .write-title{
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 12px;
  }
  
  .write-lines {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .write-line {
    flex: 1;
    border-bottom: 1px solid #000;
    margin-bottom: 5px; 
  }

  .write-line:last-child {
    border-bottom: none;
  }

  .print-page{
    display: none;
  }
  .divTable {display: table;width: 100%;width:100%;max-width:700px;margin:0 auto;padding: 15px 20px;background-color: #fff;}
  .divTableHeading{display:table-header-group;}
  .divTableRow{display:table-row;}
  .divTableCell{display:table-cell;padding:0;}
  .divTableHead{display:table-cell;vertical-align:middle;padding:0;}  
  .divTableBody{display:table-row-group;}
  .divTableBody .divTableRow .divTableCell{padding: 0;vertical-align: top;}
  .divTableFoot{display:table-footer-group;}
  table{border-collapse: collapse;width:100%;}
  td{padding: 0;}
  .table{width:100%;}
  .print-map-none{
    display: none;
  }
  .print-map{
    display: table;
    visibility: visible;
    height: auto;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: 15px 20px;
    background-color: #fff;
    break-inside: avoid;
  }
  .print-species{
    display: block;
    padding-top: 30px;
  }
  .print-form .ltb-details-region{
    display: none;
  }
  .print-form .print-region{
    display: block;
  }
}