:root {
    --green-color: #47ad1d;
    --dark-green-color: #227300;
    --dark-black: #1e1e1e;
    --black: #000;
    --white:#fff;
}

@font-face {
    font-family: "Metropolis";
    src: url("../fonts/MetropolisRegular.eot");
    src: url("../fonts/MetropolisRegular.eot") format("embedded-opentype"), url("../fonts/MetropolisRegular.woff2") format("woff2"), url("../fonts/MetropolisRegular.woff") format("woff"), url("../fonts/MetropolisRegular.ttf") format("truetype"), url("../fonts/MetropolisRegular.svg#MetropolisRegular") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Metropolis";
    src: url("../fonts/MetropolisMedium.eot");
    src: url("../fonts/MetropolisMedium.eot") format("embedded-opentype"), url("../fonts/MetropolisMedium.woff2") format("woff2"), url("../fonts/MetropolisMedium.woff") format("woff"), url("../fonts/MetropolisMedium.ttf") format("truetype"), url("../fonts/MetropolisMedium.svg#MetropolisMedium") format("svg");
    font-weight: 500;
    font-style: normal;
}


/*1. Animate css, 2. Smartmenus css, 3. Slick css, 4. Reset css, 5. Core css, 6. custom css*/


/* =================animate css starts here================= */

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 30px, 0);
        transform: translate3d(0, 30px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 30px, 0);
        transform: translate3d(0, 30px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}


/* =================animate css ends here================= */


/* =================smartmenus css start here================= */

.navbar-nav.sm-collapsible .sub-arrow {
    position: absolute;
    top: 50%;
    right: 0;
    margin: -0.7em 0.5em 0 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    padding: 0;
    width: 2em;
    height: 1.4em;
    font-size: 1.25rem;
    line-height: 1.2em;
    text-align: center;
}

.navbar-nav.sm-collapsible .sub-arrow::before {
    content: "+";
}

.navbar-nav.sm-collapsible .show>a>.sub-arrow::before {
    content: "-";
}

.navbar-dark .navbar-nav.sm-collapsible .nav-link .sub-arrow {
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-nav.sm-collapsible .has-submenu {
    padding-right: 3em;
}

.navbar-nav.sm-collapsible .nav-link,
.navbar-nav.sm-collapsible .dropdown-item {
    position: relative;
}

.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
}

.fixed-bottom .navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow,
.fixed-bottom .navbar-nav:not(.sm-collapsible):not([data-sm-skip]) .dropdown-toggle::after {
    border-top: 0;
    border-bottom: 0.3em solid;
}

.navbar-nav:not(.sm-collapsible) .dropdown-item .sub-arrow,
.navbar-nav:not(.sm-collapsible):not([data-sm-skip]) .dropdown-menu .dropdown-toggle::after {
    position: absolute;
    top: 50%;
    right: 0;
    width: 0;
    height: 0;
    margin-top: -0.3em;
    margin-right: 1em;
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}

.navbar-nav:not(.sm-collapsible) .dropdown-item.has-submenu {
    padding-right: 2em;
}

.navbar-nav .scroll-up,
.navbar-nav .scroll-down {
    position: absolute;
    display: none;
    visibility: hidden;
    height: 20px;
    overflow: hidden;
    text-align: center;
}

.navbar-nav .scroll-up-arrow,
.navbar-nav .scroll-down-arrow {
    position: absolute;
    top: -2px;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    overflow: hidden;
    border-top: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid;
    border-left: 7px solid transparent;
}

.navbar-nav .scroll-down-arrow {
    top: 6px;
    border-top: 7px solid;
    border-right: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid transparent;
}

.navbar-nav.sm-collapsible .dropdown-menu .dropdown-menu {
    margin: 0.5em;
}

.navbar-nav:not([data-sm-skip]) .dropdown-item {
    white-space: normal;
}

.navbar-nav:not(.sm-collapsible) .sm-nowrap>li>.dropdown-item {
    white-space: nowrap;
}


/* =================smartmenus css ends here================= */


/* =================slick css ends here================= */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    display: table;
    content: "";
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

.slick-slide img,
.slick-slide video {
    display: block;
    width: 100%;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}


/* .slick-loading .slick-list{background:var(--bs-white) url("../images/ajax-loader.gif") center center no-repeat;} */

.slick-prev,
.slick-next {
    z-index: 99;
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    float: left;
    padding: 0;
    margin-top: -25px\9;
    /*lte IE 8*/
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    width: 62px;
    height: 63px;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 0.3;
}

.slick-prev:before,
.slick-next:before {
    font-family: "font Awesome 5 Free";
    font-weight: 900;
    font-size: 50px;
    line-height: 1;
    opacity: 0.9;
    color: var(--bs-white);
    text-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: transparent;
    border-radius: 50%;
    display: inline-block;
    padding: 0;
}

.slick-prev {
    left: 15px;
}

.slick-prev:before {
    content: "\f053";
}

.slick-next {
    right: 15px;
}

.slick-next:before {
    content: "\f054";
}

.slick-slider {
    margin-bottom: 0;
    padding-bottom: 45px;
}

.slick-dots {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 0px;
    padding: 0;
    text-align: center;
    cursor: pointer;
    vertical-align: top;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    opacity: 1;
    border: solid 1px var(--black);
    border-radius: 50%;
    vertical-align: top;
    margin: 5px 6px;
}

.slick-dots li.slick-active button,
.slick-dots li:hover button {
    opacity: 1;
    background-color: var(--black);
}

.white-dots .slick-dots li button {
    border-color: var(--bs-white);
    background: var(--bs-white);
    opacity: 0.4;
}

.white-dots .slick-dots li.slick-active button,
.white-dots .slick-dots li:hover button {
    background-color: var(--bs-white);
    opacity: 1;
}


/* =================slick css end here=================  */


/* =================reset css starts here=================  */

* {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
body,
html,
form,
fieldset {
    margin: 0;
    padding: 0;
    outline: none;
    border: 0;
}

a {
    text-decoration: none;
    border: 0;
    outline: 0;
}

ul {
    list-style: none;
}

a:focus,
input:focus,
textarea:focus,
*:focus {
    outline: 0 !important;
}


/* =================reset css ends here================= */


/* =================core css starts here================= */

body {
    padding-top: 80px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-family: "IBM Plex Sans", sans-serif;
    color: var(--black);
    background: var(--bs-white);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.container {
    width: calc(100% - 16px);
    max-width: 1344px;
}

.custom-citation {
    margin-top: 20px;
}

a,
input,
button {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    outline: none;
    transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
}

a img {
    border: 0px none;
}

a:hover {
    outline: none;
    color: var(--green-color);
    text-decoration: none;
}

a:active {
    outline: none;
    text-decoration: none;
}

a:focus {
    outline: none;
    outline-offset: 0px;
    text-decoration: none;
    color: inherit;
}

::-webkit-input-placeholder {
    color: var(--black);
    opacity: 0.5;
}

::-moz-placeholder {
    color: var(--black);
    opacity: 0.5;
}

:-moz-placeholder {
    color: var(--black);
    opacity: 0.5;
}

:-ms-input-placeholder {
    color: var(--black);
    opacity: 0.5;
}

.os-animation {
    opacity: 0;
}

.os-animation.animated {
    opacity: 1;
}

img {
    max-width: 100%;
    border: 0;
    height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
    padding: 0px;
    margin-bottom: 24px;
    line-height: 1.5;
    font-weight: 700;
    color: inherit;
    font-family: "Poppins", sans-serif;
}

h1 {
    font-size: 58px;
}

h2 {
    font-size: 48px;
}

h3 {
    font-size: 40px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 18px;
}

p {
    margin: 0px;
    padding: 0px;
    margin-bottom: 24px;
}

strong {
    font-weight: 700;
}

b {
    font-weight: 400;
}

p:last-child {
    margin-bottom: 0;
}

.btn {
    border-radius: 4px;
    font-weight: 500;
    padding: 7px 20px;
    min-width: 131px;
    font-size: 14px;
    line-height: 18px;
    box-shadow: none;
    transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    font-family: "Poppins", sans-serif;
}

.btn-default,
.btn-default:focus {
    color: var(--bs-white);
    background-color: var(--green-color);
    border: solid 1px var(--green-color);
}

.btn-default:hover,
.btn-default:active {
    color: var(--bs-white) !important;
    background-color: var(--dark-green-color) !important;
    border-color: var(--dark-green-color) !important;
}

.btn-primary,
.btn-primary:focus {
    color: var(--bs-white);
    background-color: #333;
    border: solid 1px #333;
}

.btn-primary:hover,
.btn-primary:active {
    color: #333;
    background-color: transparent;
    border-color: #333;
}

.btn-white,
.btn-white:focus {
    color: var(--black);
    background-color: var(--bs-white);
    border: solid 1px var(--bs-white);
}

.btn-white:hover,
.btn-white:active {
    color: var(--bs-white);
    background-color: var(--black);
    border-color: var(--black);
}

.btn-grey,
.btn-grey:focus {
    color: var(--black);
    background-color: #f5f5f5;
    border: solid 1px #f5f5f5;
}

.btn-grey:hover,
.btn-grey:active {
    color: var(--bs-white);
    background-color: var(--black);
    border-color: var(--black);
}

.btn-xl {
    font-size: 18px;
    padding: 12px 10px;
    line-height: 1.2;
}

.header-btn {
    min-width: 99px;
}

.btn.btn-block {
    min-width: 100%;
}

.btn-dark-green {
    background-color: var(--dark-green-color);
    border-color: var(--dark-green-color);
    color: var(--bs-white);
}

.btn-dark-green:hover {
    color: var(--bs-white) !important;
    background-color: var(--green-color) !important;
    border-color: var(--green-color) !important;
}

.btn-outline-green{background-color:transparent;border:1px solid var(--green-color);color:var(--green-color);}
.btn-outline-green:hover{color:var(--bs-white)!important;background-color:var(--green-color)!important;border-color:var(--green-color)!important;}

.btn-green-white,.btn-green-white:focus{color:var(--green-color);background-color:var(--bs-white);border:solid 1px var(--bs-white);}
.btn-green-white:hover,.btn-green-white:active{color:var(--bs-white);background-color:var(--black);border-color:var(--black);}
.btn+.btn {
    margin-left: 10px;
}

.btn.btn-lg {
    padding: 16px 15px;
    font-size: 14px;
    line-height: 1.1;
    border-radius: 6px;
    min-width: 272px;
}

.btn.btn-md {
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
    min-width: 100px;
    padding: 10px 15px;
}

.btn-sm {
    min-width: 99px;
}

.btn-dark {
    background: var(--black);
}

.btn-check:focus+.btn,
.btn:focus {
    box-shadow: none !important;
}

ul:not([class]) {
    list-style: none;
    margin-bottom: 15px;
    text-align: left;
    line-height: 22px;
}

ul:not([class])>li {
    padding-left: 16px;
    position: relative;
    margin-bottom: 14px;
}

ul:not([class])>li:last-child {
    margin-bottom: 0;
}

ul:not([class])>li:before {
    content: "";
    position: absolute;
    left: 2px;
    top: 10px;
    background: var(--black);
    height: 5px;
    width: 5px;
    border-radius: 50%;
}

.grey-bg {
    background: #f5f5f5;
}


/* =================core css ends here================= */


/* ================= Header Start ================= */

#header {
    color: #1e1e1e;
    background: var(--bs-white);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    padding: 0;
    z-index: 9999;
    transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    display: flex;
    align-items: center;
}

.fixed #header {
    background: var(--bs-white);
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
}

.navbar {
    padding: 0;
    width: 100%;
    height: 80px;
}

.navbar-brand {
    width: 189px;
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 999;
}

.navbar-expand-xl .navbar-nav .nav-item {
    margin: 0 0 0 25px;
}

.navbar-expand-xl .navbar-nav .nav-link {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #1e1e1e;
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;
}

.navbar-expand-xl .navbar-nav .nav-item:hover .nav-link,
.navbar-expand-xl .navbar-nav .nav-item.active .nav-link {
    color: var(--green-color);
}

.dropdown-toggle::after {
    display: none;
}

.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
    display: none;
}

.nav-inside {
    width: 100%;
    padding: 0;
}

.header-right {
    margin-left: 25px;
}

.nav-inside-content {
    padding: 30px 0;
    text-align: start !important;
}


/* @media only screen and (min-width:1200px){
    .navbar-nav > li > .dropdown-menu{display:block!important;top:100%!important;margin-top:10px!important;opacity:0;visibility:hidden;background:#f00;color:var(--bs-white);min-width:180px;box-shadow:0 5px 8px rgba(0,0,0,0.2);border:0;border-radius:0;transition:all 0.1s ease-in;-moz-transition:all 0.1s ease-in;-webkit-transition:all 0.1s ease-in;}
    .dropdown:hover .dropdown-menu{opacity:1;visibility:visible;margin-top:0px!important;transition:all 0.2s ease-in;-moz-transition:all 0.2s ease-in;-webkit-transition:all 0.2s ease-in;}
    .dropdown-item{color:var(--bs-white);padding:5px 15px;font-weight:400;}
    .dropdown-item:focus,.dropdown-item:hover{color:#333;background:transparent;}
} */

.navbar-toggler {
    width: 45px;
    height: 45px;
    border-radius: 0;
    cursor: pointer;
    text-decoration: none;
    padding: 0;
    border: 0;
    background: transparent;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
}

.navbar-default .navbar-toggler:hover,
.navbar-default .navbar-toggler:focus {
    background: transparent;
}

.navbar-toggler:not([class="collapsed"]),
.navbar-toggler:not([class="collapsed"]):focus,
.navbar-toggler:not([class="collapsed"]):hover {
    background: transparent;
    box-shadow: none;
}

.navbar-toggler.collapsed,
.navbar-toggler.collapsed:focus,
.navbar-toggler.collapsed:hover {
    background: transparent;
}

.navbar-toggler .icon-bar {
    position: relative;
    display: inline-block;
    width: 22px;
    height: 4px;
    color: #243238;
    text-indent: -55px;
    margin-top: 0;
    background: transparent !important;
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    vertical-align: middle;
}

.navbar-toggler .icon-bar:before,
.navbar-toggler .icon-bar:after {
    content: "";
    width: 22px;
    height: 4px;
    background: #005983;
    position: absolute;
    left: 0;
    transition: all 0.2s ease-out;
}

.navbar-toggler.collapsed .icon-bar {
    background: #005983 !important;
}

.navbar-toggler.collapsed .icon-bar:before,
.navbar-toggler.collapsed .icon-bar:after {
    background: #005983;
}

.navbar-toggler.collapsed .icon-bar:before {
    top: -8px;
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
}

.navbar-toggler .icon-bar:before {
    top: 0;
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
    -o-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
}

.navbar-toggler.collapsed .icon-bar:after {
    bottom: -8px;
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
}

.navbar-toggler .icon-bar:after {
    bottom: 0;
    -webkit-transform: rotateZ(-45deg);
    -moz-transform: rotateZ(-45deg);
    -ms-transform: rotateZ(-45deg);
    -o-transform: rotateZ(-45deg);
    transform: rotateZ(-45deg);
}


/* ================= Header End ================= */

.ei-category-container .collapse:not(.show) {
    display: none !important;
}

@media (min-width: 991.98px) {
    .ei-category-container .tab-content>.tab-pane {
        display: block;
        opacity: 1;
    }
}

@media (max-width: 991.98px) {
    .ei-category-container .collapse:not(.show) {
        display: block !important;
    }
}

.mt-60 {
    margin-top: 60px;
}

.hero-container-text h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 150%;
    color: #1e1e1e;
    margin-bottom: 0;
}

.hero-container-image {
    width: 54.8%;
    margin-top: 30px;
}

.hero-container-content {
    align-items: center;
}

.hero-container {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.hci-inner {
    width: calc(100% + 166px);
    margin-left: 71px;
}

.dog-paw {
    position: absolute;
    top: 15%;
    left: 37.5%;
}

.bird-paw {
    position: absolute;
    bottom: 18.5%;
    left: 4%;
}

.scroll-down-btn {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 11%;
    left: 100px;
}

.scroll-down-btn>img {
    margin-right: 10px;
}

.scroll-down-btn>span {
    line-height: 160%;
    color: #89b5ab;
    opacity: 0.7;
}


/* ............ */

.ei-category-accordion .accordion-button {
    background: var(--bs-white);
    border: none;
    box-shadow: none;
}

.ei-category-accordion .accordion-item {
    border: none;
    padding: 9px 0 10px 0;
}

.ei-category-left {
    width: 310px;
}

.ei-category-accordion {
    background-color: var(--bs-white);
    box-shadow: 0px 0px 36px -2px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 18px;
    overflow-y: auto;
    height: auto;
}

.ei-category-accordion .accordion-button {
    color: var(--black);
    font-weight: 500 !important;
    font-size: 16px;
    line-height: 1.5;
    padding: 8px 0px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
}

.ei-category-accordion .accordion-item:first-child {
    padding-top: 0;
}

.ei-category-accordion .accordion-item:last-child {
    padding-bottom: 0;
}


.ei-category-accordion .accordion-body {
    padding: 0;
}

.ei-category-box {
    font-size: 14px;
    line-height: 1.3;
    width: 100%;
}

.ei-category-item {
    padding: 5px 0;
}

.ei-category-list {
    margin-top: 10px;
}

.ei-category-accordion .accordion-button:not(.collapsed)::after {
    background-image: url(../images/minus.svg);
}

.ei-category-accordion .accordion-button::after {
    background-image: url(../images/plus.svg);
    height: 24px;
    width: 24px;
    background-size: 100%;
}


/* --------------- */

.ei-category-right {
    width: calc(100% - 310px);
    padding-left: 42px;
}

.ei-heading h4 {
    line-height: 1.5;
    font-weight: 600;
    margin-bottom: 0;
}

.ei-grid-item {
    padding: 0 6px;
    width: 25%;
    margin-bottom: 12px;
}

.ei-heading>.btn-link {
    width: 80px;
    text-align: end;
}

.ei-grid-list {
    width: calc(100% + 12px);
    margin-left: -6px;
    margin-bottom: -12px;
}

.ei-grid-5 .ei-grid-item {
    width: 20%;
}

.ei-grid-box-image {
    background: #e1e1e1;
    position: relative;
    padding-bottom: 100%;
}

.ei-grid-box-image>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.ei-grid-box {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
    display: block;
    font-family: "Poppins", sans-serif;
}

.ei-grid-box-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(358.84deg, rgba(0, 0, 0, 0.8) 1.3%, rgba(0, 0, 0, 0) 43.49%);
    width: 100%;
    height: 100%;
    color: var(--bs-white);
    display: flex;
    align-items: end;
    padding: 13px 16px;
    font-weight: 500;
    font-size: 14px;
}

.ei-grid-box:hover .ei-grid-box-image>img {
    transform: scale(1.1);
}

.ei-heading+.ei-grid-list {
    margin-top: 20px;
}

.ei-grid-box-overlay p {
    width: 100%;
    line-height: 1.3;
    position: relative;
    margin-bottom: 0;
}

.ei-grid-box-icon {
    margin-left: 20px;
}

.ei-grid-list+.btn-xl {
    margin-top: 20px;
}


/* -------------- */

.ei-statistics-container {
    padding: 32px 34px;
    background: linear-gradient(94.18deg, var(--green-color) -29.36%, var(--dark-green-color) 168.78%);
    border-radius: 16px;
    color: var(--bs-white);
}

.ei-statistics-item {
    width: 33.33%;
    padding: 0 15px;
    margin-bottom: 25px;
}

.ei-statistics-box {
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 9px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    font-family: "Poppins", sans-serif;
}

.ei-statistics-list {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-bottom: -25px;
    margin-top: 20px;
}

.esb-text {
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0.02em;
    text-transform: uppercase;
}

.esb-number {
    font-weight: 600;
    font-size: 32px;
    line-height: 1.4;
    letter-spacing: 0.03em;
}

.ei-statistics-item:nth-last-child(-n+3):nth-child(3n+1) .ei-statistics-box,
.ei-statistics-item:nth-last-child(-n+3):nth-child(3n+1)~.ei-statistics-item .ei-statistics-box {
    border-bottom: none;
}


/* -------------------- */

.news-item {
    width: 33.33%;
    padding: 0 34px;
    position: relative;
    margin-bottom: 40px;
}

.news-box {
    display: block;
}

.news-list {
    width: calc(100% + 52px);
    margin-left: -26px;
    margin-top: 30px;
    margin-bottom: -40px;
}

.btn-link {
    font-weight: 600;
    font-size: 14px;
    color: var(--green-color);
    text-decoration: none;
}

.nb-head-left p {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0.005em;
    color: var(--dark-green-color);
}

.badge {
    font-weight: 500;
    font-size: 12px;
    padding: 0 6px;
    line-height: 1.5;
    border-radius: 4px;
    margin-left: 10px;
}

.badge-green {
    background: var(--green-color);
}

.nb-head.d-flex {
    justify-content: space-between;
}

.nb-head-date {
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0.005em;
    color: rgb(51 51 51 / 60%);
}

.nb-body h6 {
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.nb-body {
    margin-top: 18px;
}

.nb-body p {
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0.015em;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.btn-link:hover {
    color: black;
}

.news-item::after {
    border-right: 1px solid #e4e4e4;
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    right: 0;
    top: 0;
}

.news-item:nth-child(3n):after {
    display: none;
}

.news-box {
    position: relative;
}

.news-item .news-box:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -20px;
    width: 100%;
    height: 1px;
    background-color: #e4e4e4;
}

.news-item:nth-last-child(-n+3):nth-child(3n+1) .news-box:after,
.news-item:nth-last-child(-n+3):nth-child(3n+1)~.news-item .news-box:after {
    display: none;
}

.separator-hr {
    height: 1px;
    background: #e4e4e4;
    margin: 29px 0 30px;
}


/* ------------- */

.zrb-left {
    width: 253px;
}

.zrb-right {
    width: calc(100% - 253px);
    height: 727px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.zoo-report-box {
    background-color: #f5f5f5;
    border: 1px solid #e4e4e4;
    border-radius: 16px;
    overflow: hidden;
    margin-top: 20px;
}

.zrb-left {
    background: var(--bs-white);
    padding: 12px 10px;
    height: 100%;
    box-shadow: 11px 4px 60px -2px rgba(0, 0, 0, 0.1);
}

.zoo-map-box {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #f5f5f5;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    display: block;
}

.zoo-map-item+.zoo-map-item {
    margin-top: 8px;
}

.zoo-map-box p {
    margin-bottom: 0px;
    line-height: 1.6;
}

.zoo-map-box span {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.6;
    display: inline-block;
}

.zoo-map-box:hover,
.zoo-map-box.active {
    background-color: var(--green-color);
    color: var(--bs-white);
    background-image: none;
}

.zoo-map-footer {
    padding: 20px 10px 10px;
}


/* ----------------------- */

.blog-box {
    display: block;
    background: var(--bs-white);
    box-shadow: 0px 4px 20px -2px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    height: 100%;
    display: flex;
    flex-flow: column;
}

.blog-item {
    width: 33.33%;
    padding: 0 12px;
    margin-bottom: 24px;
}

.blog-list {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-top: 20px;
    margin-bottom: -24px;
}

.blog-box-image {
    border: 1px solid #e4e4e4;
    position: relative;
    padding-bottom: 55.47%;
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
}

.blog-box-image>img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s;
}

.blog-box-content {
    padding: 24px;
    padding-bottom: 32px;
}

.bbc-head {
    font-family: "IBM Plex Sans";
    font-weight: 500;
    font-size: 12px;
    line-height: 1.4;
    color: var(--dark-green-color);
}

.bbc-body h6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;
    color: #333333;
    margin-bottom: 0;
}

.bbc-head+.bbc-body {
    margin-top: 8px;
}

.bbc-footer {
    padding: 0 24px 0 24px;
    margin-top: auto;
}

.bbc-body p {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0.015em;
    margin-top: 10px;
}

.bbc-footer-inner {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgb(51 51 51 / 20%);
    padding: 16px 0 24px;
}

.bbc-footer-inner p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0.015em;
    color: rgb(51 51 51 / 60%);
}

.blog-box:hover .blog-box-image>img {
    transform: scale(1.1);
}

.bbc-footer-inner p>img {
    margin-right: 5px;
    margin-top: -3px;
}


/* ----- */

.blog-sm .blog-box-image {
    padding-bottom: 46%;
}

.blog-sm .blog-box-content {
    padding: 20px;
}

.blog-sm .bbc-body h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
}

.blog-sm .bbc-body p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.blog-sm .bbc-footer-inner {
    padding: 16px 0;
}

.blog-list-container+.blog-list-container {
    margin-top: 40px;
}


/* ------------------------- */


/* map */

.ei-map-box {
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 20px;
}

.ei-map-box>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* ------------------------- */


/* ------------- inner page css start -------------- */

.listing-container {
    margin-top: 30px;
    margin-bottom: 40px;
}

.filter-sidebar {
    width: 310px;
    background: var(--bs-white);
    box-shadow: 0px 0px 36px -2px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 18px;
    height: fit-content;
    overflow-y: auto;
    max-height: calc(100vh - 120px);
    top: 100px;
    position: sticky;
}

.listing-content-box {
    width: calc(100% - 310px);
    padding-left: 41px;
}

.filter-sidebar-head h6 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
}

.link-green {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: var(--dark-green-color);
    line-height: 18px;
    display: block;
}

.filter-sidebar-head {
    padding: 8px 0;
}

.fst-box p {
    font-size: 12px;
    margin-bottom: 0;
    font-family: "Poppins", sans-serif;
    line-height: 1.4;
    margin-right: 4px;
}

.fst-box {
    padding: 3px 6px;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    margin-left: 4px;
    margin-bottom: 6px;
}

.filter-sidebar-tag {
    margin-left: -4px;
    margin-bottom: -6px;
    padding: 10px 0;
}

.fst-box:hover {
    border-color: var(--green-color);
}


/* filter sidebar start  */

.filter-sidebar-accordion .accordion-item {
    border-radius: 0;
    border: none;
    padding: 10px 0;
    border-top: 1px solid #e4e4e4;
}

.filter-sidebar-accordion .accordion-button {
    background: var(--bs-white);
    box-shadow: none;
    padding: 8px 0;
    font-size: 16px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.filter-sidebar-accordion .accordion-button {
    color: var(--black);
}

.filter-content-box {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 13px;
    line-height: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: "Metropolis";
    padding: 9px 0 9px 36px;
    margin-bottom: 0;
}

.filter-content-box input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 7px;
    left: 1px;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 4px;
    background: var(--bs-white);
    border: 2px solid #e4e4e4;
    border-radius: 4px;
}

.filter-content-box:hover input~.checkmark {
    background-color: #e4e4e4;
}

.filter-content-box input:checked~.checkmark {
    background-color: var(--green-color);
    border: none;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.filter-content-box input:checked~.checkmark:after {
    display: block;
}

.filter-content-box .checkmark:after {
    left: 8px;
    top: 3px;
    width: 5px;
    height: 11px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.filter-sidebar-accordion .accordion-body {
    padding: 10px 0 0 0;
}

.filter-content-list+.link-green {
    margin-top: 9px;
}

.breadcrumb-row {
    font-family: "Metropolis";
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    padding: 1px 0;
    color: var(--dark-green-color);
    margin-left: -27px;
}

.breadcrumb-box {
    margin-left: 27px;
    position: relative;
}

.breadcrumb-box:not(:last-child):after {
    content: "";
    position: absolute;
    background-image: url(../images/breadcrumb-arrow.svg);
    width: 6px;
    height: 11px;
    background-repeat: no-repeat;
    top: 2px;
    right: -17px;
}

.breadcrumb-row+.ei-heading {
    margin-top: 15px;
}


/* filter sidebar start  */


/* sorting start  */

.sorting-row {
    font-size: 14px;
    font-weight: 500;
}

.sorting-row {
    margin: 10px 0 20px;
    padding-bottom: 7px;
    border-bottom: 1px solid #e4e4e4;
    line-height: 18px;
    padding-right: 80px;
    position: relative;
}

.ei-view-filter {
    position: absolute;
    right: 1px;
    top: -3px;
}

.evf-icon+.evf-icon {
    margin-left: 20px;
}

.evf-icon {
    opacity: 0.4;
}

.evf-icon.active {
    opacity: 1;
}

.sorting-row>p {
    margin-bottom: 0;
    opacity: 0.5;
    margin-right: 22px;
}

.sorting-item+.sorting-item {
    margin-left: 30px;
}

.sorting-box svg {
    margin-left: 3px;
    opacity: 0.5;
}

.sorting-box {
    font-weight: 400;
    position: relative;
}

.sorting-box::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    background: var(--green-color);
    bottom: -8px;
    left: 0;
    transition: all 0.3s;
}

.sorting-box:hover::after,
.sorting-box.active::after {
    width: 100%;
}

.sorting-box.active {
    font-weight: 500;
    color: var(--green-color);
}

.sorting-box.active svg {
    opacity: 1;
}

.sorting-box.active svg path {
    stroke: var(--green-color);
}


/* sorting end  */


/*------------- pagination start--------- */

.pagination-count-box h6 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1.5;
}

.pagination-count-box {
    width: 30px;
    background-color: #f5f5f5;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    margin: 0 5px;
    border-radius: 2px;
}

.pagination-row {
    margin-top: 30px;
}

.pagination-count-box:hover,
.pagination-count-box.active {
    background: var(--green-color);
    color: var(--bs-white);
}

.pagination-arrow-box {
    padding: 3px 8px;
}

.pagination-arrow-box span {
    display: block;
    font-weight: 500;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
}

.pagination-arrow-box> :first-child {
    margin-right: 20px;
}

.pagination-arrow-box>img {
    filter: brightness(0);
}

.pagination-arrow-box:hover img {
    filter: none;
}


/*------------- pagination end--------- */


/*------------- organisation page start--------- */

.organisation-box {
    background: var(--bs-white);
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    padding: 14px 21px 15px 15px;
}

.organisation-item {
    width: 50%;
    padding: 0 6.5px;
    margin-bottom: 13px;
}

.organisation-list {
    width: calc(100% + 13px);
    margin-left: -6.5px;
    margin-bottom: -13px;
}

.organisation-logo {
    width: 72px;
    height: 72px;
    border: 1px solid rgba(228, 228, 228, 0.6);
    border-radius: 7px;
    overflow: hidden;
    position: relative;
}

.organisation-logo>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.organisation-text {
    width: calc(100% - 72px);
    padding-left: 18px;
    padding-top: 3px;
}

.organisation-text h6 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 0;
}

.organisation-text-footer {
    margin-top: 13px;
}

.organisation-text-footer small {
    font-size: 12px;
    font-family: "Poppins";
    opacity: 0.5;
    display: block;
    line-height: 1.5;
}

.organisation-text-footer p {
    font-size: 12px;
    line-height: 1.5;
    margin-top: 3px;
}

.organisation-box:hover {
    color: var(--black);
}


/*------------- organisation page end--------- */

.filter-sidebar .accordion-button:not(.collapsed)::after {
    background-image: url(../images/acc-up.svg);
    transform: none;
}

.filter-sidebar .accordion-button::after {
    background-image: url(../images/acc-down.svg);
    height: 24px;
    width: 24px;
    background-size: 100%;
}


/* ------------- inner page css end -------------- */


/* ------------------------- detail page css start ---------------------- */

.tbc-head {
    position: relative;
    background-color: var(--dark-green-color);
    position: sticky;
    top: 80px;
    z-index: 99;
}

.tbc-head:after {
    z-index: -1;
}

.tbc-head .nav-pills .nav-link {
    color: var(--bs-white);
    font-size: 14px;
    font-weight: 500;
    padding: 18px 0;
    position: relative;
}

.tbc-head .nav-pills {
    justify-content: space-between;
    flex-wrap: wrap;
}

.tbc-head .nav-item+.nav-item {
    margin-left: 20px;
}

.tbc-head .nav-pills .nav-link.active,
.tbc-head .nav-pills .show>.nav-link {
    background-color: transparent;
}

.tbc-head .nav-pills .nav-link:after {
    position: absolute;
    bottom: 0;
    content: "";
    left: 0;
    width: 0;
    background: var(--bs-white);
    border-radius: 4px 4px 0px 0px;
    transition: 0.3s;
    height: 4px;
}

.tbc-head .nav-pills .nav-link.active:after,
.tbc-head .nav-pills .nav-link:hover:after {
    width: 100%;
}

.tbc-body-inner {
    padding: 80px 0;
    position: relative;
}

.tbc-details {
    position: relative;
    justify-content: space-between;
}

.tbc-details-right {
    width: 47%;
    line-height: 1.625;
    padding-top: 17px;
}

.tbc-details-left {
    width: 36.7%;
    position: relative;
    padding-bottom: 42.3%;
    border-radius: 11px;
    overflow: hidden;
}

.tbc-details-right p:not(:last-child) {
    margin-bottom: 26px;
}

.tbc-details-left>img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tbc-details-bg {
    position: absolute;
    right: 0px;
    top: 0;
    bottom: 0;
    width: 28%;
    z-index: -1;
    opacity: 0.34;
    filter: blur(3px);
    height: 100%;
}

.tbc-details-bg>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tbc-map-full {
    position: relative;
    padding-bottom: 40.25%;
    background-color: #f5f5f5;
    min-height: 428px;
}

.tbc-map-full>img {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.default-item {
    position: relative;
    line-height: 1.625;
    padding-left: 24px;
}

.default-item:after {
    content: "";
    position: absolute;
    left: 10px;
    top: 12px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: var(--black);
}

.tbc-grid-box {
    height: 100%;
    display: flex;
    flex-flow: column;
}

.tbc-grid-item {
    width: 50%;
    padding: 0 20px;
    margin-bottom: 68px;
    padding-right: 40px;
}

.tbc-grid-box h4 {
    color: black;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1.4;
    font-size: 18px;
}

.tbc-grid-box h4+* {
    margin-top: 15px;
}

.tbc-grid-list {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-bottom: -68px;
}

.tbc-organization-item {
    width: 20%;
    padding: 0 10px;
    margin-bottom: 20px;
}

.tbc-organization-list {
    width: calc(100% + 26px);
    margin-left: -6px;
    margin-bottom: -20px;
}

.tbc-organization-box {
    border: 1px solid #e4e4e4;
    border-radius: 6px;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.tbc-body-inner .ei-grid-item {
    margin-bottom: 20px;
    padding: 0 10px;
}

.tbc-body-inner .ei-grid-list {
    margin-bottom: -20px;
    width: calc(100% + 20px);
    margin-left: -10px;
}

.tbc-body-inner .ei-grid-list+.ei-heading {
    margin-top: 80px;
}

.cta-join-container {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), var(--green-color);
    padding: 52px 0;
    color: var(--bs-white);
}

.cta-join-content h6 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 20px;
}

.cta-join-content {
    font-size: 14px;
    padding: 0px 28px;
    line-height: 1.45;
}

.cta-join-content p {
    letter-spacing: 0.015em;
    margin-bottom: 35px;
}

.doner-box {
    background: var(--bs-white);
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    padding: 19px 20px 14px 19px;
    font-family: "Poppins", sans-serif;
    height: 100%;
    display: flex;
    flex-flow: column;
}

.doner-item {
    width: 25%;
    padding: 0 10px;
    margin-bottom: 20px;
}

.doner-list {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-bottom: -20px;
}

.doner-logo {
    display: flex;
    align-items: center;
}

.doner-logo>img {
    width: 40px;
    height: 40px;
    border: 1px solid rgba(228, 228, 228, 0.5);
    border-radius: 6px;
}

.doner-logo h6 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 16px;
    margin-left: 15px;
}

.doner-details {
    margin-top: auto;
    padding-top: 15px;
}

.doner-details>small {
    display: block;
    margin-bottom: 8px;
    line-height: 22px;
}

.doner-details p {
    font-weight: 500;
    line-height: 1.6;
}

.ei-heading+.doner-list {
    margin-top: 30px;
}

.cb-logo {
    width: 72px;
    height: 72px;
    border: 1px solid rgba(228, 228, 228, 0.6);
    border-radius: 7px;
    overflow: hidden;
}

.contributor-box {
    background: var(--bs-white);
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    padding: 15px;
    height: 100%;
}

.contributor-item {
    width: 33.33%;
    padding: 0 10.5px;
    margin-bottom: 21px;
}

.cb-text {
    width: calc(100% - 72px);
    padding-left: 18px;
    padding-right: 10px;
    font-size: 12px;
    line-height: 1.3;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
}

.contributor-list {
    width: calc(100% + 21px);
    margin-left: -10.5px;
    margin-top: 40px;
    margin-bottom: -20px;
}

.cb-text h6 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 7px;
}

.cb-text p:not(:last-child) {
    margin-bottom: 14px;
}

.cb-text p>span {
    font-family: "Poppins";
    opacity: 0.5;
}

.cb-text .btn {
    margin-top: auto;
}

.contributor-list+.ei-heading {
    margin-top: 60px;
}

.tbc-region-map {
    position: relative;
    padding-bottom: 62.1%;
    min-height: 428px;
    background: #f5f5f5;
    border: 2px solid #e4e4e4;
    border-radius: 10px;
    overflow: hidden;
}

.tbc-region-map>img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.media-item {
    width: 16.66%;
    padding: 0 6px;
    margin-bottom: 12px;
}

.media-list {
    width: calc(100% + 12px);
    margin-left: -6px;
    margin-top: 30px;
    margin-bottom: -12px;
}

.media-box {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
}

.media-box-inner>* {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.media-box-inner {
    position: relative;
    padding-bottom: 100%;
}

.tbc-zoo-map {
    position: relative;
    padding-bottom: 62.1%;
    min-height: 428px;
    background: #f5f5f5;
    border: 2px solid #e4e4e4;
    border-radius: 10px;
    overflow: hidden;
}

.tbc-zoo-map>img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tbc-zoo-details {
    margin-top: 60px;
}

.tbc-zoo-details-img {
    width: 30.3%;
    position: relative;
    padding-bottom: 15.9%;
}

.tbc-zoo-details-img img {
    border-radius: 10px;
}

.tbc-zoo-details-text {
    width: 42%;
    padding-left: 4.1%;
    font-size: 14px;
    line-height: 1.4;
}

.tbc-zoo-details-img>img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.tbc-zoo-details-text>h4 {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 14px;
}

.tbc-zoo-details-text>p {
    letter-spacing: 0.015em;
    color: #333333;
}

.news-box-footer {
    font-size: 12px;
    letter-spacing: 0.005em;
    color: #333333;
    opacity: 0.6;
    margin-top: 29px;
}

.tbc-zoo-details+.ei-heading {
    margin-top: 60px;
}

.ldc-image {
    width: 36.8%;
}

.species-details-img {
    width: calc(100% + 60px);
    margin-left: -60px;
    border-radius: 20px;
    overflow: hidden;
}

.ldc-text {
    width: 56.82%;
}

.species-details-img>img {
    width: inherit;
}

.text-title {
    font-weight: 500;
}

.text-red {
    color: #ff4242 !important;
}

.ldc-text-main h1 {
    font-size: 40px;
    line-height: 1.5;
    color: #1e1e1e;
    letter-spacing: -0.01rem;
    position: relative;
    display: flex;
    margin-bottom: 0;
    width: fit-content;
    padding-right: 78px;
}

.ldc-text-main {
    margin-top: 10px;
    position: relative;
    padding-right: 100px;
}

.ldc-text-main-icon {
    position: absolute;
    right: 0;
    /* top: -22px; */
}

.ldc-text-icon {
    position: absolute;
    right: 0;
    top: 20px;
}

.ldc-text-icon a+a {
    margin-left: 12px;
}

.ldc-text-icon>a {
    display: block;
    width: 24px;
    height: 24px;
}

.ldc-text-icon>a>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.ldc-text-head>small {
    color: #1e1e1e;
    margin-top: 4px;
    display: block;
}

.ldc-text-head {
    padding-bottom: 16px;
    border-bottom: 1px solid var(--black);
}

.ltb-details-list {
    margin-top: 41px;
    margin-bottom: -50px;
    width: calc(100% + 40px);
    margin-left: -20px;
}

.ltb-details-item {
    width: 50%;
    margin-bottom: 50px;
    padding: 0 20px;
}

.ltb-details-box>p {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.5;
}

.ltb-details-box h4 {
    font-weight: 500;
    letter-spacing: -0.01rem;
    margin-top: 10px;
    color: #1e1e1e;
    margin-bottom: 0;
}

.ltb-details-box {
    height: 100%;
    padding-right: 20px;
}

.tbc-body-inner .news-item .news-box:after {
    bottom: -30px;
}

.tbc-body-inner .news-item {
    margin-bottom: 60px;
}


/* ------------------------- detail page css end ---------------------- */

.no-records-container {
    height: 100vh;
    max-height: 500px;
    padding: 20px;
}

.no-records-box h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.5;
    color: #1e1e1e;
    margin-bottom: 0;
    margin-top: 47px;
}

.no-records-box {
    max-width: 183px;
}

.filter-sidebar .collapse:not(.show) {
    display: none !important;
}

@media (min-width: 991.98px) {
    .filter-sidebar .tab-content>.tab-pane {
        display: block;
        opacity: 1;
    }
}

@media (max-width: 991.98px) {
    .filter-sidebar .collapse:not(.show) {
        display: block !important;
    }
}


/*========================== Organisations Start ============================== */

.org-hero-bg {
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    object-fit: cover;
}

.org-hero-bg>img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.org-hero-bg::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 33.12%, rgba(0, 0, 0, 0.6) 112.62%);
}

.od-logo {
    width: 180px;
}

.od-content {
    width: calc(100% - 180px);
    padding-left: 40px;
    padding-top: 3px;
}

.od-logo-inner {
    box-shadow: -20px 4px 90px -2px rgba(0, 0, 0, 0.15);
    position: relative;
    width: 100%;
    height: 180px;
    border-radius: 20px;
}

.org-detail-main {
    z-index: 999;
    position: relative;
}

.od-logo-inner .edit-btn {
    top: -15px;
    right: -16px;
}

.od-logo-inner>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.od-content-heading h1 {
    font-size: 24px;
    font-weight: 600;
    padding-right: 79px;
    position: relative;
    margin-bottom: 0;
    padding-bottom: 25px;
}

.text-green {
    color: #47ad1d;
}

.od-content-heading {
    margin-top: 10px;
}

.od-content-icon {
    position: absolute;
    right: 0;
    top: -5px;
}

.od-content-icon a+a {
    margin-left: 12px;
}

.od-content-heading h1::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 79px;
    height: 3px;
    background: var(--green-color);
}

.page-detail-head-list {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-top: 38px;
    color: #1e1e1e;
}

.org-detail-container {
    padding: 30px 0 108px 0;
}

.page-detail-head-item {
    width: 33.33%;
    padding: 0 15px;
    margin-bottom: 10px;
}

.page-detail-head-box p {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5;
    color: #1e1e1e;
    margin-top: 8px;
    font-family: "Poppins";
}

.page-detail-head-box {
    border-right: 1px solid rgb(0 0 0 / 50%);
    padding-right: 10px;
}

.page-detail-head-item:nth-child(3n) .page-detail-head-box {
    border-right: 0;
}

.org-details-main {
    font-size: 18px;
    padding-right: 0;
}


/* .tbc-details-right {
                                                      align-self: center;
                                                  } */

.org-details-main .tbc-details-left {
    padding-bottom: 35.2%;
    width: 45.5%;
}

.org-details-main .tbc-details-right {
    width: 42%;
}

.org-hero-container {
    position: relative;
    height: 80px;
    display: flex;
    align-items: end;
}

.org-hero-bg>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.org-hero-container .container {
    position: relative;
    z-index: 99;
}

.hero-social-media-list {
    justify-content: center;
    padding-top: 20px;
}

.hsm-item+.hsm-item {
    margin-left: 4px;
}

.hsm-box {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(12px);
    border-radius: 4px;
    padding: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tbc-our-team-item {
    width: 16.6666666%;
    padding: 0 9px;
    margin-bottom: 28px;
}

.tbc-our-team-list {
    width: calc(100% + 18px);
    margin-left: -9px;
    margin-bottom: -28px;
}

.tbc-our-team-image {
    position: relative;
    padding-bottom: 100%;
    border-radius: 6px;
    overflow: hidden;
}

.tbc-our-team-image>img,
.tbc-our-team-image .education-media{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.tbc-our-team-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: #1e1e1e;
    font-family: "Poppins";
    margin-top: 12px;
}

.tbc-our-team-text p {
    margin-bottom: 0;
}

.tbc-our-team-text>small {
    display: block;
    font-family: "IBM Plex Sans";
    font-weight: 400;
    color: #1e1e1e;
    margin-top: 4px;
}

.partner-item {
    width: 10%;
    padding: 0 5px;
    margin-bottom: 10px;
}

.partner-list {
    width: calc(100% + 10px);
    margin-left: -5px;
    margin-bottom: -10px;
}

.partner-box {
    position: relative;
    border: 1px solid #e4e4e4;
    border-radius: 6px;
    padding-bottom: 80%;
    background: var(--bs-white);
}

.partner-box>img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 6px;
}

.blog-list-4 li {
    width: 25%;
}

.report-box h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.4;
    color: #654315;
    margin-bottom: 0;
}

.report-box h4 small {
    font-family: "IBM Plex Sans";
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    color: #000000;
    display: inline-block;
    margin-top: 0;
    margin-left: 11px;
}

.report-box {
    position: relative;
    padding-right: 50px;
}

.report-box-download {
    display: block;
    position: absolute;
    right: 80%;
    top: 3px;
}

.report-item+.report-item {
    margin-top: 30px;
}

.bg-green {
    background-color: var(--green-color);
}

.country-cta-container {
    padding: 58px 0px;
}

.country-cta-image {
    width: 102px;
    height: 102px;
    margin: 0 auto 21px;
    border-radius: 50%;
    overflow: hidden;
}

.country-cta-box {
    font-family: "Poppins";
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    mix-blend-mode: normal;
}

.country-cta-image>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/*========================== Organisations End ============================== */


/*========================== Region Start ============================== */

.map-hero {
    position: relative;
    height: 700px;
}

.map-hero-image {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.map-hero-image>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.rhc-box-left>h1 {
    font-weight: 700;
    font-size: 40px;
    color: #1e1e1e;
    mix-blend-mode: normal;
    margin-top: 9px;
    margin-bottom: 0;
}

.rhc-box-icon {
    margin-top: 8px;
}

.rhc-box-icon a+a {
    margin-left: 13px;
}

.rhc-statics-box {
    max-width: 270px;
    width: 100%;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), #f5f5f5;
    border-radius: 6px;
    color: #1e1e1e;
}

.rhc-box-left {
    width: calc(100% - 555px);
    padding-right: 50px;
}

.rhc-box-right {
    width: 555px;
}

.rhc-statics-box+.rhc-statics-box {
    margin-left: 15px;
}

.rhc-box-inner {
    justify-content: end;
}

.rhc-statics-box {
    font-size: 18px;
    line-height: 1.5;
    padding: 15px 10px 15px 23px;
}

.rhc-statics-box>h6 {
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 0;
}

.rhc-statics-box>p {
    margin-bottom: 15px;
}

.region-details-main {
    padding-right: 7.65%;
}

.region-details-main .tbc-details-left {
    width: 34.1%;
    padding-bottom: 44%;
}

.ei-heading h5 {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 500;
}

.national-sanctuaries-container {
    padding: 80px 0;
}

.national-sanctuaries-container .ei-grid-item {
    width: 33.33%;
    padding: 0 10px;
    margin-bottom: 20px;
}

.national-sanctuaries-container .ei-grid-item .ei-grid-box-image {
    padding-bottom: 60.3%;
}

.national-sanctuaries-container .ei-grid-list {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-bottom: -20px;
}

.tbc-body-inner .region-species-list {
    margin-bottom: -10px;
    width: calc(100% + 10px);
    margin-left: -5px;
}

.tbc-body-inner .region-species-list .ei-grid-item {
    padding: 0 5px;
    margin-bottom: 10px;
}

.region-details-main .tbc-details-right {
    width: 50%;
}


/*========================== Region End ============================== */


/*========================== Environmentalist End ============================== */

.environmentalist-details-img {
    width: calc(100% + 60px);
    border-radius: 20px;
    overflow: hidden;
}

.environmentalist-details-img>img {
    width: inherit;
}

.ltb-icon-item {
    font-size: 22px;
}

.ltb-icon-box {
    width: 24px;
    height: 24px;
    display: block;
    display: flex;
    justify-content: space-around;
}

.ltb-icon-item+.ltb-icon-item {
    margin-left: 20px;
}

.ltb-icon-list {
    margin-top: 20px;
}

.environmentalist-details-main {
    padding-right: 7.65%;
}

.environmentalist-details-main .tbc-details-left {
    padding-bottom: 44%;
    width: 34.1%;
}

.species-details-item {
    width: 50%;
    padding: 0 20px;
}

.sdp-img {
    width: 40%;
    border-radius: 10px;
    overflow: hidden;
}

.sdp-text {
    width: 60%;
    padding-left: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    align-self: center;
}

.sdp-text>h6 {
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    margin-bottom: 20px;
}

.species-details-list {
    width: calc(100% + 40px);
    margin-left: -20px;
}

.map-item {
    width: 20%;
    padding: 0px 20px;
}

.map-box {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.3;
    padding: 15px 35px;
    background: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
}

.map-box-img {
    margin-bottom: 19px;
}

.map-list {
    width: calc(100% + 12px);
    margin-left: -20px;
}


/*========================== Environmentalist End ============================== */


/*========================== Wet Market  Start ============================== */

.wet-market-details-main {
    padding-right: 0;
}

.wet-market-details-main .tbc-details-right {
    padding-top: 10px;
    font-size: 18px;
    width: 46%;
}

.wet-market-details-main .tbc-details-left {
    width: 48.9%;
    padding-bottom: 46.5%;
}

.wet-market-details-main+.tbc-details-bg {
    width: 43.5%;
}

.btn-transparent {
    color: var(--green-color);
    border-color: var(--green-color);
}


/*========================== Wet Market  End ============================== */

.spd-content {
    width: 52%;
    margin-right: 4.8%;
}

.spd-image {
    width: 43.2%;
    height: fit-content;
    position: sticky;
    top: 90px;
}

.spd-title {
    font-family: "Poppins";
    font-weight: 500;
    letter-spacing: 0.005em;
}

*+.spd-head {
    margin-top: 30px;
}

.spd-title+.ei-heading {
    margin-top: 10px;
}

.publisher-details {
    display: flex;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.005em;
    color: #333333;
    opacity: 0.6;
    align-self: center;
}

.pd-box+.pd-box {
    margin-left: 24px;
}

.pd-box:not(:last-child):after {
    content: "";
    position: absolute;
    right: -13px;
    height: 11px;
    width: 1px;
    background: #333;
    opacity: 0.6;
    top: 3px;
}

.pd-box {
    position: relative;
}

.spd-separator {
    margin: 14px 0px 20px;
    height: 1px;
    background: #e4e4e4;
}

.spd-body {
    font-size: 14px;
    line-height: 1.6;
    color: #000000;
}

.spd-body p:not(:last-child) {
    margin-bottom: 21px;
}

.publisher-footer .btn {
    padding: 3px 10px;
}

.publisher-footer {
    margin-top: 11px;
}

.publisher-details+.btn-dark-green {
    margin-left: 20px;
}

.spd-head {
    padding-right: 110px;
    position: relative;
}

.spd-text-icon {
    position: absolute;
    right: -1px;
    bottom: -1px;
}

.spd-head .ei-heading h1 {
    font-size: 24px;
    font-weight: 500;
}

.spd-text-icon a img {
    width: 22px;
    height: 22px;
}

.spd-text-icon a+a {
    margin-left: 13px;
}

.spd-image-inner>img {
    border-radius: 11px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.spd-image-inner {
    position: relative;
    padding-bottom: 94%;
}


/* ================= edit Start ================= */

.btn-xs {
    min-width: 100px;
    padding: 2px 30px;
}

.edit-btn {
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
    z-index: 1;
}

.species-detailsedit-btn {
    right: 2%;
    top: 4%;
}
.region-detail-edit-img{
    top: 2%;
    left: 100%;
}

.edit-btn img {
    width: 20px;
    height: 20px;
    object-fit: cover;
}

.tbc-details-left .edit-btn {
    top: 4%;
    right: 4%;
}

.tbc-grid-box [contenteditable="true"] {
    flex-grow: 1;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    padding: 6px 0;
    margin-top: 9px;
    min-height: 110px;
}

.custom-dropdown {
    position: absolute;
    top: 14px;
    right: 16px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    border-radius: 4px;
    opacity: 0;
    transition: 0.3s;
    z-index: 9;
    cursor: pointer;
}

.dropdown-icon img {
    width: 24px;
    height: 24px;
}

.custom-dropdown .dropdown-menu {
    background: #ffffff;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
    border: none;
    transition: none !important;
    min-width: auto;
    width: 107px;
    right: -3px !important;
    padding: 6px 0;
    top: 4px !important;
}

.custom-dropdown .dropdown-menu .dropdown-item {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #000000;
    padding: 5px 12px;
}

*:hover>.custom-dropdown {
    opacity: 1;
}

.custom-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #f5f5f5;
}

.news-box .custom-dropdown {
    bottom: 0;
    top: auto;
}

.change-hero {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(12px);
    border-radius: 4px;
    font-weight: 500;
    color: #ffffff;
    padding: 7px 15px 7px 10px;
    margin-bottom: 21px;
}

.change-hero>img {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    filter: brightness(0) invert(1);
}

.news-box.more-top .custom-dropdown {
    top: -10px;
    bottom: auto;
    right: 0px;
}

.news-box.more-top:hover .nb-head-date {
    display: none;
}

.environmentalist-edit-btn {
    top: 5.8%;
    right: 8%;
}

.card-content-box .custom-dropdown {
    right: 0;
    top: -2px;
}

.spd-image-inner .edit-btn {
    top: 20px;
    right: 20px;
}


/* ================= edit end ================= */


/* ================= Footer Start ================= */

#footer {
    background: var(--bs-white);
    padding: 50px 0 30px;
    border-top: 1px solid #e4e4e4;
}

.fc-left {
    width: 33.2%;
    font-size: 14px;
    line-height: 1.6;
}

.fc-right {
    width: 66.8%;
}

.footer-menu-content h6 {
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: black;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 600;
    margin-bottom: 0;
}

.fc-right1 {
    width: 28.35%;
}

.fc-right2 {
    width: 40.4%;
    margin-top: 3%;
}

.fc-right3 {
    width: 30.25%;
}

.footer-menu-list {
    margin-top: 23px;
}

.footer-menu-box {
    font-size: 14px;
    line-height: 18px;
    display: block;
}

.footer-menu-item+.footer-menu-item {
    margin-top: 22px;
}

.fc-left>p {
    margin-top: 30px;
    margin-bottom: 0;
}

.social-media-item a {
    width: 30px;
    height: 30px;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.social-media-item+.social-media-item {
    margin-left: 24px;
}

.social-media-list {
    margin-top: 38px;
}


/* footer lower */

.footer-lower-logo {
    width: 139px;
}

.footer-lower {
    padding: 25px 0 25px 20px;
    border: 1px solid #e4e4e4;
    border-right: 0;
    border-left: 0;
    margin-top: 39px;
}

.footer-lower-menu {
    width: calc(100% - 139px);
    align-self: center;
    margin-bottom: -20px;
}

.footer-lower-menu-box {
    font-size: 14px;
    line-height: 1.286;
    font-weight: 400;
    display: block;
}

.footer-lower-menu-item+.footer-lower-menu-item {
    margin-left: 30px;
}

.footer-lower-menu-item {
    margin-bottom: 20px;
}


/* footer lower */

.footer-credit p {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 1.5;
    color: black;
}

.footer-credit {
    padding: 20px 0 0;
}

.social-media-box i {
    font-size: 16px;
    color: rgb(0 0 0 / 70%);
}

.social-media-item a:hover i {
    color: var(--bs-white);
}

.social-media-item a:hover {
    background: var(--green-color);
}

.social-media-item a:hover img {
    filter: brightness(1) invert(1);
}


/* ================= Footer End ================= */


/* ================= Content Start ================= */

.content-container {
    padding: 60px 0;
}


/* ================= Content End ================= */


/* ==========================================
! Large devices (desktops, less than 1200px)
=========================================== */

@media (max-width: 1199.98px) {
    body {
        padding-top: 70px;
    }
    .navbar {
        padding: 0;
        width: 100%;
        height: 70px;
    }
    .navbar-toggler {
        width: 64px;
        height: 40px;
        height: 20px;
        border-radius: 10;
        cursor: pointer;
        text-decoration: none;
        padding: 0;
        border: 0;
        background: transparent;
        margin: 0;
        position: absolute;
        right: 0;
        top: 50%;
        z-index: 999;
        transform: translateY(-50%);
    }
    .navbar-default .navbar-toggler:hover,
    .navbar-default .navbar-toggler:focus {
        background: transparent;
    }
    .navbar-toggler:not([class="collapsed"]),
    .navbar-toggler:not([class="collapsed"]):focus,
    .navbar-toggler:not([class="collapsed"]):hover {
        background: transparent;
    }
    .navbar-toggler.collapsed,
    .navbar-toggler.collapsed:focus,
    .navbar-toggler.collapsed:hover {
        background: transparent;
    }
    .home .navbar-toggler.collapsed,
    .home .navbar-toggler.collapsed:focus,
    .home .navbar-toggler.collapsed:hover {
        background: transparent;
    }
    .navbar-toggler .navbar-toggler-icon {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 2px;
        color: var(--black);
        text-indent: -55px;
        margin-top: 0;
        background: transparent !important;
        transition: all 0.2s ease-out;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -ms-transition: all 0.2s ease-out;
        vertical-align: middle;
    }
    .navbar-toggler .navbar-toggler-icon:before,
    .navbar-toggler .navbar-toggler-icon:after {
        content: "";
        width: 20px;
        height: 2px;
        background: var(--black);
        position: absolute;
        left: 0;
        transition: all 0.2s ease-out;
    }
    .navbar-toggler.collapsed .navbar-toggler-icon {
        background: var(--black) !important;
    }
    .navbar-toggler.collapsed .navbar-toggler-icon:before,
    .navbar-toggler.collapsed .navbar-toggler-icon:after {
        background: var(--black);
    }
    .navbar-toggler.collapsed .navbar-toggler-icon:before {
        top: -6px;
        -webkit-transform: rotateZ(0deg);
        -moz-transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -o-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
    }
    .navbar-toggler .navbar-toggler-icon:before {
        top: 0;
        -webkit-transform: rotateZ(45deg);
        -moz-transform: rotateZ(45deg);
        -ms-transform: rotateZ(45deg);
        -o-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
    }
    .navbar-toggler.collapsed .navbar-toggler-icon:after {
        bottom: -6px;
        -webkit-transform: rotateZ(0deg);
        -moz-transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -o-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
    }
    .navbar-toggler .navbar-toggler-icon:after {
        bottom: 0;
        -webkit-transform: rotateZ(-45deg);
        -moz-transform: rotateZ(-45deg);
        -ms-transform: rotateZ(-45deg);
        -o-transform: rotateZ(-45deg);
        transform: rotateZ(-45deg);
    }
    .header-right {
        position: relative;
        z-index: 999;
        margin-right: 50px;
    }
    .navbar-collapse,
    .navbar-collapse.collapsing {
        position: fixed;
        top: 70px;
        left: 0;
        background: var(--bs-white);
        padding: 0;
        z-index: 99;
        margin: 0;
        border: 0;
        overflow-y: auto;
        bottom: 0;
        width: 100%;
        height: 0;
        display: block !important;
        transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
    }
    .navbar .collapse.show {
        height: calc(100vh - 70px);
    }
    .navbar-inside {
        height: calc(100vh - 70px);
        padding: 0 15px;
        display: flex;
        flex-flow: column;
    }
    .navbar-expand-xl .navbar-nav .nav-item {
        padding: 0;
        margin: 0;
        border-bottom: solid 1px #f4f4f4;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        font-size: 16px;
        line-height: 30px;
        padding: 15px 0;
        position: relative;
        color: #333;
    }
    .navbar-nav.sm-collapsible .sub-arrow {
        border: 0;
        margin: 0;
        width: auto;
        font-size: 30px;
        line-height: 60px;
        top: 0;
        height: 60px;
        color: #f00;
        width: 40px;
    }
    .dropdown-menu {
        background: transparent;
        border: 0;
        padding: 0;
        margin: 0 0 20px 0;
    }
    .dropdown-item {
        padding: 7px 20px 7px 40px;
    }
    .dropdown-item:focus,
    .dropdown-item:hover {
        color: #f00;
        background: transparent;
    }
    .content-container {
        padding: 50px 0;
    }
    .ei-category-right {
        overflow: hidden;
    }
    .hero-container-text h1 {
        font-size: 30px;
    }
    .scroll-down-btn {
        left: 20px;
    }
    .ei-grid-item {
        width: 33.33% !important;
    }
    .news-item::after {
        width: 100%;
        height: 1px;
        background: #e4e4e4;
        bottom: -25px;
        top: auto;
        left: 0;
        right: 0;
    }
    .news-item {
        width: 100%;
    }
    .listing-content-box .news-item::after {
        display: none;
    }
    .ei-statistics-item .ei-statistics-box {
        border-bottom: 1px solid rgba(255, 255, 255, 0.6) !important;
    }
    .ei-statistics-item {
        width: 50%;
    }
    .ei-statistics-item:nth-last-child(-n+2):nth-child(2n+1) .ei-statistics-box,
    .ei-statistics-item:nth-last-child(-n+2):nth-child(2n+1)~.ei-statistics-item .ei-statistics-box {
        border-bottom: none !important;
    }
    .footer-lower {
        padding-left: 0;
        margin-top: 0;
    }
    .footer-lower-logo {
        width: 100%;
        text-align: center;
    }
    .footer-lower-menu {
        width: 100%;
        justify-content: center !important;
        flex-wrap: wrap;
        margin-top: 40px;
    }
    .footer-lower-logo>a {
        width: 139px;
    }
    .blog-list-slider .slick-track {
        display: flex;
    }
    .blog-list-slider .slick-slide {
        height: auto;
    }
    .blog-list-slider .slick-list {
        padding-bottom: 0;
        padding-right: 20%;
    }
    .news-list {
        width: 100%;
        margin-left: 0;
    }
    .news-item {
        padding: 0;
    }
    .listing-head-btn-group {
        margin: 20px 0 12px;
    }
    .listing-content-head {
        flex-flow: column;
    }
    .ldc-text-main h1 {
        font-size: 30px;
    }
    .ldc-text-icon {
        top: 10px;
    }
    .ldc-text-main-icon {
        width: 70px;
        height: 70px;
        top: -14px;
    }
    .ldc-text-main {
        padding-right: 70px;
    }
    .ltb-details-box>p {
        font-size: 14px;
    }
    .ltb-details-box h4 {
        font-size: 18px;
    }
    .ltb-details-item {
        margin-bottom: 34px;
    }
    .ltb-details-list {
        margin-bottom: -34px;
    }
    .tbc-details {
        padding: 0;
    }
    .tbc-details-left {
        width: 50%;
    }
    .tbc-organization-item {
        width: 25%;
    }
    .doner-item {
        width: 33.33%;
    }
    .media-item {
        width: 25%;
    }
    .tbc-body .news-item::after {
        display: none;
    }
    .blog-list-4 li {
        width: 33.33%;
    }
    .tbc-our-team-item {
        width: 25%;
    }
    .ei-sort-filter {
        position: absolute;
        right: 0;
        font-size: 14px;
        top: 5px;
        font-weight: 500;
    }
    .map-item {
        width: 33.33%;
    }
    .tbc-body-inner .nb-body {
        margin-top: 0;
    }
    .tbc-body-inner .news-box {
        padding-bottom: 0;
    }
    .news-box .custom-dropdown {
        bottom: 0;
    }
    .tbc-head {
        top: 70px;
    }
    .nav-pill-mob-slider {
        display: block !important;
        /* Ensure the mobile slider is visible */
    }
    .nav.nav-pills {
        display: none !important;
        /* Hide the desktop navigation pills */
    }
    .nav.nav-pills-2 {
        display: flex !important;
        /* Hide the desktop navigation pills */
    }
    .nav-pill-mob-slider .slick-prev:after {
        background: none;
        left: 0px;
    }
    .nav-pill-mob-slider .slick-next:after {
        background: none;
        right: 0px;
    }
    .nav-pill-mob-slider {
        padding-bottom: 0;
        color: #fff;
    }
    .nav-pill-mob-box {
        font-weight: 500;
        font-size: 14px;
        padding: 0 15px;
        text-align: center;
    }
    .nav-pill-mob-slider .slick-prev:before,
    .nav-pill-mob-slider .slick-next:before {
        font-size: 16px;
    }
    .nav-pill-mob-slider .slick-prev {
        width: 20px;
        height: 20px;
        left: -8px;
    }
    .nav-pill-mob-slider .slick-next {
        width: 20px;
        height: 20px;
        right: -8px;
    }
    .nav-pill-mob-slider .slick-next:after,
    .nav-pill-mob-slider .slick-prev:after {
        content: "";
        position: absolute;
        height: 60px;
        width: 62px;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
    }
    .nav-pill-mob-slider .slick-next:after {
        right: -10px;
    }
    .nav-pill-mob-slider .slick-prev:after {
        left: -10px;
    }
    .nsv-pill-mob-inner {
        width: fit-content;
        margin: 0 auto;
        position: relative;
        padding: 18px 0;
    }
    .nsv-pill-mob-inner:after {
        position: absolute;
        bottom: 0;
        content: "";
        left: 0;
        width: 0;
        background: var(--bs-white);
        border-radius: 4px 4px 0px 0px;
        transition: 0.3s;
        height: 4px;
    }
    .nsv-pill-mob-inner:hover:after,
    .slick-current .nsv-pill-mob-inner:after {
        width: 100%;
    }
}


/*==========================================
! Medium devices (tablets, less than 992px)
========================================== */

@media (max-width: 991.98px) {
    .fc-left {
        width: calc(100%);
        text-align: center;
        padding-bottom: 43px;
        border-bottom: 1px solid #e4e4e4;
        padding: 0 20px 43px;
    }
    .social-media-list {
        justify-content: center;
        margin-top: 24px;
    }
    .footer-container {
        width: calc(100% + 40px);
        margin-left: -20px;
    }
    .fc-right {
        width: 100%;
    }
    .footer-menu-content {
        padding: 25px 20px 25px;
    }
    .ei-category-right {
        width: 100%;
        padding: 0;
    }
    .ei-category-accordion {
        padding: 0;
        height: calc(100% - 120px);
        background: #f5f5f5;
        top: 0;
        border-radius: 0;
    }
    .ei-category-left {
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 999999;
        position: fixed;
        background: var(--bs-white);
        display: block;
        right: 100%;
        transition: all 0.5s;
    }
    .browse-category-mob .btn {
        border-radius: 0;
        padding: 17px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
    body.filter-open {
        overflow: hidden;
    }
    .browse-category-mob {
        position: sticky;
        bottom: 0;
        margin-top: 0px;
    }
    .ei-category-nav {
        flex-flow: column;
        width: 176px;
        border: none;
        padding: 22px 20px;
        height: 100%;
        background: var(--bs-white);
        overflow-y: auto;
    }
    .ei-category-nav .nav-link {
        padding: 0 !important;
        text-align: start;
        width: 100%;
        font-family: "Poppins";
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: var(--black) !important;
        border: none;
        display: block;
    }
    .ei-category-nav .nav-item+.nav-item {
        margin-top: 26px;
    }
    .ei-category-tab .accordion-item {
        background: transparent;
        border-bottom: 0 !important;
    }
    .ei-category-accordion>div {
        height: 100%;
    }
    .ei-category-tab {
        width: calc(100% - 176px);
        height: 100%;
        padding: 14px 20px;
        overflow-y: auto;
    }
    .ei-category-list {
        margin-top: -1px;
    }
    .ei-category-item+.ei-category-item {
        margin-top: 12px;
    }
    .filter-header-mobile h6 {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
    .filter-header-mobile {
        position: relative;
        padding: 18px 50px 18px 20px;
        background: var(--bs-white);
        box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);
        z-index: 1;
    }
    .filter-header-mobile .close-btn {
        position: absolute;
        right: 18px;
        top: 16px;
        width: 28px;
        height: 28px;
    }
    .close-btn>img {
        width: 100%;
        height: 100%;
    }
    .filter-footer-mob-btn .btn {
        padding: 17px;
        border-radius: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
    body.filter-open .ei-category-left {
        right: 0;
    }
    .listing-content-box {
        width: 100%;
        padding: 0;
    }
    .filter-sidebar {
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 999999;
        position: fixed;
        background: var(--bs-white);
        display: block;
        transition: all 0.5s;
        right: 100%;
        padding: 0px;
    }
    .filter-sidebar-head,
    .filter-sidebar-tag {
        display: none !important;
    }
    .filter-nav {
        flex-flow: column;
        width: 176px;
        border: none;
        padding: 22px 20px;
        height: 100%;
        background: var(--bs-white);
        overflow-y: auto;
    }
    .filter-nav .nav-link {
        padding: 0 !important;
        text-align: start;
        width: 100%;
        font-family: "Poppins";
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: var(--black) !important;
        border: none;
        display: block;
    }
    .filter-nav .nav-item+.nav-item {
        margin-top: 26px;
    }
    .filter-tab .accordion-item {
        background: transparent;
        border-bottom: 0 !important;
    }
    .filter-accordion>div {
        height: 100%;
    }
    .filter-tab {
        width: calc(100% - 176px);
        height: 100%;
        padding: 14px 20px;
        overflow-y: auto;
    }
    .filter-sidebar {
        background: #f5f5f5;
        border-radius: 0;
        max-height: 100%;
    }
    .filter-sidebar-accordion {
        height: calc(100% - 120px);
    }
    .filter-sidebar-accordion>div {
        height: 100%;
    }
    .filter-tab {
        height: 100%;
        padding: 2px 20px;
    }
    .filter-tab>.tab-pane {
        border-top: 0;
    }
    .filter-content-list {
        margin-top: 13px;
    }
    .filter-content-item+.filter-content-item {
        margin-top: 6px;
    }
    .filter-sort-btn {
        position: sticky;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
        margin-top: 30px;
    }
    .btn-dark-green>.btn {
        border-radius: 0 !important;
    }
    .filter-sort-btn>.btn {
        border-radius: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        padding: 17px;
    }
    .filter-open .filter-sidebar {
        right: 0;
    }
    .filter-sidebar>.filter-footer-mob-btn .btn-grey {
        width: 176px;
        background: #f5f5f5;
    }
    .filter-sidebar>.filter-footer-mob-btn .btn-grey+.btn {
        margin-left: 0;
        width: calc(100% - 176px);
    }
    .listing-content-heading-mob {
        position: relative;
        margin-bottom: 30px;
        padding-right: 80px;
    }
    .listing-content-heading-mob .ei-view-filter {
        top: auto;
        bottom: 0px;
    }
    .tbc-details-left {
        width: 100% !important;
        order: -1;
        min-height: 240px !important;
    }
    .tbc-details-right {
        width: 100% !important;
        font-size: 14px;
        padding-top: 30px;
        line-height: 1.58;
    }
    .tbc-details-bg {
        display: none;
    }
    .tbc-details-right p:not(:last-child) {
        margin-bottom: 21px;
    }
    .tbc-body-inner {
        padding: 60px 0px;
    }
    .doner-item,
    .contributor-item {
        width: 50%;
    }
    .blog-list-4 li {
        width: 50%;
    }
    .partner-item {
        width: 20%;
    }
    .national-sanctuaries-container {
        padding: 40px 0;
    }
    .environmentalist-details-main .tbc-details-left {
        max-width: 388px !important;
        height: 482px;
    }
    .spd-content {
        width: 100%;
        margin: 0;
    }
    .spd-image-inner+.spd-body {
        margin-top: 30px;
    }
    .spd-image-inner {
        height: 500px;
        padding-bottom: 0;
    }
    .tab-content-slider {
        padding-bottom: 0;
    }
    /* .tab-content>.tab-pane{display:block!important;opacity:1;} */
    .nav-pill-mob-slider {
        padding-bottom: 0;
        color: #fff;
    }
    .nav-pill-mob-box {
        font-weight: 500;
        font-size: 14px;
        padding: 0 15px;
        text-align: center;
    }
    .nav-pill-mob-slider .slick-prev:before,
    .nav-pill-mob-slider .slick-next:before {
        font-size: 16px;
    }
    .nav-pill-mob-slider .slick-prev {
        width: 20px;
        height: 20px;
        left: -8px;
    }
    .nav-pill-mob-slider .slick-next {
        width: 20px;
        height: 20px;
        right: -8px;
    }
    .nav-pill-mob-slider .slick-next:after,
    .nav-pill-mob-slider .slick-prev:after {
        content: "";
        position: absolute;
        height: 60px;
        width: 62px;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
    }
    .nav-pill-mob-slider .slick-next:after {
        background: linear-gradient(270deg, #227300 67.86%, rgba(34, 115, 0, 0) 100%);
        right: -10px;
    }
    .nav-pill-mob-slider .slick-prev:after {
        background: linear-gradient(90deg, #227300 67.86%, rgba(34, 115, 0, 0) 100%);
        left: -10px;
    }
    .nsv-pill-mob-inner {
        width: fit-content;
        margin: 0 auto;
        position: relative;
        padding: 18px 0;
    }
    .nsv-pill-mob-inner:after {
        position: absolute;
        bottom: 0;
        content: "";
        left: 0;
        width: 0;
        background: var(--bs-white);
        border-radius: 4px 4px 0px 0px;
        transition: 0.3s;
        height: 4px;
    }
    .nsv-pill-mob-inner:hover:after,
    .slick-current .nsv-pill-mob-inner:after {
        width: 100%;
    }
    .rhc-box-left>h1 {
        font-size: 30px;
    }
    .rhc-statics-box>h6 {
        font-size: 24px;
    }
    .rhc-statics-box>p {
        margin-bottom: 10px;
    }
    .rhc-statics-box {
        font-size: 14px;
    }
    .rhc-statics-box {
        min-width: 100%;
    }
    .rhc-statics-box+.rhc-statics-box {
        margin-left: 0;
        margin-top: 10px;
    }
    .rhc-box-right {
        width: 300px;
    }
    .rhc-box-left {
        width: calc(100% - 300px);
    }
}


/* =================================================
! Small devices (landscape phones, less than 768px)
================================================= */

@media (max-width: 767.98px) {
    body {
        padding-top: 52px;
    }
    .navbar {
        height: 52px;
    }
    .navbar-collapse,
    .navbar-collapse.collapsing {
        top: 52px;
    }
    .navbar .collapse.show {
        height: calc(100vh - 52px);
    }
    .navbar-inside {
        height: calc(100vh - 60px);
    }
    .content-container {
        padding: 40px 0;
    }
    .navbar-brand {
        width: 152px;
    }
    .hero-container-text {
        width: 100%;
        padding-bottom: 0;
        position: relative;
        z-index: 96;
    }
    .hero-container-image {
        width: 100%;
        margin-top: -61px;
    }
    .hero-container-text h1 {
        font-size: 24px;
        max-width: 270px;
    }
    .hci-inner {
        max-width: 570px;
        width: calc(100% + 181px);
        margin-right: -181px;
        margin-left: auto;
    }
    .hero-container {
        align-items: start;
    }
    .hero-container-content:after {
        content: "";
        position: absolute;
        left: -20px;
        right: 0;
        top: 0;
        bottom: 0;
        width: calc(100% + 220px);
        height: 90%;
        background: radial-gradient( 76.75% 91.52% at 8.18% 10.38%, var(--bs-white) 20.49%, rgba(255, 255, 255, 0.767442) 43.4%, rgba(255, 255, 255, 0) 74.53%);
        z-index: 0;
    }
    .dog-paw {
        z-index: 9;
        top: -13%;
        left: 54%;
        width: 128px;
    }
    .bird-paw {
        bottom: -6%;
    }
    .scroll-down-btn {
        width: 100%;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        justify-content: center;
    }
    .filter-footer-mob-btn .btn {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        border-radius: 0;
        padding: 17px;
    }
    .ei-grid-item {
        width: 50% !important;
    }
    .ei-statistics-item {
        width: 100%;
        margin-bottom: 16px;
    }
    .nb-body p {
        -webkit-line-clamp: 3;
    }
    .ei-statistics-container {
        padding: 24px;
    }
    .footer-lower-menu-item {
        margin-left: 30px;
    }
    .footer-lower-menu {
        margin-left: -15px;
        padding: 0px 10px;
    }
    .footer-credit>* {
        width: 100%;
    }
    .footer-credit {
        flex-wrap: wrap;
        text-align: center;
        width: calc(100% + 40px);
        margin-left: -20px;
        border-top: 1px solid #e4e4e4;
    }
    .footer-credit *+* {
        margin-top: 22px;
    }
    .footer-lower {
        border: 0;
        padding: 27px 0 28px;
    }
    .fc-right3 {
        order: -1;
        border-top: 0 !important;
        margin-bottom: -22px;
    }
    .footer-menu-content {
        width: 100%;
        border-top: 1px solid #e4e4e4;
    }
    .fc-right3 .footer-menu-list {
        display: flex;
        flex-wrap: wrap;
    }
    .fc-right3 .footer-menu-item {
        width: 50%;
        margin-bottom: 22px;
    }
    .fc-right3 .footer-menu-item+.footer-menu-item {
        margin-top: 0;
    }
    .zrb-left {
        width: 100%;
        order: 2;
    }
    .zrb-right {
        width: 100%;
        height: 450px;
    }
    .zoo-map-image {
        height: 100%;
    }
    .zoo-map-image>img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .zrb-left {
        padding: 12px 0 0 5px;
    }
    .zml-slider .slick-list {
        padding-right: 22.5%;
    }
    .zoo-map-footer {
        text-align: center;
        padding: 15px 10px;
    }
    .zoo-map-box {
        background: #f5f5f5;
        height: 100%;
        display: flex;
        flex-flow: column;
    }
    .zoo-map-item {
        margin-top: 0 !important;
        padding: 0 4px;
        height: auto;
    }
    .zml-slider {
        padding-bottom: 0;
    }
    .zoo-map-list .slick-track {
        display: flex;
    }
    .zoo-map-box>span {
        margin-top: auto;
    }
    .blog-list:not(.blog-list-slider) .blog-item {
        width: 100%;
    }
    .pagination-arrow-box span {
        display: none;
    }
    .pagination-arrow-box> :first-child {
        margin: 0;
    }
    .pagination-arrow-box {
        padding: 3px 0;
    }
    .organisation-item {
        width: 100%;
    }
    .ldc-text {
        width: 100%;
        margin-top: 40px;
    }
    .ldc-image {
        width: 70%;
    }
    .ldc-text-head {
        font-size: 14px;
        padding-bottom: 11px;
    }
    .ldc-text-main h1 {
        font-size: 24px;
        position: static;
        padding-right: 0;
    }
    .ldc-text-main {
        margin-top: 8px;
        padding-right: 50px;
    }
    .ldc-text-main-icon {
        width: 52px !important;
        height: 52px !important;
        top: -11px !important;
        right: -6px !important;
    }
    .ldc-text-icon {
        top: 127%;
    }
    .ldc-text-icon>a {
        width: 18px;
        height: 18px;
    }
    .ldc-text-icon a+a {
        margin-left: 10px;
    }
    .ldc-text-head>small {
        margin-top: 7px;
    }
    ul.ltb-details-list {
        margin-top: 30px;
    }
    .ltb-details-item {
        padding-right: 0;
    }
    .tbc-body-inner {
        padding: 40px 0px;
    }
    .tbc-grid-item {
        width: 100%;
        padding-right: 20px;
    }
    .tbc-grid-item {
        margin-bottom: 40px;
    }
    .tbc-grid-list {
        margin-bottom: -40px;
    }
    .tbc-organization-item {
        width: 33.33%;
    }
    .doner-item,
    .contributor-item {
        width: 100%;
    }
    .media-item {
        width: 50%;
    }
    .od-content {
        width: 100%;
        padding-left: 0;
        padding-top: 0;
        margin-top: 28px;
    }
    .text-title {
        font-size: 14px;
    }
    .od-content-heading {
        margin-top: 8px;
    }
    .od-content-heading h1 {
        padding-bottom: 20px;
    }
    .page-detail-head-item {
        width: 100%;
        margin-bottom: 32px;
    }
    .page-detail-head-box {
        border-right: none;
    }
    .page-detail-head-list {
        margin-bottom: -32px;
    }
    .org-detail-container {
        padding: 50px 0px;
    }
    .od-logo {
        width: 100%;
    }
    .od-logo-inner {
        width: 140px;
    }
    .org-hero-container {
        height: 110px;
    }
    .od-logo-inner {
        height: 140px;
    }
    .blog-list-4 li {
        width: 100%;
    }
    .tbc-our-team-item {
        width: 33.33%;
    }
    .hero-social-media-list {
        padding-bottom: 15px;
    }
    .environmentalist-details-container .ldc-text-icon {
        top: -30px;
    }
    .environmentalist-details-img {
        border-radius: 20px;
    }
    .environmentalist-details-container .ldc-image {
        width: 63% !important;
    }
    .map-item {
        width: 50%;
    }
    .spd-head {
        padding-right: 0;
    }
    .spd-title {
        padding-right: 70px;
    }
    .spd-text-icon.d-flex {
        bottom: auto;
        top: -3px;
    }
    .spd-text-icon a img {
        width: 20px;
        height: 20px;
    }
    .spd-head .ei-heading h1 {
        font-size: 18px;
    }
    .publisher-footer {
        flex-wrap: wrap;
    }
    .publisher-details {
        width: 100%;
    }
    .publisher-details+.btn-dark-green {
        margin-left: 0;
        margin-top: 21px;
    }
    .tbc-zoo-details-text {
        width: 69.7%;
    }
    .tbc-body-inner .news-item {
        margin-bottom: 30px;
    }
    .tbc-body-inner .news-item .news-box:after {
        margin-bottom: 15px;
    }
    .map-hero {
        height: 420px;
    }
    .rhc-box-left {
        width: 100%;
        padding: 0 70px 0 0;
    }
    .rhc-box-right {
        width: 100%;
    }
    .rhc-box-inner {
        justify-content: start;
        margin-top: 30px;
    }
    .rhc-box-left>h1 {
        font-size: 24px;
        margin-top: 8px;
    }
    .rhc-box {
        position: relative;
    }
    .rhc-box .rhc-box-icon {
        position: absolute;
        right: 0;
        top: -3px;
    }
    .rhc-box .rhc-box-icon a {
        display: block;
        width: 20px;
        height: 20px;
    }
    .tbc-head {
        top: 52px;
    }
}


/* ======================================================
! Extra small devices (portrait phones, less than 576px)
====================================================== */

@media (max-width: 575.98px) {
    .ltb-details-item {
        width: 100%;
    }
    .tbc-organization-item {
        width: 50%;
    }
    .partner-item {
        width: 33.33%;
    }
    .tbc-our-team-item {
        width: 50%;
    }
    .national-sanctuaries-container .ei-grid-item {
        width: 100% !important;
    }
    .map-item {
        width: 100%;
    }
    .spd-image-inner {
        height: 365px;
    }
    .tbc-zoo-details-text,
    .tbc-zoo-details-img {
        width: 100% !important;
        padding: 0;
    }
    .tbc-zoo-details-img {
        height: 300px;
    }
    .tbc-zoo-details-text>h4 {
        font-size: 18px;
    }
}


/* ======================================================
! Extra small devices (portrait phones, less than 576px)
====================================================== */

@media (max-width: 480.98px) {
    .card-img-box.overflow-hidden {
        width: 100%;
    }
    .card-content-box {
        width: 100% !important;
        padding-left: 0 !important;
        margin-top: 30px;
    }
}


/* ================================= Modal Css Start Here ================================= */

.d-button-flex div {
    width: auto;
    padding: 5px;
}

.d-button-flex {
    width: calc(100% + 10px);
    margin-left: -5px;
}

.environmental-inclusion-modal .modal-content {
    border: 0;
    border-radius: 10px;
    overflow: hidden;
    font-family: "Poppins";
}

.environmental-inclusion-modal .close-btn {
    width: 24px;
    height: 24px;
    border: 0;
    background: transparent;
    position: absolute;
    right: 50px;
    top: 54px;
}

.environmental-inclusion-modal .modal-header {
    padding: 50px 80px 50px 50px;
    border-bottom: none;
}

.environmental-inclusion-modal .modal-title {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.6;
    color: var(--dark-black);
}

.form-floating {
    margin-bottom: 35px;
}

.file-input-group {
    position: absolute;
    right: 0;
    bottom: 5px;
}

.file-input-group input[type="file"] {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
    opacity: 0;
}

.file-input-group label.form-label {
    font-weight: 600;
    color: var(--green-color);
}

.custom-floating-input .form-floating>.form-control {
    height: 50px;
    line-height: 1;
    background-color: transparent;
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-color: rgb(0 0 0 / 20%) rgb(0 0 0 / 20%);
    padding-left: 0;
    font-size: 14px;
}

.custom-floating-input .form-floating>label {
    padding-left: 0;
    font-size: 14px;
    padding: 20px 0 0 0;
    border: 0;
}

.custom-floating-input .form-floating>.form-control:focus~label,
.custom-floating-input .form-floating>.form-control:not(:placeholder-shown)~label,
.custom-floating-input .form-floating>.form-select~label {
    opacity: 0.5;
    transform: scale(0.85) translateY(-1.4rem) translateX(0rem);
}

.custom-floating-input .form-floating .form-select {
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 15px;
    border-color: rgb(0 0 0 / 20%) rgb(0 0 0 / 20%);
    border-top: none;
    border-left: none;
    border-right: none;
    height: 50px;
    background-position: right 5px center;
    color: rgb(0 0 0 / 90%);
    border-radius: 0;
}

.form-control:focus {
    box-shadow: none !important;
}

.form-select:focus {
    box-shadow: none !important;
    border-color: rgb(0 0 0 / 20%);
}

textarea {
    resize: none;
}

.modal-backdrop.show {
    opacity: 0.6;
}

.modal-backdrop {
    background-color: #1e1e1e;
}

.environmental-inclusion-modal .modal-body {
    padding: 30px 50px 50px;
}

.row-form-button {
    margin-top: 60px;
}

.environmental-inclusion-modal .btn {
    font-size: 14px;
    line-height: 1.458;
    padding: 14px 15px;
}

.modal-xl {
    max-width: 1100px;
}

textarea#textarea {
    height: 140px !important;
}

input[type="file"],
input[type="file"]::-webkit-file-upload-button {
    cursor: pointer !important;
}

.uplode-img-list+.row-form-button {
    margin-top: 25px;
}

.custom-file-row {
    border: 2px dashed var(--black);
    padding: 15px;
    height: 67px;
    position: relative;
    border-radius: 6px;
    text-align: center;
    margin-bottom: 20px;
}

.custom-file-input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
}

.custom-file-input {
    display: none;
}

.uplode-img-list {
    width: calc(100% + 11px);
    margin-left: -5.5px;
    margin-top: -5.5px;
    margin-bottom: -5.5px;
}

.uplode-img-list li {
    padding: 5.5px;
}

.ui-img>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ui-box {
    max-width: 100px;
    position: relative;
    text-align: center;
    border-radius: 6px;
    overflow: hidden;
}

.ui-box .close-icon {
    position: absolute;
    top: 4px;
    right: 5px;
    background: var(--bs-white);
    width: 22px;
    height: 22px;
    line-height: 22px;
    border-radius: 2px;
    cursor: pointer;
}

.form-floating:not(.form-control:disabled)::before {
    background-color: transparent;
}

.modal-form-group .form-floating {
    margin: 0 10px 35px 10px;
    width: 100%;
}

.modal-form-group {
    width: calc(100% + 20px);
    margin-left: -10px;
}


/* ================================= Modal Css End Here ================================= */


/* ======================================== Zoo Details Page Start ============================== */

.full-height {
    min-height: calc(100vh - 80px);
}

.fw-medium {
    font-weight: 500;
}

.zoo-map {
    width: 50%;
    left: 0;
    top: 0;
    bottom: 0;
    margin-left: -173px;
    position: absolute;
    height: 100%;
}

.zoo-map>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.page-details-box {
    padding: 53px 0 62px 12px;
    color: var(--dark-black);
    line-height: 1.5;
}

.page-heading-row>span {
    margin-bottom: 10px;
}

.page-heading-row h1 {
    font-size: 40px;
    margin-bottom: 10px;
}

.page-link-list {
    margin-left: 12px;
}

.page-link-list .page-link {
    padding: 3px 3px;
    border: 0;
    background: transparent;
}

.page-heading-row h2 {
    font-size: 18px;
    margin-bottom: 0;
}

.page-divider {
    width: 100%;
    height: 1px;
    background: var(--black);
    margin: 20px 0;
}

.page-link:focus {
    box-shadow: none;
}

.page-list {
    width: calc(100% + 50px);
    margin-left: -25px;
    margin-bottom: -20px;
}

.page-list-item {
    width: 50%;
    padding: 20px 25px;
    margin-bottom: 20px;
}

.page-list-box {
    font-size: 18px;
}

.page-list-box>p {
    margin-bottom: 10px;
}

.page-list-box h4 {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 500;
}

.page-social-link li a {
    font-size: 22px;
}

.page-social-link li+li {
    margin-left: 26px;
}

.tbc-details-right strong {
    font-weight: 600;
}

.lg-tbc-details.tbc-details {
    padding: 0;
}

.lg-tbc-details .tbc-details-right {
    width: 40%;
}

.lg-tbc-details .tbc-details-left {
    width: 60%;
    padding-bottom: 45.3%;
}

.w-auto-list .blog-item {
    width: auto !important;
}

.w-auto-list .blog-box {
    max-width: 312px;
}

.tbc-contact-item>span {
    margin-top: 15px;
}

.tbc-contact-item+.tbc-contact-item {
    margin-top: 47px;
}

.tbc-contact-item address {
    display: inline-block;
    margin-top: 15px;
    max-width: 260px;
}

.card-list {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-bottom: -15px;
    margin-top: -15px;
}

.card-item {
    width: 50%;
    padding: 15px 20px;
}

.card-box {
    color: var(--black);
}

.card-box:hover {
    color: var(--black);
}

.card-box:hover .card-img-inside {
    transform: scale(1.1);
}

.card-img-box {
    width: 256px;
    border-radius: 10px;
}

.card-img-inside {
    width: 100%;
    position: relative;
    padding-bottom: 63%;
    transition: 0.5s all ease-in;
}

.card-img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
}

.card-content-box {
    width: calc(100% - 256px);
    font-size: 14px;
    line-height: 1.6;
    padding-left: 6.2%;
}

.card-content-box h5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 20px;
}

.card-content {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

[contenteditable="true"] {
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 4px;
}

.page-bar {
    padding: 4px 0;
}

.yellow-bg {
    background: #ffbb31;
}

.page-bar-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 0;
    margin-left: 50%
}

@media (max-width: 1199.98px) {
    .full-height {
        min-height: calc(100vh - 70px);
    }
    .page-details-box {
        padding: 50px 0;
    }
    .zoo-map {
        margin-left: -120px;
    }
    .page-heading-row h1 {
        font-size: 30px;
    }
    .page-list-box h4 {
        font-size: 18px;
    }
    .page-list-box {
        font-size: 16px;
    }
    .page-heading-row h2 {
        font-size: 16px;
    }
    .page-list {
        width: 100%;
        margin-left: 0;
        margin-bottom: -14px;
    }
    .page-list-item {
        margin-bottom: 14px;
        padding: 10px 0;
    }
}

@media (max-width: 991.98px) {
    .full-height {
        min-height: 100%;
    }
    .zoo-map {
        position: relative;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        margin: 0;
        width: 100%;
        height: 440px;
    }
    .card-item {
        width: 100%;
    }
    .page-details-box {
        padding: 30px 0;
    }
    .page-heading-row,
    .page-heading-row h2,
    .page-list-box {
        font-size: 14px;
        line-height: 1.5;
    }
    .page-heading-row h1 {
        font-size: 24px;
    }
    .page-heading-row {
        position: relative;
    }
}

@media (max-width: 767.98px) {
    .environmental-inclusion-modal .modal-header {
        padding: 30px 50px 30px 30px;
    }
    .environmental-inclusion-modal .close-btn {
        right: 25px;
        top: 34px;
    }
    .environmental-inclusion-modal .modal-body {
        padding: 25px 30px 30px;
    }
    .form-floating {
        margin-bottom: 25px;
    }
    .row-form-button {
        margin-top: 40px;
    }
    .ui-box {
        max-width: 80px;
    }
    .page-list-item {
        width: 100%;
    }
    .page-link-list {
        margin-left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
    .page-link-list .page-link {
        width: 24px;
    }
    .card-img-box {
        width: 200px;
    }
    .card-content-box {
        width: calc(100% - 200px);
        padding-left: 25px;
    }
    .card-content-box h5 {
        font-size: 18px;
        margin-bottom: 15px;
    }
}


/* ======================================== Zoo Details Page End ============================== */


/* ======================================= 29 AUGEST 2023 START ================================= */

.font-family-poppins {
    font-family: "Poppins", sans-serif;
}

.registration-flow-container {
    min-height: calc(100vh - 80px);
    border-top: 1px solid #e4e4e4;
    padding: 60px 0;
}

.rfc-content-holder {
    width: 100%;
    /* max-width: 648px; */
    background-color: #fff;
    border-radius: 10px;
    padding: 40px;
}

.registration-flow-container .form-floating+.form-floating {
    margin-top: 30px;
}


/* .rfc-content-holder.lg-holder {
                                                                                             max-width: 872px;
                                                                                         } */

.flex-step-list {
    max-width: 727px;
}

.flex-step-item:not(:last-child) {
    flex: 1 1 auto !important;
}

.flex-step-box {
    font-size: 14px;
    padding-left: 40px;
    background: #fff;
}

.flex-step-count {
    width: 25px;
    height: 25px;
    line-height: 25px;
    background: #f5f5f5;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Metropolis";
    font-weight: 500;
}

.flex-step-count>span {
    transition: 0.3s all linear;
}

.flex-step-item:not(:last-child)::before {
    content: "";
    width: calc(100% - 40px);
    height: 1px;
    background: #e4e4e4;
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
}

.flex-step-item:not(:last-child) .flex-step-txt {
    z-index: 2;
    padding-right: 30px;
}

.flex-step-count img.check {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all linear;
}

.current .flex-step-count,
.complete .flex-step-count {
    background: var(--green-color);
    color: #fff !important;
}

.complete.flex-step-item:not(:last-child)::before {
    background: var(--green-color);
}

.complete .flex-step-count span {
    opacity: 0;
    visibility: hidden;
}

.complete .flex-step-count img.check {
    opacity: 1;
    visibility: visible;
}

.rf-head-row {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;
}

.rf-head-row h1 {
    color: #1e1e1e;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 0;
}

.rf-total {
    opacity: 0.4;
    padding-left: 1px;
}

.rf-current {
    padding-left: 3px;
}

.rf-step-count {
    margin-bottom: 20px;
}

.rf-head-row+.rf-form-row-group {
    margin-top: 50px;
}

.rf-form-btn {
    margin-top: 30px;
}

.form-floating-row {
    width: calc(100% + 24px);
    margin-left: -12px;
}

.ff-column {
    width: 50%;
    padding: 0 12px;
    flex-grow: 1;
}

.custom-form-floating.form-floating>.form-control {
    height: 60px;
    line-height: 1;
    background-color: transparent;
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-color: #000;
    padding-left: 0;
    font-size: 14px;
    color: #000;
}

.custom-form-floating.form-floating>label {
    font-size: 14px;
    padding: 27px 0 0 0;
    border: 0;
    color: black;
    font-weight: 400;
    opacity: 1 !important;
}

.custom-form-floating.form-floating>.form-control:focus~label,
.custom-form-floating.form-floating>.form-control:not(:placeholder-shown)~label,
.custom-form-floating.form-floating>.form-select~label {
    opacity: 1;
    transform: scale(0.85) translateY(-1.7rem) translateX(0rem);
    color: black;
    font-size: 14px;
}

.custom-form-floating.form-floating .form-select {
    --bs-form-select-bg-img: url("../images/chevron-down-24x24.svg");
    background-size: 24px 24px;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 15px;
    border-color: #000;
    font-size: 14px;
    border-top: none;
    border-left: none;
    border-right: none;
    height: 60px;
    background-position: right 0 center;
    color: #000;
    border-radius: 0;
    cursor: pointer;
}

.custom-form-floating input[type="password"] {
    padding-right: 30px;
}

.ff-column {
    margin-bottom: 30px;
}

.form-floating.custom-form-floating {
    margin-bottom: 0;
}

.custom-form-floating .form-control.is-invalid,
.custom-form-floating .was-validated .form-control:invalid {
    background-image: none !important;
    border-color: #ff4242;
}

.invalid-feedback {
    font-size: 12px;
    color: #ff4242;
}

.show-password-btn {
    border: 0;
    background: transparent;
    position: absolute;
    right: 0;
    top: 25px;
}

.rf-label-box.email-label-box {
    color: rgb(0 0 0 / 90%);
}

.otp-box+.otp-box {
    margin-left: 15px;
}

.otp-input-fields input {
    height: 60px;
    width: 78px;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #e4e4e4;
    text-align: center;
    outline: none;
    color: #000;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.3;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.form-floating.custom-form-floating.has-select-button>.form-control {
    padding-right: 60px;
}

button.select-button-box {
    border: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 60px;
    background: transparent;
    font-size: 14px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 8px;
}

sup {
    color: rgb(255 66 66 / 60%);
    top: -4px;
}

.otp-input-fields+.rf-form-btn {
    margin-top: 60px;
}

.form-floating+.rf-form-btn {
    margin-top: 60px;
}

.signup-modal .modal-dialog {
    max-width: 931px;
}

.signup-modal .modal-content {
    border: 0;
    background: transparent;
    border-radius: 0;
}

.signup-modal-box {
    width: calc(100% - 39px);
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.signup-modal-close-box {
    width: 39px;
}

.signup-modal-close {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.signup-modal-title {
    color: #050505;
    font-size: 14px;
    line-height: 1.43;
}

.grey-content-box {
    padding: 15px;
    border-radius: 10px;
    background: #f5f5f5;
}

.rfc-content-holder .grey-content-box {
    margin: 28px 0;
}

.signup-modal-header {
    padding: 20px 20px 24px;
    color: #000;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.5;
}

.signup-modal-header p {
    font-family: "Inter", sans-serif;
}

.signup-modal-header h4 {
    color: #0f1419;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
}

.signup-modal-body {
    padding: 20px;
}

.signup-modal-footer {
    border-top: 1px solid #ebeef0;
    padding: 15px 20px;
}

.check-group-list {
    width: calc(100% + 16px);
    margin-left: -8px;
    margin-bottom: -16px;
}

.check-group-item {
    width: 33.33%;
    padding: 0 8px;
    margin-bottom: 16px;
}

.w-full-list>* {
    width: 100% !important;
}

.custom-form-check {
    padding-left: 35px;
    font-size: 13px;
    line-height: 20px;
    color: #000;
    margin: 0;
    text-align: left;
    position: relative;
    display: flex;
    align-items: center;
    min-height: auto;
}

.custom-form-check input[type="checkbox"].form-check-input {
    display: none;
}

.custom-form-check input[type="checkbox"]+.form-check-label {
    cursor: pointer;
}

.custom-form-check input[type="checkbox"]+.form-check-label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    border: 2px solid #e4e4e4;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    transition: all 0.2s ease-in;
}

.custom-form-check input[type="checkbox"].form-check-input:checked+.form-check-label::before {
    border-color: var(--green-color);
    background: var(--green-color);
}

.custom-form-check input[type="checkbox"]+.form-check-label::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    display: block;
    background: url(../images/checkbox-tick-icon.svg) no-repeat center/10px 7px;
    opacity: 0;
    transform: scale(0);
    transition: all 0.2s ease-in;
}

.custom-form-check input[type="checkbox"].form-check-input:checked+.form-check-label::after {
    opacity: 1;
    transform: scale(1);
}

.custom-form-check input[type="checkbox"].form-check-input:disabled+.form-check-label::before {
    background: #f6f6f6;
}

.clear__all__btn {
    font-family: "Metropolis";
    color: #47ad1d;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.4;
    text-decoration: underline;
}

.clear__all__btn:hover {
    text-decoration: none;
}

.cfc-label-para {
    font-family: "Metropolis";
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 1.4;
}

.tc-search-result-label {
    color: #47ad1d;
    font-family: "Metropolis";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.tc-search-result-row {
    margin-top: 26px;
}

.tc-search-result {
    margin-top: 16px;
}

.modal-serch-box {
    margin: 10px 0 15px;
}

.modal-search {
    border: 0;
    color: #0f1419;
    border-radius: 6px;
    background: #f5f5f5;
    padding: 10px 40px 10px 13px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.modal-search::placeholder {
    color: rgb(15 20 25 / 60%);
}

.modal-search-close-icon {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: transparent;
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
}

.cfc-label-title {
    font-family: "Metropolis";
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
}

.tc-right-inside {
    border-radius: 6px;
    border: 1px solid #ebeef0;
    background: #fff;
    padding: 18px;
}

.cols-row-group {
    margin-top: 30px;
}

.tc-left {
    width: 36%;
    padding-right: 1%;
}

.tc-left .signup-modal-title {
    margin-bottom: 15px;
}

.tc-right {
    width: 64%;
}

.tc-right>.signup-modal-title {
    margin-bottom: 10px;
}

.tc-btn-box+.check-group-list {
    margin-top: 16px;
}

.grey-content-box .check-group-list {
    margin-top: 15px;
}

.custom-accordion-flush .accordion-button {
    font-weight: 500;
    padding: 0;
    box-shadow: none;
}

.custom-accordion-flush.accordion-flush .accordion-item {
    border: 0;
}

.custom-accordion-flush .accordion-body {
    font-weight: 400;
    padding: 12px 35px;
}

.custom-accordion-flush .custom-form-check+.custom-form-check {
    margin-top: 16px;
}

.custom-accordion-flush .accordion-item+.accordion-item {
    margin-top: 15px;
}

.custom-accordion-flush .accordion-button:not(.collapsed) {
    background-color: transparent;
}

.accordion-button:focus {
    box-shadow: none;
}

.tc-right-inside .tc-search-result-row {
    height: 356px;
    overflow-y: auto;
    overflow-x: hidden;
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
    background-image: url("../images/x-close-24x24.svg");
}

.tc-search-result-row .custom-form-check+.custom-form-check {
    margin-top: 15px;
}

.tc-friends-btn {
    width: 86px;
}

.tc-friends-row .friends-list {
    width: calc(100% - 86px);
}

.tc-friends-row .friends-img-box {
    width: 48px;
    height: 48px;
}

.tc-friends-row .friends-text-box {
    width: calc(100% - 58px);
}

.tc-friends-row+.tc-friends-row {
    margin-top: 16px;
}

.friends-check-list .custom-form-check input[type="checkbox"]+.form-check-label::before,
.friends-check-list .custom-form-check input[type="checkbox"]+.form-check-label::after {
    top: 10px;
}

.friends-img-box {
    border: 1px solid rgb(228 228 228 / 60%);
    width: 38px;
    height: 38px;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 10px;
}

.tc-search-result>.tc-friends-row:first-child {
    margin-top: -18px;
}

.friends-img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.friends-text-box {
    width: calc(100% - 48px);
    color: #0f1419;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.step-tabs.nav-tabs {
    width: calc(100% + 20px);
    margin-left: -10px;
}

.step-tabs.nav-tabs {
    border: 0;
    margin-top: 20px;
    position: relative;
}

.step-tabs.nav-tabs .nav-link {
    color: #000;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    padding: 0;
    border: 0;
    cursor: pointer;
}

.step-tabs.nav-tabs .nav-link {
    position: relative;
    padding: 6px 0;
}

.step-tabs.nav-tabs .nav-link.active:before {
    opacity: 1;
    visibility: visible;
}

.step-tabs.nav-tabs .nav-link:before {
    content: "";
    width: 100%;
    height: 2px;
    background: #47ad1d;
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 5;
}

.step-tabs.nav-tabs .nav-item.show .nav-link,
.step-tabs.nav-tabs .nav-link.active {
    color: #47ad1d;
    font-weight: 500;
}

.step-tabs.nav-tabs .nav-item {
    padding: 0 10px;
}

.rfc-content-holder .tab-pane {
    padding-top: 30px;
}

.page-panel-container {
    padding: 30px 0 60px;
}

.panel-sidebar {
    width: 310px;
}

.panel-sidebar-right {
    width: 350px;
}

.panel-main-content {
    padding: 0 30px;
    width: calc(100% - 660px);
}

.panel-head {
    margin-bottom: 35px;
}

.panel-head .breadcrumb-row {
    margin-bottom: 20px;
}

.panel-head h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 0;
}

.widget+.widget {
    margin-top: 10px;
}

.widget {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 20px -2px rgba(0, 0, 0, 0.08);
    padding: 16px;
}

.widget-icon {
    width: 48px;
    height: 48px;
    margin-right: 12px;
}

.widget-content {
    width: calc(100% - 60px);
}

.w-icon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid rgb(228 228 228 / 60%);
}

.w-icon img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;
}

.w-textarea {
    width: 100%;
    border: 1px solid #ebeef0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    color: #0f1419;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    padding: 10px 12px 10px 0;
}

.w-textarea::placeholder {
    color: rgb(15 20 25 / 60%);
}

.w-button {
    padding: 0 7px;
}

.w-button-box {
    border: 0;
    background: transparent;
    opacity: 0.5;
}

.w-button-box:hover {
    opacity: 1;
}

.w-content-lower {
    margin-top: 15px;
}

.w-heading-box {
    color: #050505;
    font-size: 15px;
    line-height: 1.35;
}

.w-h-button-box {
    width: 26px;
    height: 26px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.heart-icon-box {
    width: 26px;
    height: 26px;
    border-radius: 4px;
    background: transparent;
    border: 0;
}

.heart-icon-box:hover {
    background-color: #f5f5f5;
}

.w-heading-block {
    padding-right: 30px;
    z-index: 2;
}

.w-time-box {
    color: rgb(5 5 5 / 50%);
}

.w-dot-box {
    width: 4px;
    height: 4px;
    border-radius: 50rem;
    background: rgb(5 5 5 / 50%);
    display: inline-block;
    margin: 0 6px;
}

.w-time-count {
    margin-right: 5px;
    font-size: 12px;
}

.w-text-box {
    color: #050505;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    font-family: "IBM Plex Sans", sans-serif;
}

.w-heading-block+.w-text-box {
    margin-top: 4px;
}

.w-iconlist-box {
    gap: 10px;
    cursor: pointer;
    padding: 0px 12px;
    color: #333;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
}

.w-text-box+.w-iconlist {
    margin-top: 25px;
}

.w-text-box+.w-iconlist {
    margin-top: 25px;
}


/* .w-iconlist-box:hover .w-iconl-box,.w-iconlist-box:hover .w-textl-box{opacity: 0.8;} */

.w-iconl-box {
    position: relative;
    width: 21px;
    height: 18px;
}

.w-iconl-box img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
}

.widget-text-holder .w-image-holder {
    margin: 12px 0 25px;
}

.w-image-holder {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.w-image-holder>img,
.w-image-holder video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.holder-button-box {
    width: 34px;
    height: 34px;
    border: 0;
    background: rgba(62, 62, 62, 0.6);
    backdrop-filter: blur(5px);
    border-radius: 50rem;
    z-index: 1;
    position: absolute;
    right: 21px;
    bottom: 20px;
}

.sticky-widget+.sticky-widget {
    margin-top: 16px;
}

.sticky-widget-wrap .sw-followers-box {
    margin: 10px 0 20px;
}

.sticky-widget-wrap {
    padding: 12px 16px 7px;
    border-radius: 10px;
    border: 1px solid #47ad1d;
}

.sw-text-img {
    width: 48px;
    height: 48px;
    margin-right: 10px;
}

.sw-text-label {
    width: calc(100% - 58px);
    color: #0f1419;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
}

.sw-img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 1px solid rgb(228 228 228 / 60%);
}

.sw-followers-box {
    gap: 15px;
}

.sw-followers-item {
    color: #000;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    font-family: "IBM Plex Sans", sans-serif;
}

.sw-followers-item>span {
    font-weight: 500;
}

.view-text-btn {
    color: #47ad1d;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
}

.view-text-btn:hover {
    text-decoration: underline;
    color: #47ad1d;
}

.sticky-label-widget {
    padding: 12px 16px;
    background: rgb(71 173 29 / 20%);
    border-radius: 10px;
    color: #227300;
    font-size: 13px;
    font-weight: 500;
    line-height: 150%;
    font-family: "IBM Plex Sans", sans-serif;
}

.panel-sidebar .accordion-button {
    cursor: pointer;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
    box-shadow: none;
    padding: 15px 0;
}

.add-plus-minus-arrow .accordion-button::after {
    background-image: url(../images/chevron-plus.svg);
    transform: none;
}

.add-plus-minus-arrow .accordion-button:not(.collapsed)::after {
    background-image: url(../images/minus.svg);
}

.panel-sidebar .accordion-flush .accordion-item {
    border-top: 1px solid #e4e4e4 !important;
}

.panel-sidebar .accordion-flush .accordion-item:first-child {
    border-top: 0 !important;
}

.panel-sidebar .tab-content .tab-pane:first-child .accordion-item {
    border-top: none !important;
}

.panel-sidebar .accordion-body {
    padding: 10px 0 15px;
}

.accordion-menu-list li+li {
    margin-top: 10px;
}

.accordion-menu-list li a {
    color: rgb(0 0 0 / 80%);
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
}

.accordion-menu-list li a:hover {
    text-decoration: underline;
}

.border-accordion .accordion-item+.accordion-item {
    margin-top: 16px;
}

.border-accordion.accordion-flush .accordion-item:first-child {
    border-top: 1px solid #eff1f2;
}

.border-accordion.accordion-flush .accordion-item:last-child {
    border-bottom: 1px solid #eff1f2;
}

.border-accordion .accordion-item {
    border-radius: 10px;
    border: 1px solid #eff1f2;
    background: #fff;
}

.border-accordion .accordion-button {
    border: 0;
    background: transparent;
    box-shadow: none;
    color: #0f1419;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 12px 16px;
    cursor: pointer;
}

.edit-green-btn {
    color: #227300;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: underline;
}

.edit-green-btn:hover {
    text-decoration: none;
}

.border-accor-list li a {
    color: #0f1419;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.border-accor-list li a:hover {
    text-decoration: underline;
}

.view-all-btn {
    color: #47ad1d;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    border: 0;
    background: transparent;
}

.view-all-btn:hover {
    text-decoration: underline;
}

.accor-view-all-btn[aria-expanded="true"] {
    display: none;
}

.select-button-box input#fileInput {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
}

.ff-close-img {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
    border: 0;
    border-radius: 50rem;
}

.ff-close-img {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
    border: 0;
    border-radius: 50rem;
    position: absolute;
    top: -7px;
    right: -7px;
}

.ff-image-box {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    border: 1px solid #e4e4e4;
    position: relative;
}

.ff-image-box>img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ff-label-box {
    color: black;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}

.ff-update-link {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-left: 20px;
    padding-bottom: 6px;
    border: none;
    color: var(--green-color);
    background: transparent;
}

.ff-change-link {
    position: absolute;
    right: 0;
    bottom: 0;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 8px;
    border: 0;
    color: var(--green-color);
    background: transparent;
}

.ff-update-link:hover,
.ff-change-link:hover {
    color: inherit;
}

.ff-update-link input#fileInput {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
}

.sticky-widget .tab-pane {
    padding: 15px 0 0;
}

.accor-d-area {
    margin: 13px 0 18px;
}

.d-item+.d-item {
    margin-top: 17px;
}

.accor-d-title {
    color: #0f1419;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
}

.accor-d-subtitle {
    color: rgb(0 0 0 / 50%);
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 4px;
}

.tc-friends-list {
    margin: 10px 0 15px;
}

.tc-friends-item+.tc-friends-item {
    margin-top: 9px;
}

.sticky-widget .accordion-body {
    padding: 12px 16px;
}

.sticky-widget .accordion-body {
    padding: 0 16px 12px;
}

.sticky-widget .step-tabs.nav-tabs {
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-top: 0;
}

.sticky-widget .step-tabs.nav-tabs:after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #eff1f2;
    position: absolute;
    bottom: -3px;
    left: 0;
}

.sticky-widget .step-tabs.nav-tabs .nav-item:first-child {
    padding-left: 16px;
}

.border-accor-list {
    margin: 13px 0;
}

.border-accor-list li+li {
    margin-top: 15px;
}

.w-h-button-box .dropdown-menu {
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 30px -2px rgba(0, 0, 0, 0.12);
    border: 0;
    padding: 9px 10px;
    min-width: 116px;
}

.w-h-button-box .dropdown-item {
    color: #050505;
    font-size: 15px;
    font-weight: 400;
    line-height: 150%;
    font-family: "IBM Plex Sans", sans-serif;
    padding: 0 10px;
}

.w-h-button-box li+li {
    margin-top: 8px;
}

.w-h-button-box .dropdown-item.delete {
    color: #ff4242;
}

.modal-widet-row {
    border-top: 1px solid #eff1f2;
}

.signup-modal .widget {
    box-shadow: none;
    border-radius: 0;
}

.w-btn-row .btn {
    font-size: 15px;
    padding: 10px;
}

.modal-widet-row .w-textarea::placeholder {
    color: #0f1419;
}

.signup-modal-header h4 {
    margin-bottom: 15px;
}

.smh-insider {
    padding-right: 30px;
}

.signup-modal-body .step-tabs.nav-tabs {
    margin: 0 0 30px -10px;
}

.page-modal-check-item+.page-modal-check-item {
    margin-top: 16px;
}

.signup-modal textarea.form-control {
    border-radius: 6px;
    background: #f5f5f5;
    padding: 10px 13px;
    border: 0;
    min-height: 115px;
    color: #0f1419;
}

.signup-modal textarea.form-control::placeholder {
    color: rgb(15 20 25 / 50%);
}

.page-modal-text-area {
    margin-top: 23px;
}

.panel-sidebar .accordion-flush .tab-intro-wrapper+.accordion-item {
    border: 0 !important;
}

.wpw-title {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 10px;
}

.wpw-content-head-icon {
    width: 98px;
    height: 98px;
    background: #fff;
    border-radius: 10px;
    border: 4px solid #fff;
    box-shadow: 0px 4px 26px -5px rgba(0, 0, 0, 0.1);
}

.btn-dark-grey {
    border-radius: 4px;
    background: #eff1f2;
    padding: 10px 15px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.btn-dark-grey:hover,
.btn-dark-grey:focus {
    background-color: var(--green-color);
    color: #fff;
}

.ww-page-link-list {
    width: calc(100% + 30px);
    margin-left: -15px;
}

.ww-page-link-item {
    padding: 0 12px;
}

.ww-text-box {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    font-weight: 400;
}

.ww-text-box>span {
    font-weight: 600;
    color: #000;
    display: inline-block;
    margin-right: 4px;
}

.ww-text-list {
    width: calc(100% + 36px);
    margin-left: -18px;
}

.ww-text-item {
    padding: 0 18px;
}

.tab-intro-wrapper {
    margin-top: 15px;
    font-size: 13px;
    font-weight: 500;
    line-height: 150%;
    font-family: "IBM Plex Sans", sans-serif;
    color: #050505;
}

.w-page-link-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.w-page-link {
    width: calc(100% - 29px);
    color: rgb(0 0 0 / 60%);
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.w-page-link a {
    color: #1da1f2;
}

.w-page-link a:hover {
    text-decoration: underline;
}

.widget-profile-block+.widget {
    margin-top: 10px;
}

.wpw-content-row {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 20px -2px rgba(0, 0, 0, 0.08);
}

.w-body-content {
    padding: 20px 25px 30px;
}

.wpw-content-body .profile-page-link {
    padding: 0 25px;
}

.w-body-content {
    padding: 20px 26px 30px;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.w-body-content>h4 {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 15px;
}

.wpw-content-img-box {
    position: relative;
    padding-bottom: 31%;
}

.wpw-content-img-box>img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
}

.wpw-content-head.d-flex.align-items-center.justify-content-between {
    padding: 10px 25px 0;
}

.wpw-content-head-icon {
    margin-top: -55px;
    position: relative;
    z-index: 2;
}

.p-p-box {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    padding-bottom: 13px;
}

.p-p-box:before {
    content: "";
    width: 100%;
    height: 5px;
    border-radius: 4px;
    background: #47ad1d;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all ease-in-out;
}

.p-p-box:hover:before,
.p-p-item.active .p-p-box:before {
    opacity: 1;
    visibility: visible;
}

.p-p-item.active .p-p-box {
    font-weight: 500;
}

.w-page-link-list {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-bottom: 15px;
}

.w-page-link-item {
    padding: 5px 12px;
}

.w-body-content p:not(last-child) {
    margin-bottom: 15px;
}

.p-p-btn {
    margin-top: 40px;
}

.add-green-border {
    border: 2px solid rgba(71, 173, 29, 0.5);
}

.trash-button-box {
    width: 38px;
    height: 38px;
    border-radius: 5px;
    background: #fff;
    border: 0;
    position: absolute;
    right: 17px;
    top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.col-badges-box {
    padding: 6px 12px;
    border-radius: 60px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #47ad1d;
    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.colse-x-btn {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    border: 0;
    background: transparent;
}

.col-select-btn {
    color: #47ad1d;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    border: 0;
    background: transparent;
}

.col-select-btn:hover {
    text-decoration: underline;
}

.col-text-item+.col-text-item {
    border-top: 1px solid #ebeef0;
    padding-top: 11px;
}

.col-text-item {
    padding: 6px 0;
}

.dropdown-link {
    color: #000;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    padding: 4px 10px;
}

.col-dropdown-menu .dropdown-menu {
    margin-top: 0 !important;
}

.dl-icon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
}

.chevron-down-wrap {
    width: 16px;
    height: 16px;
    margin-left: 5px;
}

.col-text-box {
    color: #000;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
}

.col-text-area {
    margin: -15px 0 0 0;
}

.has-green-toggle {
    color: #47ad1d;
}

.dropdown-toggle-inside {
    position: relative;
    z-index: 3;
    border-radius: 4px;
    padding: 6px 10px;
}

.col-dropdown-menu .dropdown-toggle:before {
    content: "";
    width: 100%;
    height: 100%;
    background: currentColor;
    opacity: 0.4;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 4px;
    z-index: 1;
}

.col-dropdown-menu .dropdown-menu {
    min-width: 121px;
    margin-top: 6px !important;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 30px -2px rgba(0, 0, 0, 0.12);
    border: 0;
    padding: 9px 0;
}

.col-dropdown-menu .dropdown-item {
    padding: 0;
}

.col-dropdown-menu .dropdown-item+.dropdown-item {
    margin-top: 2px;
}

.dropdown-link:hover {
    background: #f5f5f5;
}

.col-btn-group {
    margin-top: 15px;
}

.dropdown-item .dl-icon {
    color: rgb(0 0 0 / 20%);
    transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
}

.w-text-box .w-button-holder {
    margin-top: -10px;
    margin-bottom: 25px;
    gap: 10px;
}

.has-blue-toggle {
    color: #1d71ad;
}

.dropdown-link:hover .dl-icon {
    color: var(--green-color) !important;
}

.sbw-btn {
    border: 0;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    width: 100%;
    padding: 18px;
}

.sbw-btn-green {
    background: #227300;
}

.sbw-btn-light-green {
    background: #47ad1d;
}

.panel-header-inside {
    padding: 18px 44px 18px 20px;
    height: 60px;
    background: #fff;
    box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);
}

.panel-close-button {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.panel-header-title {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
}

.col-text-label {
    width: 50%;
}

.col-select-btn {
    width: 50%;
    text-align: right;
}

.error-page-container {
    min-height: calc(100vh - 80px);
    padding: 20px;
}

.error-content-box>h6 {
    color: #333;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    margin-bottom: 35px;
}

.error-content-box {
    color: rgb(0 0 0 / 80%);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: uppercase;
}

.four-zero-four-img {
    margin-bottom: 41px;
}

.error-content-box .btn {
    margin-top: 80px;
}

.login-container {
    min-height: calc(100vh - 130px);
    border-top: 1px solid #e4e4e4;
    padding: 60px 0;
}

.login-content-holder {
    width: 100%;
    max-width: 648px;
    background-color: #fff;
    border-radius: 10px;
    padding: 40px;
}

.login-head-row {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
}

.login-head-row h1 {
    color: #1e1e1e;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 0;
}

.login-head-row h1+p {
    margin-top: 10px;
}

.login-form-btn .btn {
    width: 100%;
    padding: 15px;
}

.login-head-row+.login-form-row-group {
    margin-top: 30px;
    margin-bottom: 30px;
}

.form-floating-row.remember-me-row {
    width: 100%;
    margin: 10px 10px 10px 0px;
}

.form-check {
    padding-left: 35px;
    font-size: 0.875rem;
    line-height: 1.2;
    margin: 0;
    text-align: left;
    position: relative;
}

.form-check input[type="checkbox"].form-check-input {
    display: none;
}

.form-check input[type="checkbox"]+.form-check-label {
    cursor: pointer;
}

.form-check input[type="checkbox"]+.form-check-label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    border: 2px solid #e4e4e4;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    transition: all 0.2s ease-in;
}

.form-check input[type="checkbox"].form-check-input:checked+.form-check-label::before {
    border-color: var(--green-color);
    background: var(--green-color);
}

.form-check input[type="checkbox"]+.form-check-label::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    display: block;
    background: url(../images/checkbox-tick-icon.svg) no-repeat center/12px 9px;
    opacity: 0;
    transform: scale(0);
    transition: all 0.2s ease-in;
}

.form-check input[type="checkbox"].form-check-input:checked+.form-check-label::after {
    opacity: 1;
    transform: scale(1);
}

.remember-me-row a {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--green-color);
}

.login-link {
    margin-top: 40px;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1;
}

.login-link a strong {
    color: var(--green-color) !important;
    font-weight: 500 !important;
}

#footer2 {
    background: linear-gradient(0deg, #f5f5f5, #f5f5f5), linear-gradient(90deg, #a1cf6d -11%, var(--green-color) 166.8%);
    width: 100%;
    height: 50px;
    padding: 15px 0;
    font-size: 14px;
    line-height: 1.2;
}

#footer2 .copyright-box {
    opacity: 0.85 !important;
}

.footer-nav-list li {
    position: relative;
    padding: 0 10px;
    color: rgba(0, 0, 0, 0.8);
}

.footer-nav-list li:not(:last-child):before {
    content: "";
    width: 1px;
    height: calc(100% - 4px);
    background: currentColor !important;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.widget-media-block {
    width: calc(100% + 10px);
    margin-left: -5px;
    margin-bottom: -10px;
    margin-top: 10px;
}

.wmb-item {
    width: 33.33%;
    padding: 0 5px;
    margin-bottom: 10px;
}

.wmb-img-box {
    border-radius: 10px;
}

.wmb-img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.shared-post-list {
    margin-bottom: 21px;
    margin-top: 12px;
}

.shared-post-img-box {
    width: 44px;
    height: 44px;
    border-radius: 6px;
    overflow: hidden;
    margin-left: 14px;
}

.shared-post-img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.shared-post-text-box {
    width: calc(100% - 58px);
}

.shared-post-title {
    color: #0f1419;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shared-post-subtitle {
    color: #0f1419;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    margin-top: 4px;
}

.shared-post-item+.shared-post-item {
    margin-top: 21px;
}

.custom-dropdown2 .dropdown-menu {
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 30px -2px rgba(0, 0, 0, 0.12);
    border: 0;
    min-width: 124px;
}

.custom-dropdown2 .dropdown-item-icon {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    opacity: 0.7;
}

.custom-dropdown2 .dropdown-item-text {
    width: calc(100% - 26px);
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    font-family: "IBM Plex Sans", sans-serif;
    padding: 0;
}

.custom-dropdown2 .dropdown-item+.dropdown-item {
    margin-top: 10px;
}

.custom-dropdown2 .dropdown-item {
    padding: 0 10px;
}

.custom-dropdown2 .dropdown-item.active,
.custom-dropdown2 .dropdown-item:active,
.custom-dropdown2 .dropdown-item:hover {
    color: var(--green);
    text-decoration: none;
    background-color: #f4f4f4;
}

.custom-dropdown2 .dropdown-item:hover .dropdown-item-text {
    color: var(--green);
}

.widget-tab-nav {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 20px -2px rgba(0, 0, 0, 0.08);
    margin-bottom: 10px;
    padding: 16px 25px 0;
}

.widget-tab-content {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 20px -2px rgba(0, 0, 0, 0.08);
}

.preloader {
    width: 200px;
    height: 200px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
}

.header-profile-img {
    width: 34px;
    height: 34px;
    border: 1px solid #e4e4e4;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 3px;
    background: linear-gradient(94deg, #2eb1dd -107.97%, #8761da 111.63%);
    color: #fff;
}

.header-profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#header .dropdown-toggle::after {
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    font-size: 13px;
    display: inline-block;
    font-weight: 900;
    border: none;
    color: var(--white);
}

.header-menu-area .dropdown-menu {
    margin-top: 8px;
    min-width: 132px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 30px -2px rgba(0, 0, 0, 0.12);
    border: 0;
}

.dropdown-item-text {
    padding: 0;
    width: calc(100% - 26px);
    color: #000;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
}

.dropdown-item-icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.header-menu-area .dropdown-item {
    padding: 6px 10px;
}

.dropdown-menu-items+.dropdown-menu-items {
    margin-top: 2px;
}

.dropdown-menu-items .dropdown-item.active,
.dropdown-menu-items .dropdown-item:active {
    color: #000;
    text-decoration: none;
    background-color: #f5f5f5;
}

.widget-tab-nav .nav-tabs {
    padding: 0;
    border: 0;
    margin: 0;
    gap: 40px;
}

.widget-tab-nav .nav-link {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    padding: 0 0 13px;
    border: 0;
}

.widget-tab-nav .nav-link:before {
    content: "";
    width: 100%;
    height: 5px;
    border-radius: 4px;
    background: #47ad1d;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all ease-in-out;
}

.widget-tab-nav .nav-link:hover:before,
.widget-tab-nav .nav-link.active:before {
    opacity: 1;
    visibility: visible;
}

.widget-tab-nav .nav-link.active {
    font-weight: 500;
}

.widget-member-item+.widget-member-item {
    margin-top: 4px;
}

.widget-member-row {
    padding: 4px 16px;
}

.widget-member-img-box {
    width: 48px;
    height: 48px;
    border-radius: 6px;
    overflow: hidden;
}

.widget-member-img-box>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.widget-member-text-box {
    padding: 6px 0 6px 10px;
    width: calc(100% - 48px);
}

.widget-member-text {
    color: #0f1419;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    flex-grow: 1;
}

.widget-member-btn {
    margin-left: 15px;
}

.widget-tab-content-box {
    padding: 12px 0;
}

.full-width-modal .modal-content {
    background: transparent;
    border: 0;
    border-radius: 0;
}
.fwm-head-content {
    padding: 25px 15px 0 ;
}
.fwm-content-left {
    width: 56.3%;
    padding:0px 0px 0px 40px;
    position: relative;
}
.all-comment-model .fwm-content-left{
    width: 60%;
    padding: 0 40px;
    background-color: rgb(0 0 0 / 90%);
}
.all-comment-model .fwm-content-right{
    width: 40%;
}
.all-comment-model .fwm-content-box {
    max-width: 100%;
    margin: 0;
}
.all-comment-model .fwm-content-left .w-image-holder>img{
    position: relative;
    object-fit: contain;
}
.fwiw-img{
    width: auto; 
    height: 100%;
}
.fit-width-image-wrap img{
    object-fit: contain;
}
.fwm-content-left .widget {
    padding-bottom: 30px;
    height: 100%;
}

.fwm-title {
    color: #050505;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
}

.fwm-close-btn>img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    filter: brightness(0) invert(1);
}

.fwm-close-btn {
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    position: absolute;
    top: 15px;
    left: 15px;
    width: 24px;
    height: 24px;
}

.fwm-content-right {
    width: 43.7%;
    background-color: #FBFBFB;
}
.fit-width-image-wrap .w-image-holder {
    border-radius: 0;
}
.overflow-hidden-y-auto{
    overflow-y: auto;
}
.fwm-content-box {
    max-width: 1440px;
    margin: 0 auto;
}

.fwm-widget {
    padding: 0px 15px 0px 15px;
}

.wo-head {
    padding-top: 16px;
    padding-left: 76px;
    padding-bottom: 7px;
}

.wo-widget+.wo-widget {
    margin-top: 10px;
}

.fwm-header {
    padding: 25px 15px 17px;
}

.full-width-modal .dropdown-toggle::after {
    content: none;
}

.code-block-icon {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}

.widget-reply-code-block button {
    border: 0;
    background-color: transparent;
}

.code-block-label {
    color: #050505;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.5;
}

.fwm-widget {
    padding: 0 15px;
}

.fwm-widget+.fwm-widget {
    margin-top: 10px;
}

.fwm-widget .widget {
    position: relative;
    border-radius: 0;
    box-shadow: none;
}

.wo-body .widget-content {
    position: relative;
}

.wo-body .widget-content::before {
    content: "";
    width: 1px;
    height: calc(100% + 70px);
    background-image: url(../images/line----bg.svg);
    position: absolute;
    left: -35px;
    top: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.wo-body .widget-icon {
    position: relative;
    z-index: 2;
}

.widget-outer {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 20px -2px rgba(0, 0, 0, 0.08);
    overflow: hidden;
}

.fwm-widget .widget+.widget {
    margin-top: 0;
}

.w-iconlist+.widget-reply-code-block {
    margin: 40px 0 -10px;
}

.widget-outer .widget+.widget .widget-icon {
    margin-top: 0;
}

.widget-outer .wo-widget:last-child .widget-content::before {
    content: none;
}

.fwm-body {
    overflow-y: auto;
}

.fwm-content-right:before {
    content: "";
    width: 50vw;
    height: 100%;
    position: absolute;
    left: 100%;
    background-color: #fbfbfb;
}

.fwm-content-left .w-image-holder {
    flex-grow: 1;
}

.fwm-content-left .w-image-holder>img,
.fwm-content-left .w-image-holder video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.pin {
    width: 52px;
    height: 52px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    cursor: pointer;
}

.custom-popover.popover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
    border: 0;
    border-bottom: 6px;
    padding: 10px 15px;
}

.custom-popover .popover-body {
    padding: 0;
    color: var(--black);
}

.custom-popover .popover-arrow::before {
    content: none !important;
}

.share-post:hover .dropdown-item-text {
    color: var(--green);
}

.share-post .dropdown-item-icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    padding-bottom: 22px;
}

@media (max-width: 1199.98px) {
    .rf-head-row h1 {
        font-size: 28px;
    }
    .fwm-content-left {
        padding: 0 0px 0 40px;
    }
}

@media (max-width: 1199.98px) {
    .panel-main-content {
        padding: 0;
        padding-left: 30px;
        width: calc(100% - 310px);
    }
    .panel-sidebar-right {
        width: 100%;
        position: fixed;
        top: 0;
        bottom: 0;
        right: -100%;
        left: auto;
        background: #fff;
        z-index: 99999;
        transition: 0.2s all ease-in;
    }
    .panel-sidebar-right.open {
        right: 0;
    }
    .panel-sidebar-right .sticky-lg-top,
    .panel-sidebar .panel-sidebar-inside {
        padding: 16px 20px;
        height: calc(100vh - 60px);
        overflow-y: auto;
    }
    .sbw-item {
        width: 100%;
    }
}

@media (min-width: 991.98px) {
    .page-panel-container .sticky-lg-top {
        top: 90px;
    }
    .panel-sidebar-inside {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 0px 36px -2px rgba(0, 0, 0, 0.06);
        padding: 3px 18px;
    }
}

@media (max-width: 991.98px) {
    .flex-step-item:not(.current) {
        display: none !important;
    }
    .flex-step-item:not(:last-child)::before {
        content: none !important;
    }
    .flex-step-item:not(:last-child) {
        flex: none !important;
    }
    .flex-step-item:not(:last-child) .flex-step-txt {
        padding-right: 0;
    }
    .flex-step-count {
        margin-top: -3px;
    }
    .add-accor2tab {
        display: flex;
        flex-wrap: wrap;
    }
    .tab-container {
        width: 250px;
    }
    .tab-content-wrapper {
        width: calc(100% - 250px);
        padding-left: 25px;
    }
    .wpw-title {
        font-size: 20px;
        margin-bottom: 20px;
    }
    .signup-modal.modal .modal-dialog {
        position: fixed;
        margin: auto;
        width: 100%;
        max-width: 100%;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
    }
    .signup-modal.modal .modal-content {
        height: 100%;
        overflow-y: auto;
        border: 0;
        border-radius: 0;
    }
    .signup-modal.modal.fade .modal-dialog {
        right: -100%;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
    }
    .signup-modal.modal.fade.show .modal-dialog {
        right: 0;
    }
    .modal_close-box {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 0;
        top: 0;
    }
    .signup-modal-box {
        width: 100%;
        border-radius: 0;
        height: 100%;
        display: flex;
        flex-flow: column;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .signup-modal-parent {
        height: 100%;
    }
    .signup-modal-body {
        flex-grow: 1;
    }
    .panel-main-content {
        padding: 0;
        width: 100%;
    }
    .panel-sidebar {
        width: 100%;
        position: fixed;
        top: 0;
        bottom: 0;
        right: auto;
        left: -100%;
        background: #fff;
        z-index: 99999;
        transition: 0.2s all ease-in;
    }
    .panel-sidebar.open {
        left: 0;
        right: 0;
    }
    .sbw-item {
        width: 50%;
    }
    .panel-sidebar-inside .accordion {
        width: 100%;
    }
    .fwm-content-left {
        padding: 60px 0px 0px;
        width: 100%;
        background-color: #fff;
    }
    .fwm-content-left .widget {
        width: 100%;
        border-radius: 0;
    }
    .fwm-close-btn {
        color: #000;
        top: 20px;
        left: auto;
        right: 20px;
    }
    .fwm-close-btn>img {
        filter: none;
    }
    .fwm-content-right {
        width: 100%;
    }
    .fwm-content-left .w-image-holder {
        flex-grow: unset;
        height: 300px;
    }
    .fwm-content-left .widget {
        height: auto;
    }
    .fwm-content-box.h-100,
    .fwm-content-left.h-100,
    .fwm-content-right.h-100 {
        height: auto !important;
    }
    .all-comment-model .fwm-content-left,
    .all-comment-model .fwm-content-right{
        width: 100%;
    }
    .all-comment-model .fwm-content-left {
        padding: 25px;
    }
    .fwm-head-content {
        padding: 25px 15px 25px 15px;
        width: 100%;
    }
    .all-comment-model .fwm-header {
        padding: 25px 15px 17px 15px;
    }
    .all-comment-model .fwm-widget {
        padding: 0 15px 0;
    }
    .all-comment-model .fwm-close-btn>img {
        filter: brightness(0) invert(1);
    }
    .all-comment-model  .fwm-close-btn {
        top: 35px;right: 35px;
    }
}

@media (max-width: 767.98px) {
    .otp-input-fields input {
        height: 50px;
        width: 50px;
    }
    .p-p-btn .btn {
        min-width: 100%;
        padding: 15px;
        font-size: 14px;
    }
    #footer2 {
        text-align: center;
    }
    #footer2 .copyright-box {
        margin-top: 10px;
    }
}

@media (max-width: 575.98px) {
    .registration-flow-container {
        padding: 40px 0;
    }
    .signup-modal-header,
    .grey-content-box,
    .signup-modal-body,
    .signup-modal-footer {
        padding: 15px;
    }
    input.otp-box+input.otp-box {
        margin-left: 10px;
    }
    .otp-input-fields input {
        font-size: 16px;
        height: 50px;
        width: 50px;
    }
    .check-group-item {
        width: 50%;
    }
    .tc-left {
        width: 100%;
        padding-right: 0;
    }
    .tc-right {
        width: 100%;
        margin-top: 25px;
    }
    .rfc-content-holder {
        padding: 24px;
    }
    .registration-flow-container {
        min-height: auto;
    }
    .ff-column {
        width: 100%;
    }
    .rf-head-row h1 {
        font-size: 24px;
        margin-bottom: 15px;
    }
    .rf-step-count {
        margin-bottom: 15px;
    }
    .rf-head-row+.rf-form-row-group {
        margin-top: 35px;
    }
    .ff-column {
        margin-bottom: 25px;
    }
    .rf-form-btn {
        margin-top: 15px;
    }
    .rf-form-btn .btn.btn-lg {
        min-width: 100%;
        padding: 12px 15px;
    }
    .otp-input-fields+.rf-form-btn,
    .form-floating+.rf-form-btn {
        margin-top: 40px;
    }
    .w-page-link-item+.w-page-link-item {
        margin-top: 15px;
    }
    .holder-button-box {
        width: 30px;
        height: 30px;
        right: 12px;
        bottom: 12px;
    }
    .error-content-box .btn {
        margin-top: 35px;
    }
    .remember-me-row a {
        margin-top: 20px;
    }
}

@media (max-width: 400.98px) {
    .navbar-brand {
        width: 130px;
    }
    .check-group-item {
        width: 100%;
    }
    input.otp-box+input.otp-box {
        margin-left: 5px;
    }
    .otp-input-fields input {
        height: 25px;
        width: 25px;
    }
    .rf-head-row h1 {
        font-size: 22px;
    }
    .custom-form-floating.form-floating>label {
        font-size: 10px;
    }
    button.select-button-box {
        font-size: 12px;
    }
}


/* ======================================= 29 AUGEST 2023 END ================================= */

.panel-empty-wrapper {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.6;
    max-width: 350px;
    padding-top: 130px;
}

.panel-empty-wrapper h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.pew-icon {
    margin-bottom: 40px;
    width: 80px;
    height: 80px;
}

.pew-icon.icon-lg {
    width: 110px;
    margin-left: 24px;
}

.pew-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.no-box-shadow {
    border-radius: 0;
    background: transparent;
    box-shadow: none;
}


/* ======================================= Login Content Start ================================= */

.sucess-messgae-block {
    max-width: 321px;
    margin: 0 auto;
}

.block-icon-box {
    width: 83px;
    margin: 0 auto 40px;
}

.sucess-messgae-block p {
    font-weight: 600;
    font-size: 1.3125rem;
    line-height: 160%;
    margin-bottom: 60px;
    text-transform: capitalize;
}

.sucess-messgae-block .btn {
    padding: 12px 15px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 260px;
    margin: 0 auto;
}

.btn-post {
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* ======================================= Login Content End ================================= */


/*Media css */

.media-tab-head .nav-pills .nav-item .nav-link {
    color: var(--black);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    background: transparent;
    position: relative;
    padding: 10px 0;
}

.media-tab-head .nav-pills .nav-item .nav-link .active {
    color: var(--green-color) !important;
}

.activeMedia {
    color: var(--green-color) !important;
}

.media-tab-head .nav-pills .nav-item .nav-link::after {
    position: absolute;
    bottom: 0;
    content: "";
    left: 0;
    width: 0;
    background: var(--green-color);
    border-radius: 6px;
    transition: 0.3s;
    height: 3px;
}

.media-tab-head .nav-pills .nav-item .nav-link.activeMedia::after,
.media-tab-head .nav-pills .nav-item .nav-link:hover::after {
    width: 100%;
}

.reports-list.media-list .media-box {
    background: #f2f2f2;
    padding: 20px 0;
}

.reports-list.media-list .media-box-inner {
    padding-bottom: 63%;
}

.reports-list.media-list .media-box-inner img {
    width: 80%;
    height: 80%;
    object-fit: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.reports-list.media-list .media-text-box {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
}

.reports-list.media-list .media-text-box {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
}

.new-tabs-block-container.tabs-block-container .tbc-head {
    padding: 20px 0;
}

.new-tabs-block-container.tabs-block-container .tbc-head .nav-pills {
    gap: 10px 20px;
    justify-content: start;
}

.new-tabs-block-container.tabs-block-container .tbc-head .nav-pills .nav-link:after {
    display: none;
}

.new-tabs-block-container.tabs-block-container .tbc-head .nav-pills .nav-link {
    padding: 5px 15px;
    border: 1px solid #fff;
    border-radius: 50px;
}

.new-tabs-block-container.tabs-block-container .tbc-head .nav-pills .nav-link.active,
.new-tabs-block-container.tabs-block-container .tbc-head .nav-pills .nav-link:hover {
    background: #fff;
    color: var(--green-color);
}

.new-tabs-block-container.tabs-block-container .tbc-head .nav-item+.nav-item {
    margin-left: 0;
}

.ei-green-label-block {background: rgb(71, 173, 29);background: linear-gradient(140deg, rgba(71, 173, 29, 1) 0%, rgba(34, 115, 0, 1) 100%);border-radius: 10px;padding: 22px 18px;color: #fff;font-weight: 600;    font-family: "Poppins", sans-serif;margin-top: 20px;}
.ei-g-l-row {margin-top: 18px;}
.ei-g-l-item:not(:last-child) {border-bottom: 1px solid rgb(255 255 255 / 20%);}
.ei-g-l-box{padding: 14px 0;}
.ei-g-l-title{font-size: 20px;}
.nav-link-box {font-size: 16px;line-height: 1;color: #050505;font-family: "Poppins", sans-serif;gap: 14px;cursor: pointer;}
.nav-link-icon {width: 24px;height: 24px;}
.nav-link-icon > svg{width: 100%;height: 100%;}
.nav-link.active .nav-link-box {color: #47AD1D;cursor: pointer;}
.map-tab-container .nav-tabs {border: 0!important;}
.map-tab-container .nav-link {border: 0;border-radius: 0;}
.map-tab-container .nav-item{position: relative;}
.map-tab-container .nav-item:not(:last-child):before{content: '';width: 1px;height: calc(100% - 18px);top: 50%;transform: translateY(-50%);right: 0;background-color: rgb(0 0 0 / 10%);position: absolute;}
.map-tab-container .accordion-button {padding: 20px;background: var(--dark-green-color);}
.map-tab-container .accordion-button  .nav-link-box{ color: #fff;}
.map-tab-container  .accordion-body {padding:0;position: relative;}


.gallery-map .grid {width: calc(100% + 20px);margin-left: -10px;}
.gallery-map .grid-sizer, .grid-item {width: 33.33%;padding: 0 10px;}
.gallery-map .grid-item,.map-tab-container .grid-item{margin-top: 36px;}
.eap-img-box {border-radius: 10px;overflow: auto;margin-bottom: 14px;}
.eap-text-box h5 {font-size: 15px;font-weight: 600;color: #050505;margin-bottom: 3px;}
.eap-text-box {font-size: 15px;color: #050505;}
.eap-list {gap: 10px;}
.eap-item {font-weight: 400;line-height: 1;color: #47AD1D;}
.eap-text-box p {margin-bottom: 3px;}

.gmb-media-box {padding: 50px 50px 0;}
.gmb-media {padding-bottom: 51.5%;border-radius: 10px;overflow: hidden;}
.gmb-media>* {position: absolute;top: 0;right: 0;bottom: 0;left: 0;}
.gmb-content-box {padding: 0 50px 50px;margin-top: 30px;}
.gmb-content-left {width: calc(100% - 252px);padding-right: 30px;font-size: 15px;color: #050505;border-right: 1px solid rgb(0 0 0 / 5%);}
.gmb-cl-item {font-size: 14px;font-weight: 500;color: #47AD1D;}
.gmb-cl-item + .gmb-cl-item {margin-left: 10px;}
.gmb-content-right {width: 252px;padding-left: 30px;}
.gmb-content-right h5 {color: #050505;font-size: 18px;font-weight: 600;margin-bottom: 0;}
.gmb-cr-list {margin-top: 7px;}
.gmb-cr-item + .gmb-cr-item {margin-top: 10px;}
.gmb-cr-title {font-size: 12px;color: #050505;}
.gmb-cr-number {font-size: 15px;color: #050505;}
.modal{z-index: 99999;}
.modal-backdrop {z-index: 9999;}

@media (max-width:1199.98px){

.gallery-map .grid-item {margin-top: 25px;}
.gmb-media-box {padding: 40px 40px 0;}
.gmb-content-box {padding: 0 40px 40px;}
}
@media (max-width:991.98px){
.map-tab-container .tab-content>.tab-pane {display: block;opacity: 1;}
.gallery-map {width: 100%;padding-left: 0;}
.gallery-map .grid-sizer, .grid-item {width: 50%;}
.gmb-media-box {padding: 30px 30px 0;}
.gmb-content-box {padding: 0 30px 30px;}
.gmb-media {margin-top: 15px;}
.map-tab-container .tab-pane+.tab-pane {margin-top: 15px;}
.ei-cat-holder{overflow-y: auto; padding-bottom: 20px;}
}
@media (max-width:767.98px){
.gmb-content-left {width: 100%;padding-right: 0;border-right: 0;border-bottom: 1px solid rgb(0 0 0 / 5%);padding-bottom: 30px;}
.gmb-content-right {width: 100%;padding-left: 0;margin-top: 30px;}
}
@media (max-width:575.98px){
.gallery-map .grid-sizer, .grid-item {width: 100%;}
.gmb-media-box {padding: 25px 25px 0;}
.gmb-content-box {padding: 0 25px 25px;}
}

/* ************************************ JULY 29 HOME PAGE START ******************************* */
.font-poppins{font-family:"Poppins",sans-serif!important;}
.page-holder-wrapper .btn {font-size: 14px;padding: 12.5px 16px;border-radius: 8px;min-width: 138px;}
.page-holder-wrapper .btn.btn-xs{font-size: 16px;padding: 14px 24px;}
.bg-gray{background-color:#F4F5F7!important;}
.page-holder-wrapper #header{background-color:#F4F5F7!important;}

/* ****************************** PAGE HERO  CONTAINER START ******************************* */
.page-hero-container {padding: 90px 0;font-family:"Poppins",sans-serif;}
.page-hero-img-1 {width: 750px;position: absolute;left: 50%;bottom:0;transform: translateX(-50%);margin-left: -615px;max-width: none;}
.page-hero-img-2 {width: 563px;position: absolute;bottom: 100px;left: 50%;transform: translateX(-50%);margin-left: 664px;filter: drop-shadow(-22px 19px 61.1px rgb(0 0 0 / 10%));max-width: none;}
.page-hero-container .container{position: relative;z-index: 4;}
.page-hero-content{width: 100%;max-width: 677px;}
.page-hero-content h5{font-size: 48px;font-weight: 600; line-height: 1.3;letter-spacing: -1px;margin-bottom: 16px;}
.page-hero-content p {letter-spacing: -0.35px;line-height: 1.5;}
.page-hero-btn-wrap {gap: 10px;width: 100%;max-width: 605px;margin: 38px auto 0;}
/* ****************************** PAGE HERO  CONTAINER END ******************************* */
/* ****************************** PAGE INTRO  CONTAINER START ******************************* */
.page-intro-container {padding: 70px 0;}
.page-intro-image {width: calc(100% + 75px);}
.page-title {font-size: 36px;line-height: 1.4;font-weight: 600;letter-spacing: -0.8px;max-width: 400px;margin-bottom: 17px;}
.page-inro-text {font-size: 14px;line-height: 1.6;letter-spacing: -0.3px;max-width: 430px;}
.page-inro-txt-wrap {padding: 0 10.6% 9% 0%;}
.page-btn{margin-top: 40px;}
/* ****************************** PAGE INTRO  CONTAINER END ******************************* */
/* ****************************** PAGE CARD  CONTAINER START ******************************* */
.page-card-container{padding: 100px 0;}
.page-card-row {border-radius: 30px;overflow: hidden;background-color: #fff;padding: 20px;}
.page-card-item+.page-card-item{margin-top: 20px;}
.page-card-item:nth-child(even) .page-card-row{flex-direction: row-reverse;}
.page-card-text {width: 50%;padding: 9% 4.4% 9% 6.5%;font-size: 14px;line-height: 1.6;letter-spacing: -0.3px;}
.page-card-image {width: 50%;padding: 6.5% 0 0%;}
.page-card-item:not(:first-child) .page-card-image{padding: 0% 2.4% 0% 4.5%;}
/* .page-card-img {width: calc(100% + 100px);max-width: none;margin-left: -9%;} */
.page-card-item .page-btn{margin-top: 0;}
.page-card-item:nth-child(even) .page-card-img{margin-left: 0%;width: 98%;}
.page-card-item:nth-child(even) .page-card-text{padding: 9% 7.4% 9% 4.5%;}
.page-card-item:nth-child(even) .page-card-image {padding: 7% 0% 7% 7%;}
/* ****************************** PAGE CARD  CONTAINER END ******************************* */
/* ****************************** RIGHT IMAGE  CONTAINER START ******************************* */
.ric-image-box {width: 50%;height: 100%;position: absolute;right: 0;top: 0;bottom: 0;}
.ric-content-box h6 {font-size: 16px;font-weight: 600;color: var(--green-color);margin-bottom: 12px;}
.ric-content-box h4 {font-size: 40px;line-height: 1.3;font-weight: 600;letter-spacing: -0.02em;margin-bottom: 18px;color: var(--dark-black);}
.ric-content-box p {letter-spacing: -0.02em;line-height: 1.5;}
.ric-content-box {width: 50%;font-size: 14px;color: var(--dark-black);min-height: 578px;padding: 100px 0;padding-right: 6%;}
.ric-btn {margin-top: 55px;}
/* ****************************** RIGHT IMAGE  CONTAINER END ******************************* */
/* ****************************** LEFT IMAGE  CONTAINER START ******************************* */
.lic-image-box {position: absolute;top: 0;left: 0;bottom: 0;width: 50%;height: 100%;}
.lic-content-box h6 {font-size: 16px;font-weight: 600;color: var(--green-color);margin-bottom: 12px;}
.lic-content-box h4 {font-size: 40px;line-height: 1.3;font-weight: 600;letter-spacing: -0.02em;margin-bottom: 18px;color: var(--dark-black);}
.lic-content-box p {letter-spacing: -0.02em;}
.lic-content-box {width: 50%;font-size: 14px;color: var(--dark-black);min-height: 600px;padding: 100px 0 80px;padding-left: 8.5%;}
.lic-btn {margin-top: 50px;}
.lic-image-hold {width: 100%;height: 100%;position: absolute;left: 0;right: 0;top: 0;bottom: 0;display: flex;align-items: flex-end;justify-content: flex-end;padding: 80px 100px 0 14%;}
.shape-2-bg {width: 100%;height: 100%;position: absolute;left: 0;right: 0;top: 0;bottom: 0;object-position: right center;}
.shape-1-bg {width: 100%;/* margin: 0 auto; */position: relative;z-index: 1;bottom: 0;height: 100%;object-fit: contain;object-position: right bottom;margin-left: auto;}
/* ****************************** LEFT IMAGE  CONTAINER END ******************************* */
/* ****************************** PAGE TEXT  CONTAINER START ******************************* */
.page-text-container {background-color: var(--dark-black);color: #fff;padding: 50px 0 35px;}
.page-text-container::after {content: '';position: absolute;top: 0;right: 0;bottom: 0;left: 0;width: 100%;height: 100%;background: rgb(255,255,255);background: linear-gradient(180deg, rgba(255,255,255,0.3897934173669467) 0%, rgba(50,50,50,0.9192051820728291) 78%, rgba(31,31,31,1) 100%);opacity: 10%;}
.ptc-content-box h6 {font-size: 16px;font-weight: 600;color: var(--green-color);margin-bottom: 16px;}
.ptc-content-box h4 {font-size: 40px;line-height: 1.3;font-weight: 600;letter-spacing: -0.02em;margin-bottom: 18px;}
.ptc-content-box { width: 45%;font-size: 14px;font-weight: 300;}
.ptc-image-box {width: 55%;padding:0 0 0 8.5%;}
.ptc-content-box p {letter-spacing: -0.02em;}
.ptc-image {width: calc(100% + 75px);}
/* ****************************** PAGE TEXT  CONTAINER END ******************************* */
/* ****************************** PAGE COMIN SOON  CONTAINER START ******************************* */
.page-coming-soon-container {color: #fff;background-position: center;background-repeat: no-repeat;background-size: cover;}
.pcsc-inside h4 {font-size: 64px;font-weight: 600;letter-spacing: -0.02em;margin-bottom: 15px;}
.pcsc-inside p {letter-spacing: -0.02em;line-height: 1.6;}
.pcsc-inside p strong {font-size: 24px;font-weight: 400;letter-spacing: -0.03em;}
.pcsc-text-box {width: 58%;}
.pcsc-inside {width: 100%;padding: 80px 0;}
.pcsc-btn {margin-top: 50px;}
.pcsc-img-box {width: 42%;    padding: 7.5% 3% 0% 10%;position: relative;}
.eggie {width: 100%;max-width: 300px;margin: 0 auto;}
.eggie img{width: 100%;height: 100%;object-fit: cover;}
.pcsc-shape-1 {width: 1014px;height: 1061px;max-width: none;position: absolute;top: 50%;transform: translate(-50%, -50%);left: 50%;}
/* ****************************** PAGE COMIN SOON  CONTAINER END ******************************* */

@media (max-width:1199.98px){
.page-intro-image {width: 100%;}
.page-title {font-size: 30px;}
.lic-content-box {padding-left: 5%;}
.pcsc-inside h4{font-size: 54px;}
.ptc-content-box h4 {font-size: 30px;}
.page-intro-container {padding: 60px 0;}
.page-card-container {padding: 80px 0;}
}
@media (max-width:991.98px){
.page-hero-container {padding: 50px 0 100px;}
.page-hero-content h5 {font-size: 38px;}
.page-hero-img-2 {left: auto;transform: none;margin-left: 0;right: -50px;width: 200px;bottom: -60px;}
.page-hero-img-1 {left: 0;transform: none;margin-left: 0;width: 200px;bottom: -30px;}
.page-title {font-size: 30px;}
.page-card-text,.page-card-item:nth-child(even) .page-card-text {width: 100%;padding: 50px 50px 20px;}
.page-card-image,.page-card-item:not(:first-child) .page-card-image {width: 100%;padding: 30px 40px 40px;}
.page-card-item:nth-child(even) .page-card-image{width: 100%;padding: 30px 50px 50px}
.page-card-row {border-radius: 12px;padding: 0;}
.page-card-img {width: 90%;max-width: none;margin: 0 auto;}
.ric-image-box, .lic-image-box {width: 100%;position: relative;}
.ric-content-box, .lic-content-box{width: 100%;min-height: auto;padding: 50px 0;}
.ric-btn,.lic-btn {margin-top: 30px;}
.pcsc-inside {padding: 50px 0 25px;}
.ptc-content-box {width: 100%;}
.ptc-image-box {width: 100%;margin-top: 25px;padding: 0;}
.ptc-image {width: 100%;max-width: 560px;margin: 0 auto;}
.eggie-img {width: 100%;max-width: 450px;margin: 0 auto 30px;}
.pcsc-img-box {padding: 0;}
.pcsc-inside p strong {font-size: 20px;}
.pcsc-btn {margin-top: 40px;}
.lic-image-box {height: 330px;}
.lic-image-hold {padding: 50px 5% 0;}
.pcsc-inside h4{font-size: 44px;}
.pcsc-img-box, .pcsc-text-box { width: 100%;}
.shape-1-bg{object-position: center;}
.page-intro-container {padding: 50px 0;}
.page-card-container {padding: 50px 0;}
}
@media (max-width:767.98px){
.page-hero-content h5 {font-size: 30px;}
.page-inro-txt-wrap {padding: 0;}
.page-inro-text{max-width: 100%;}
.page-title {font-size: 24px;}
.page-btn {margin-top: 24px;}
.page-card-text,.page-card-item:nth-child(even) .page-card-text {padding: 40px 40px 20px;}
.page-card-item:nth-child(even) .page-card-image{width: 100%;padding: 30px 40px 40px}
.ptc-content-box h4, .ric-content-box h4, .lic-content-box h4 {font-size: 28px;}
.pcsc-inside h4{font-size: 34px;}
}
@media (max-width:575.98px){
.page-hero-content h5 {font-size: 24px;}
.ptc-content-box h4, .ric-content-box h4, .lic-content-box h4 {font-size: 24px;}
.pcsc-inside h4{font-size: 24px;}
.page-card-text, .page-card-item:nth-child(even) .page-card-text {padding: 24px 24px 0px;}
.phbw-item{width: 100%;}
.page-btn .btn,.ric-btn .btn,.lic-btn .btn,.ptc-btn .btn,.pcsc-btn .btn,.phbw-item .btn{min-width: 100%!important;}
}
/* //Transaction Tab in profile // */
.transaction-content-wrapper{margin-top: 10px;}
.transaction-content-head{margin-bottom: 10px;}
.transaction-head-box {box-shadow: 0 0 36px -2px rgb(0 0 0 / 6%);border-radius: 10px;padding: 20px;color: var(--dark-black);}
.tcb-upper-head h6 {font-weight: 500;font-size: 15px;}
.tcb-upper-head .btn-edit {font-size: 12px;color: var(--green-color);text-decoration: underline;border: none;background: none;margin-left: 9px;line-height: 1;}
.tcb-upper-body {font-size: 13px;}
.tcb-upper-icon {width: 58px;height: 40px;border: 1px solid #E4E4E4;border-radius: 4PX;flex-shrink: 0;}
.tcb-upper-left {gap: 13px;}
.tcb-upper-right h6 {font-size: 12px;font-weight: 400;color: rgb(30 30 30 / 60%);margin-bottom: 6px;}
.tcb-upper-right h5 {font-size: 16px;font-weight: 500;text-align: end;}
.tcb-lower-box {flex-grow: 1;flex-basis: 0;}
.tcb-lower-box h6 {font-size: 12px;font-weight: 400;color: rgb(30 30 30 / 60%);margin-bottom: 5px;}
.tcb-lower-box h5 {font-size: 16px;font-weight: 500;}
.tcb-lower {background: rgb(71 173 29 / 6%);border-radius: 6px;padding: 15px 16px;margin-top: 12px;}
.transaction-content-list {gap: 10px;}
.transaction-content-box {box-shadow: 0 0 36px -2px rgb(0 0 0 / 6%);border-radius: 10px;padding: 20px;color: var(--dark-black);}
.tcb-left h5 {font-size: 15px;font-weight: 500;margin-bottom: 6px;line-height: 1;}
.tcb-left h6 {font-size: 12px;line-height: 1;font-weight: 400;}
.tcb-right {font-size: 22px;font-weight: 600;color: var(--black);}
/* =====================================TAB END HERE ================================ */

@media (max-width: 575.98px){
.tcb-upper-head h6 {font-size: 12px;}
.tcb-upper {gap: 10px;flex-flow: column;}
.tcb-upper-right h5 {font-size: 12px;text-align: start;}
.tcb-lower {flex-flow: column;gap: 10px;}
.tcb-lower-box h5 {font-size: 14px;}
.tcb-right {font-size: 18px;}
}
/* Sponsor Page */
.sponsored-container h1 {font-size: 36px;line-height: 54px;font-weight: 600; color: #0F1419;margin-bottom: 30px;}
.sponsored-text-box {font-size: 13px;line-height: 1.4;font-weight: 300;}
.sponsored-details {background: #47AD1D0F;padding: 16px;border-radius: 4px;overflow: hidden;    margin-bottom: 21px;}
.sd-img-box {width: 38%;border-radius: 10px;overflow: hidden;}
.sd-img-box img {width: 100%;height: 100%;object-fit: cover;}
.sd-text-box h2 {font-size: 14px;line-height: 21px;font-weight: 500;color: #FF4242;margin-bottom: 14px;font-family: "IBM Plex Sans", sans-serif;}
.sd-text-box {padding: 33px 12px 32px 40px;width: 62%;}
.sd-text-box h3 {font-size: 24px;line-height: 36px;color: #1E1E1E;font-weight: 600;margin-bottom: 20px;}
.sd-text-box h3 img {width: 34px;margin-left: 6px;}
.badge-holder{gap: 6px;}
.badge.green-badge {padding: 6px 8px;background: #47AD1D1A;border-radius: 10px;color: #47AD1D;font-family: "IBM Plex Sans", sans-serif;line-height: 1;margin: 0;}
.sponsor-form-box {padding: 40px 30px;box-shadow: 0 4px 49.5px 0 rgb(0 0 0 / 6%);border-radius: 7px;}
.float-badge-holder {width: 100%;position: absolute;top: 30px;display: flex;align-items: center;gap: 5px;padding-right: 60px;}
.sponsored-container .ff-change-link input#floatingfile {width: 100%;position: absolute;left: 0;right: 0;top: 0;bottom: 0;opacity: 0;}
.ffr-logo-area {width: 60px;height: 60px;border-radius: 6px;border: 1px solid #E4E4E4;overflow: hidden;}

@media (max-width:1199.98px){
.rf-head-row h1{font-size: 28px;}
.fwm-content-left {padding: 0 0px 0 40px;}
.sponsored-container h1 {font-size: 30px;}
.sd-text-box {padding: 24px 12px 24px 24px;}
.sd-text-box h3 {font-size: 20px;}   
}
@media (max-width: 575.98px){
.sd-img-box {width: 100%;}
.sponsor-form-box {padding: 28px 20px;}
.sponsor-form-box .ff-column {margin-bottom: 20px;}
}

.pub-icon-box {width: 56px;height: 56px;background: #474747;border-radius: 12px;}
.pub-text-box h6 {font-size: 15px;line-height: 20px;font-weight: 600;margin-bottom: 0;color: #1E1E1E;}
.pub-text-box {font-size: 12px;line-height: 16px;font-weight: 400;}
@media (max-width: 575.98px){
.w-body-content {padding: 15px 15px 15px;}
.pub-icon-box {width: 45px;height: 45px;border-radius: 8px;}
}


.sustainability-content-container{padding: 80px 0;}
.sustainability-hero-container{position: relative;height: 477px;}
.sustainability-hero-container .container{position: relative;z-index: 4;}
.vc-bg{width: 100%;height: 100%;position: absolute;left: 0;right: 0;top: 0;bottom: 0;}
.vc-bg img{width: 100%;height: 100%;object-fit: cover;}
.login-link-buttom {font-size: 14px;line-height: 1;font-weight: 500;color: #47AD1D;margin-right: 22px;}
.sustainability-hero-logo {width: 164px;height: 164px;border-radius: 20px;overflow: hidden;background-color: #fff;}
.sustainability-hero-logo img {width: 100%;height: 100%;object-fit: contain;}
.sustainability-hero-text h1 {font-size: 36px;line-height: 1.4;color: #fff;font-weight: 600;letter-spacing: -0.02em;margin-bottom: 0;}
.sustainability-hero-content {gap: 40px;padding: 0 0 60px 40px;}
.sustainability-intro-content {font-size: 18px;line-height: 28px;}
.dropdown-holder .dropdown.custom-dropdown {position: relative;width: auto;height: auto;top: auto;right: auto;opacity: 1;backdrop-filter: none;border-radius: 0;background: none;}
.dropdown-holder .dropdown.custom-dropdown .dropdown-menu{min-width:none!important;width: auto;}
.sponsored-species-body {gap: 65px;}
.sponsored-species-head {margin-bottom: 36px;}
.ss-left h2 {font-size: 36px;line-height: 54px;font-weight: 600;margin-bottom: 0;}
.ss-list {gap: 30px;}
.ss-box {font-size: 12px;line-height: 1;letter-spacing: -0.02em;max-width: 46px;gap: 10px;}
.ss-img {width: 46px;height: 46px;border-radius: 50%;overflow: hidden;}
.ss-img img {width: 100%;height: 100%;object-fit: cover;}
.species-profile-widget {gap: 24px;}
.species-profile-overview {width: 274px;}
.species-profile {width: 100%;background: #fff;border-radius: 10px;overflow: hidden;}
.species-profile-img {position: relative;padding-bottom: 73%;border-radius: 10px;overflow: hidden;box-shadow: -20px 4px 90px -2px rgb(0 0 0 / 6%);}
.species-profile-img > img {width: 100%;height: 100%;position: absolute;left: 0;right: 0;top: 0;bottom: 0;object-fit: cover;}
.species-profile-name {width: 100%;gap: 16px;border-top: 1px solid rgb(0 0 0 / 10%);padding: 20px 0 32px;}
.species-profile-details {padding: 30px 20px 20px 20px;font-size: 14px;line-height: 21px;}
.species-profile-details h3 {font-size: 24px;line-height: 1;letter-spacing: -0.015em;font-weight: 600;margin-bottom: 12px;}
.species-profile-details p{margin-bottom: 12px;}
.npm-name-box {font-size: 14px;line-height: 1;font-weight: 500;color: #FF4242;}
.spn-icon-box {width: 36px;}

.species-profile-bottom {padding: 0px 20px;}

.species-profile-description{gap: 10px;}
.description-pannel-box {font-size: 15px;line-height: 22px;color: #050505;background-color: #fff;border-radius: 10px;padding: 24px;}
.description-pannel-header{position: relative;padding-right: 35px;margin-bottom: 10px;}
.description-pannel-header h4 {font-size: 18px;line-height:1.5;font-weight: 600;margin-bottom: 0;}
.dropdown-holder {position: absolute;right: 0;top: 0;}
.ellipsis-dots {width: 25px;height: 26px;display: flex;align-items: center;justify-content: center;}
.description-pannel-box p:not(:last-child) {margin-bottom: 10px;}
.viewmore-post-btn .btn{min-width: 100%!important;}

#footerBar{border-top: 1px solid rgb(0 0 0 / 10%);background-color: #fff;}
#footerBar .container {max-width: 1264px;}
#footerBar .footer-upper-bar {padding: 40px 0 30px;font-size: 14px;line-height: 22px;color: rgb(0 0 0 / 70%);}
#footerBar .footer-bar-logo {width: 193px;}
#footerBar .footer-lower-bar-content {font-size: 13px;line-height: 18px;color: rgb(30 30 30 / 70%);}
#footerBar .footer-lower-bar {border-top: 1px solid rgb(0 0 0 / 10%);padding: 30px 0 40px;}
#footerBar .footer-nav li a {color: rgb(30 30 30 / 70%);}
#footerBar .footer-nav li a:hover{color: #47AD1D;}
#footerBar .footer-nav {gap: 30px;}

@media (max-width: 991.98px){
.sustainability-content-container {padding: 40px 0;}
.sponsored-species-body {gap: 32px;}
}
@media (max-width: 767.98px){
.login-link-buttom{margin-right: 0;}
.sustainability-hero-content {gap: 30px;padding: 0 0 30px 30px;}
.sustainability-hero-logo {width: 100px;height: 100px;border-radius: 10px;}
.sustainability-hero-text h1 {font-size: 26px;}
.species-profile-overview {width: 100%;}
#footerBar{text-align: center;}
#footerBar .footer-bar-logo {width: 160px;}
.ss-left h2 {font-size: 30px;line-height: 1.2;}
}

/*Environemntal Press crop Page */

.container.container1 {max-width: 1264px;}
.heading-wrapper {padding: 60px 0 80px;}
.hw-title h1 {font-size: 48px;line-height: 1.5;margin-bottom: 0;text-transform: uppercase;color: #47AD1D;}
.hw-title h1>span {padding:0 40px;display: inline-block;position: relative;}
.hw-title h1>span:before {content: '';width: 50vw;height: 3px;background: #47AD1D;position: absolute;top: 50%;transform: translateY(-50%);right: 100%;}
.hw-title h1>span:after {content: '';width: 50vw;height: 3px;background: #47AD1D;position: absolute;top: 50%;transform: translateY(-50%);left: 100%;}

.hw-subtitle h2 {font-size: 24px;line-height: 1.5;margin-bottom: 0;font-weight: 400;color: #1E1E1E;}
.hw-subtitle h2>span {padding:0 40px;display: inline-block;position: relative;}
.hw-subtitle h2>span:before {content: '';width: 50vw;height: 18px;background: rgb(71,173,29);background: linear-gradient(90deg, rgba(71,173,29,1) 0%, rgba(71,173,29,0) 100%);position: absolute;top: 50%;transform: translateY(-50%);right: 100%;}
.hw-subtitle h2>span:after {content: '';width: 50vw;height: 18px;background: rgb(71,173,29);background: linear-gradient(90deg, rgba(71,173,29,0) 0%, rgba(71,173,29,1) 100%);position: absolute;top: 50%;transform: translateY(-50%);left: 100%;}

.default-heading h2 {font-size: 24px;line-height: 1.5;font-weight: 600;color: #47AD1D;display: inline-block;margin-bottom: 0;position: relative;padding-bottom: 15px;}
.default-heading h2:before {content: '';width: 137px;height: 2px;background: #47AD1D;position: absolute;bottom: 0;left: 0;}
.default-cta-row {margin-top: 40px;}
.default-cta-content h3 {font-size: 32px;line-height: 1.5;font-weight: 600;margin-bottom: 0;}

.default-image-box {width: 327px;}
.default-intro-row {margin-top: 30px;}
.dib-hold {width: 100%;height: 100%;position: relative;padding-bottom: 102%;border-radius: 20px;overflow: hidden;}
.dib-hold img {width: 100%;height: 100%;position: absolute;left: 0;right: 0;top: 0;bottom: 0;object-fit: cover;}
.default-image-text,.dmr-text-box {font-size: 14px;line-height: 1.6;letter-spacing: -0.02em;}
.default-image-text p:not(:last-child) {margin-bottom: 20px;}
.default-map-row,.default-card-list-row {margin-top: 40px;}
.dmr-map-box {max-width: 625px;margin-left: auto;}
.dmr-map {width: 100%;height: 100%;padding-bottom: 57%;position: relative;}
.dmr-map .insider-map {width: 100%;height: 100%;position: absolute;left: 0;right: 0;top: 0;bottom: 0;object-fit: cover;}

.default-card-list {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-bottom: -30px;
}
.default-card-item{width:33.33%;padding:0 15px;margin-bottom:30px;}

.default-card-bottom {border-top: 1px solid rgb(0 0 0 / 10%);height: 64px;}
.d-button-link-icon{width: 24px;height: 24px;display: flex;align-items: center;justify-content: center;}
.default-card-img {width: 100%;position: relative;padding-bottom: 62.5%;}
.default-card-img img {width: 100%;height: 100%;position: absolute;left: 0;right: 0;top: 0;bottom: 0;object-fit: cover;}
.default-card-content {padding: 20px;color: #1A1A1A;font-size: 14px;line-height: 1.5;}
.default-card-content h3 {font-size: 20px;line-height: 1.5;font-weight: 600;margin-bottom: 7px;}
.default-card-date {font-size: 14px;line-height: 1;}
.default-card-box {width: 100%;height: 100%;box-shadow: 0 2px 30px 0 rgb(42 42 42 / 15%);border-radius:20px;}
.default-card-bottom{padding:20px 10px;}
.d-button-link-item {position: relative;padding: 0 10px;}
/* .d-button-link-item:not(:last-child):before {content: '';width: 1px;height: calc(100% - 10px);position: absolute;right: 0;top: 50%;transform: translateY(-50%);background: currentColor;} */
.v-line{width: 1px;height: 15px;background:currentColor;}
.default-card-content p:not(:last-child) {margin-bottom: 15px;}
.default-card-btn {margin-top: 16px;}
.readmore-link {font-family: "Poppins", sans-serif;font-size: 14px;line-height: 1;color: #47AD1D;font-weight: 600;}
.readmore-link:hover{text-decoration:underline;}
.d-button-link-icon {position: relative;}.red-heart-icon,.t-heard-icon {width: auto;position: absolute;left: 0;right: 0;top: 0;bottom: 0;}
.red-heart-icon{opacity:0;}
.liked .red-heart-icon{opacity:1;}
.liked .t-heard-icon{opacity:0;}
.content-container.default-bottom-container {padding-top: 40px;}
.default-bottom-content h4 {font-size: 24px;font-weight: 400;line-height: 1.5;margin-bottom: 0;}
.default-bottom-button-group{width:100%;max-width:420px;margin:0 auto;}
.default-bottom-button-group .btn+.btn{margin-left:0;}
.default-bottom-button-group a {width: auto;flex-grow: 1;flex-basis: 0;}

.inner-hero-container { position: relative; display: flex; align-items: center; padding-top: 90px; padding-bottom: 140px; }
.inner-hero-container .container { position: relative; z-index: 99; }
.inner-hero-bg { position: absolute; top: 0; height: 100%; right: 0; left: 0; bottom: 0; width: 100%; object-fit: cover; background-position: center; background-repeat: no-repeat; background-size: cover; }
.inner-hero-bg>img { width: 100%; height: 100%; object-fit: cover; }
.inner-hero-bg::after {content: '';position: absolute;left: 0;right: 0;bottom: 0;top: 0;width: 65%;height: 100%;background: linear-gradient(260deg, rgba(241,199,146,0) 2%, rgba(167,144,117,1) 49%, rgba(145,127,108,1) 100%);}
.inner-header h3 { font-size: 64px; line-height: 1.2; font-weight: 600; color: #fff; letter-spacing: .02em; margin-bottom: 7px; }
.inner-header { font-size: 18px; color: #fff; }

.contact-banner { display: flex; gap: 20px; padding: 0 20px; margin-top: -135px; }
.contact-banner-left { width: 100%; padding: 24px; border-radius: 10px; background-color: #fff; filter: drop-shadow(0px 4px 54px rgba(0, 0, 0, 0.05)); }
.contact-banner-right { width: 100%; padding: 24px; border-radius: 10px; background-color: #fff; filter: drop-shadow(0px 4px 54px rgba(0, 0, 0, 0.05)); }
.cb-list { display: flex; flex-direction: column; gap: 20px; }
.cb-box { display: flex; gap: 20px; }
.cb-box-icon { width: 30px; height: 30px; flex-shrink: 0; }
.cb-box-text { word-break: break-all; }
.cb-box-text h6 { font-size: 18px; font-weight: 600; margin-bottom: 5px; }

.contact-head-row+.contact-form-row-group {margin-top: 48px;}
.contact-form-row-group { display: flex; flex-direction: column; gap: 20px; }
.contact-head-row h2 {color: #1E1E1E;font-size: 32px;font-weight: 600;margin-bottom: 0;}
.contact-content-holder {padding: 35px 50px;border-radius: 10px;background-color: #fff;filter: drop-shadow(0px 4px 54px rgba(0, 0, 0, 0.05));}
.contact-intro-wrapper { padding-right: 25px; }
.contact-intro { padding: 40px; border-radius: 10px; background-color: #fff; filter: drop-shadow(0px 4px 54px rgba(0, 0, 0, 0.05)); }
.contact-intro-img {margin-bottom: 30px;position: relative;padding-bottom: 119%;}
.contact-intro-img img {width: 100%;height: 100%;object-fit: cover;position: absolute;top: 0;left: 0;right: 0;bottom: 0;}
.ci-about h6 { font-size: 24px; font-weight: 600; margin-bottom: 5px; }
.ci-mail { display: flex;  padding-top: 30px; gap: 20px; }
.ci-mail-icon { width: 30px; height: 30px; flex-shrink: 0; }
.ci-mail-text h6 { font-size: 18px; font-weight: 600; margin-bottom: 5px; }
.ci-mail-text { word-break: break-all; }

.cf-form-btn .btn.btn-lg {min-width: 378px;}
@media (max-width:1199.98px){
.contact-intro-wrapper { padding-right: 0; }
.inner-header h3 { font-size: 47px; }
.contact-banner { padding: 0;}
.contact-intro { padding: 20px; }
.ci-mail { padding-top: 0; }
.ci-mail-text h6 { font-size: 16px; }
.ci-mail-text { font-size: 14px; }
.ci-mail-icon { width: 25px; height: 25px; }
.contact-head-row h2 { font-size: 28px; }
.contact-content-holder { padding: 20px 30px;}
}

@media (max-width:991.98px){
.contact-banner { flex-direction: column; padding: 0; margin-top: -100px;}
.cb-box-icon { width: 25px; height: 25px; }
.cb-box-text h6 { font-size: 16px; }
.cb-box-text { font-size: 14px; }
.contact-content-holder { margin-top: 20px;}
.contact-intro-img { max-width: 500px; margin: 0 auto 30px; padding: 0; }
.contact-intro-img img { position: relative;}
.ci-about h6 { font-size: 22px; margin-bottom: 2px; }
}
@media (max-width:767.98px){
.inner-header h3 { font-size: 40px; }
.contact-banner-left { padding:20px;}
.psw-outer-wrapper { flex-direction: column; }
.psw-btn-wrapper.d-flex { width: 100%; }
.psw-btn, .psw-btn a { width: 100%; }
.psw-outer-wrapper .psw-search { width: 100%; }
.contact-head-row+.contact-form-row-group { margin-top: 30px; }
.psw-search-select {width: 84px;}

}

@media (max-width:575.98px){
.contact-head-row h2 { font-size: 24px; }
.contact-content-holder { padding: 20px; }
.cf-form-btn .btn.btn-lg {min-width: 100%;}
.psw-btn-wrapper.d-flex {flex-flow: column;}
.psw-btn-wrapper{height:auto;}
}

/* =====================================  Intern Report Page Start ============================== */
.intern-program-container {padding: 40px 0;}
.hw-title h1 span em {font-weight: 500;font-style: normal;}
.professor-details-box {padding: 40px;font-size: 20px;line-height: 1.5;}
.professor-details-box h5 {font-weight: 600;font-family: "IBM Plex Sans", sans-serif;margin-bottom: 30px;}
.professor-details-box li a {color: var(--green-color);text-decoration: underline;}
.professor-details-box li a:hover{text-decoration: none;}
.professor-details-box p:not(:last-child) {margin-bottom: 0;}
.professor-details-box p + ol {margin-top: 30px;}
.professor-details-box ol {margin-bottom: 0;padding-left: 28px;}

.grid-content-row + .grid-content-row {margin-top: 60px;padding-top: 60px;border-top: 1px solid #000;}
.gc-title h4 {position: relative; font-size: 28px;line-height: 1.2; margin-bottom: 16px;padding-bottom: 17px;color: #1A1A1A;}
.gc-title h4:before {content: '';width: 98px;height: 4px;background: var(--green-color);position: absolute;bottom: 0;left: 0;}
.gc-title h4 span {font-weight: 400;}
.gc-intro-content {gap: 25px;}
.gc-intro-img {width: 258px; height: auto;}
.gc-intro-details-list {gap: 12px;}
.gc-intro-details-item strong {font-weight: 600;}
.gc-intro-details-item {line-height: 1.2;color: #1A1A1A;font-family: "Poppins", sans-serif;}
.gc-detail-wrapper {display: flex; gap: 40px; }
.gc-detail-left { width: 215px; flex-shrink: 0; }
.gc-detail-right { width: calc(100% - 215px); flex-grow: 1;color: #1A1A1A;}
.gc-detail-left-img img { width: 100%; height: 100%; object-fit: cover; position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
.gc-detail-left-img { position: relative; padding-bottom: 100%; border-radius: 25px; overflow: hidden; }
.gc-detail-right-head { margin-bottom: 26px; }
.gc-detail-right-head h4 { font-size: 28px; font-weight: 600; margin-bottom: 0; line-height: 1.25; }
.gc-detail-text { font-size: 14px; min-width: 153px;}
.gc-detail-text h6 { font-size: 18px; font-weight: 500; line-height: 1.2; margin-bottom: 8px; font-family: 'IBM Plex Sans';}
.gc-detail-text p { margin-bottom: 0px; }
.gc-detail-right-lower { gap: 45px; }
.gc-detail-lower-right { gap: 30px; }
.gc-detail-lower-left { display: flex; flex-direction: column; gap: 28px;}
.gc-detail-lower-col { display: flex; flex-direction: column; gap: 28px;}
.gc-separator { margin: 40px 0; height: 1px; background: #e4e4e4; }
.gc-header-row {margin-bottom: 40px;}
.gc-heading h3 {font-size: 32px;font-weight: 600;margin-bottom: 0;padding-bottom: 16px;position: relative;color: var(--green-color);}
.gc-heading h3:before {content: '';width: 137px;height: 3px;background: var(--green-color);position: absolute;bottom: 0;left: 0;}
.grid-logo-box {gap: 25px;}
.glb-img {width: 106px;height: 106px;flex-shrink: 0;border-radius: 19px;box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);overflow: hidden;}
.glb-content {max-width: 240px;}
.glb-content h5 {font-size: 20px;font-weight: 500;color: #1A1A1A;margin-bottom: 10px;line-height: 1.4;}
.read-btn {font-size: 14px;font-weight: 300;color: #227300;text-decoration: underline;line-height: 1;}
.grid-logo-list {width: calc(100% + 30px);margin-left: -15px;  margin-bottom: -30px;}
.grid-logo-item {width: 33.33%;padding: 0 15px;margin-bottom: 30px;}    
.member-list .glb-img {border-radius: 50%;}
.gc-heading-line h3::before {width: 56px;}
.green-dot-list {column-gap: 50px;row-gap: 20px;}
.green-dot-item {padding-left: 22px;font-size: 20px;line-height: 1.4;color: #1A1A1A;font-family: "Poppins", sans-serif;}
.green-dot-item span {font-weight: 500;}
.green-dot-item::before {content: '';position: absolute;top: 7px;left: 0;width: 12px;height: 12px;background: #227300;border-radius: 50%;}
.gc-card-list {width: calc(100% + 30px);margin-left: -15px;margin-bottom: -24px;}
.gc-card-item {width: 50%;padding: 0 15px;margin-bottom: 24px;}  
.gc-card-box {border-radius: 10px;overflow: hidden;box-shadow: 0 2px 30px 0 rgb(42 42 42 / 30%);}
.gc-img-box {position: relative;padding-bottom: 42%;}
.gc-img-box img {position: absolute;top: 0;right: 0;bottom: 0;left: 0;}
.gc-content-box {padding: 20px;font-size: 14px;color: #1A1A1A;line-height: 1.4;}
.gc-content-box h5 {font-size: 20px;font-weight: 600;margin-bottom: 8px;}
.gc-content-box p {overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;margin-bottom: 12px;}
.gc-link-group {gap: 16px;line-height: 24px;}
.gc-link {text-decoration: underline;font-family: "Poppins", sans-serif;}
.gc-divider {width: 1px;height: 14px;background: rgb(26 26 26 / 70%);}
.tc-list {width: calc(100% + 40px);margin-left: -20px;margin-bottom: -24px;}   
.tc-item {width: 33.33%;padding: 0 20px;margin-bottom: 24px;} 
.tc-heading h4 {font-size: 24px;font-weight: 600;color: #1A1A1A;margin-bottom: 20px;}
.tc-content-box {border: 1px solid  var(--green-color);border-radius: 10px;padding: 38px 30px;}
.tc-box {color: #1A1A1A;}
.tc-box h5 {font-size: 24px;font-weight: 600;line-height: 1;color: var(--green-color);margin-bottom: 25px;}
.small-dot-list {gap: 12px;}
.small-dot-item {font-size: 14px;line-height: 1.4;padding-left: 18px;}
.small-dot-item::before {content: '';position: absolute;top: 5px;left: 0;width: 8px;height: 8px;background: var(--green-color);border-radius: 50%;}
.gc-bottom-wrapper p strong {font-weight: 600;}
.gc-bottom-wrapper {font-size: 20px;line-height: 1.5;}
.gc-btn-group {margin-top: 60px;}
@media (max-width:1199.98px){
.gc-detail-wrapper { padding: 0; gap: 25px; }
.gc-detail-right-lower { gap: 20px; }
.gc-detail-lower-right { gap: 20px; }
.glb-img {width: 80px;height: 80px;}
.glb-content h5 {font-size: 16px;}
.grid-logo-box {gap: 15px;}
.green-dot-list {column-gap: 40px;}
.tc-box h5 {font-size: 20px;}
}  
@media (max-width:991.98px){
.professor-details-box {padding: 30px;}
.grid-content-row + .grid-content-row {margin-top: 40px;padding-top: 40px;}
.gc-intro-content { padding: 0;}
.gc-intro-img {width: 220px;}
.gc-title h4 {font-size: 24px;}
.gc-detail-right-lower {flex-direction: column; gap: 30px;}
.gc-detail-lower-left {display: flex;flex-direction: row;}
.gc-detail-lower-left .gc-detail-text { width: 50%; }
.gc-detail-lower-col {width: 33.33%;}
.gc-detail-wrapper {flex-direction: column;}
.gc-detail-left {width: 100%;}
.gc-detail-right {width: 100%;}
.gc-detail-left-img {padding: 0;max-width: 300px;}
.gc-detail-left-img img {position: relative;}
.grid-logo-item {width: 50%;}
.gc-heading h3 {font-size: 28px;}
.green-dot-list {column-gap: 30px;}
.gc-content-box {padding: 15px;}
.gc-content-box h5 {font-size: 18px;}
.tc-content-box {padding: 25px;}
.tc-list {width: calc(100% + 24px);margin-left: -12px;}   
.tc-item {width: 50%;padding: 0 12px;} 
.tc-box h5 {margin-bottom: 15px;}
.gc-bottom-wrapper {font-size: 16px;}
.gc-btn-group {margin-top: 40px;}
.gc-card-list {width: calc(100% + 24px);margin-left: -12px;}
.gc-card-item {padding: 0 12px;}
}
@media (max-width:767.98px){
.professor-details-box {padding: 20px;font-size: 16px;}
.professor-details-box h5 {margin-bottom: 20px;}
.professor-details-box p + ol {margin-top: 20px;}
.gc-title h4 {font-size: 20px;}
.gc-detail-right-head {margin-bottom: 20px;}
.gc-detail-right-head h4 {font-size: 24px;}
.gc-detail-lower-left .gc-detail-text {width: 100%;}
.gc-detail-lower-left {flex-direction: column;gap: 15px;}
.gc-detail-lower-right {flex-direction: column; gap: 25px;}
.gc-detail-right-lower {gap: 25px;}
.gc-detail-lower-col {width: 100%;gap: 15px;}
.glb-content {max-width: 100%;}
.glb-img {width: 60px;height: 60px;}
.gc-heading h3 {font-size: 24px;}
.green-dot-item {width: 100%;font-size: 18px;}
.gc-card-item {width: 100%;padding: 0 12px;}
.tc-heading h4 {font-size: 20px;}
.tc-item {width: 100%;}
.tc-box h5 {font-size: 18px;}
}
@media (max-width:575.98px){
.gc-intro-content { flex-direction: column;}
.grid-logo-list {margin-bottom: -20px;}
.grid-logo-item {width: 100%;margin-bottom: 20px;}
.gc-btn-group .btn {width: 100%;margin-left: 0;}
.gc-btn-group {margin-top: 30px;gap: 12px;}
.gc-header-row {margin-bottom: 30px;}
.grid-content-row + .grid-content-row {margin-top: 30px;padding-top: 30px;}
}
@media (max-width:389.98px){
.professor-details-box {padding: 15px;}
.professor-details-box ol {padding-left: 18px;}
.gc-title h4 {font-size: 18px;}
.gc-heading h3 {font-size: 20px;}
.gc-link-group {gap: 10px;}
.tc-content-box {padding: 15px;}
.green-dot-list {row-gap: 15px;}
}
/* ========================================= Intern Report Page End ======================================== */

/* ====================================== Volunteer Css Start =============================== */
#volunteerHeader {color: #1e1e1e;background: var(--bs-white);position: fixed;left: 0;top: 0;right: 0;padding: 0;z-index: 9999;transition: all 0.2s ease-in;-webkit-transition: all 0.2s ease-in;display: flex;align-items: center;}
.fixed #volunteerHeader {background: var(--bs-white);box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);}
.vh-inside {height: 80px;display: flex;align-items: center;justify-content: center;}

.volunteer-page-wrapper { background-color: #f4f5f7; }
.volunteer-detail {padding: 0 24px;display: flex;flex-direction: column;gap: 40px;}
.volunteer-detail-upper { font-size: 20px; line-height: 1.5; }
.volunteer-detail-upper p a { color: var(--green-color); text-decoration: underline; }
.volunteer-detail-upper p a:hover{text-decoration:none;}
.vd-separator { margin: 40px 0; height: 1px; background: #e4e4e4; }
.tick-icon-list { display: flex; flex-direction: column; gap: 20px; }
.ti-item { position: relative; padding-left: 44px; font-size: 20px; line-height: 1.4; font-family: 'Poppins'; }
.ti-item:after { content: ''; position: absolute; left: 0; top: 0; width: 24px; height: 24px; display: block; background: url(../images/tick-icon.svg) no-repeat center / 13px 9px; border-radius: 50%; background-color: var(--green-color); }
.reporting-content { padding: 40px 78px; background-color: #fff; }
.reporting-content-head { margin-bottom: 35px; }
.reporting-content-head h4 { font-size: 26px; margin-bottom: 0; }
.rc-check-list { gap: 30px; }
.rc-check-item .custom-form-check { font-size: 16px; line-height: 1.5; }
.rc-check-item { padding: 7px; }
.rc-check-item .custom-form-check input[type="checkbox"]+.form-check-label::before { width: 25px; height: 25px; }
.rc-check-item .custom-form-check input[type="checkbox"]+.form-check-label::after { width: 25px; height: 25px; background: url(../images/checkbox-tick-icon.svg) no-repeat center / 15px 10px; }
.rc-check-list+.rc-form { margin-top: 60px;}
.rc-form-left { width: 45%;}
.rc-form-img { width: 100%; height: 100%;}
.rc-holder { position: absolute; width: 58.3%; height: 68%; border-radius: 15px; overflow: hidden; }
.rc-holder-1 { top: 0; left: 0; z-index: 1; }
.rc-holder-2 { bottom: 36px; right: 0; z-index: 3; }
.rc-form-right { width: 55%; padding-left: 9.2%; }
.rc-form-row-group .custom-form-floating.form-floating > label { font-size: 15px; line-height: 1.5; font-weight: 500; font-family: 'poppins';}
.rc-form-row-group .custom-form-floating.form-floating .form-select { font-size: 20px; line-height: 1.3; height: 73px;}
.rc-form-row-group .form-floating-row .ff-column { margin-bottom: 40px;}
.rc-from-btn+.rc-from-btn { margin-top: 20px; }
@media (max-width:991.98px){
.volunteer-detail-upper { font-size: 18px; }
.ti-item { font-size: 16px; }
.vd-separator { margin: 30px 0; }
.reporting-content-head h4 { font-size: 22px; }
.reporting-content { padding: 30px; }       
}
@media (max-width:767.98px){
.reporting-content { padding: 20px; }
.volunteer-detail { padding: 0; gap: 25px; }
.vd-separator { margin: 25px 0; }
.volunteer-detail-upper { font-size: 16px; }
.ti-item { font-size: 14px; }
.reporting-content-head h4 { font-size: 20px; }
.reporting-content-head { margin-bottom: 15px; }
.rc-form { flex-direction: column; }
.rc-form-left { width: 100%; margin-bottom: 40px;}
.rc-holder-2 { bottom: 0; }
.rc-form-right { width: 100%; padding-left: 0;}
.rc-form-img { height: 380px; max-width: 470px; margin: 0 auto; }
.rc-check-item .custom-form-check { font-size: 14px; }
.rc-check-list { gap: 10px; }
.rc-check-item .custom-form-check input[type="checkbox"]+.form-check-label::before { width: 20px; height: 20px; }
.rc-check-item .custom-form-check input[type="checkbox"]+.form-check-label::after { width: 20px; height: 20px; background: url(../images/checkbox-tick-icon.svg) no-repeat center / 11px 8px; }
.rc-form-row-group .custom-form-floating.form-floating > label { font-size: 13px; }
.rc-form-row-group .custom-form-floating.form-floating .form-select { font-size: 16px; height: 60px; }
}
/* =========================================== Volunteer Css End ========================== */
/* CHAT START */
.divider {width: 1px;height: 16px;background: rgb(0 0 0 / 10%);}
.notify-toggler-icon {width: 20px;height: 20px;display: flex;align-items: center;justify-content: center;cursor:pointer;}
.notify-count {width: 16px;height: 16px;background: #FF4242;border-radius: 50%;color: #fff;font-size: 10px;line-height: 12px;position: absolute;right: -5px;top: -5px;z-index: 9;border: 2px solid #fff;display: flex;align-items: center;justify-content: center;font-weight: 700;}
.panel-sidebar-right .panel-close-button {top: 10px !important;transform: none !important;left: 19px;}
.panel-sidebar-right .rcp-heading-row .panel-close-button {top: 0px !important;left: 0px;}
.recent-chat-pannel{border-radius: 10px;box-shadow: 0 0 36px -2px rgb(0 0 0 / 6%);height: calc(100vh - 200px);}
.rcp-head {padding: 24px 18px 8px;}
.rcp-heading-label {font-size: 16px;line-height: 1.2;font-weight: 600;}
.chat-search-pannel {width: 100%;background: #F5F5F5;border-radius: 6px;position: relative;}
.chat-search-pannel .form-control-feedback {width: 18px;position: absolute;left: 10px;top: 50%;transform: translateY(-50%);}
.chat-search-input {border: 0;background: transparent;padding: 8px 20px 8px 38px;font-weight: 400;font-size: 16px;line-height: 1.5;color: #1A1A1A;width: 100%;font-family: "IBM Plex Sans", sans-serif;}
.chat-search-input::placeholder {color: rgb(26 26 26 / 70%);}.rcp-heading-btn .btn-read-more {font-size: 12px;}
.lb-member-item{position:relative;}
.lb-member-item:hover:before{content:none!important;}
.lb-member-item:not(:last-child):before {content: '';width: 100%;height: 1px;background: rgb(228 228 228 / 40%);position: absolute;bottom: 0;left: 0;right: 0;}
.lb-member-status {font-weight: 500;font-size: 12px;line-height: 14px;text-align: right;color: #000000;opacity: 0.5;position: absolute;right: 9px;top: 12px;}
.lb-member-box {position: relative;padding: 11px 10px;border-radius: 6px;cursor: pointer;font-family: "IBM Plex Sans", sans-serif;}
.lb-member-img {width: 44px;height: 44px;border-radius: 50%;margin-right: 20px;}
.lb-member-img > img {width: 100%;height: 100%;object-fit: cover;overflow: hidden;border-radius: 50%;}
.lb-member-text {width: calc(100% - 70px);padding: 3px 0 3px;}
.lb-member-text h5 {font-weight: 500;font-size: 16px;line-height: 1.2;margin-bottom: -5px;font-family: "IBM Plex Sans", sans-serif;}
.lb-member-intro p {font-weight: 400;font-size: 14px;line-height: 16px;color: #000000;opacity: 0.6;margin-top: 10px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin-bottom: 0;}
.lb-member-item+.lb-member-item {margin-top: 8px;}
.lb-member-intro p {width: calc(100% - 25px);padding-right: 10px;}
.lb-member-box.active .lb-member-intro p {color: #47AD1D;}
.lb-member-box .list-count-text {bottom: 9px;right: 9px;}
.lb-member-intro span {background: #47AD1D;border-radius: 50%;display: block;font-weight: 500;font-size: 12px;line-height: 14px;color: #fff;font-family: 'Poppins';width: 19px;height: 19px;display: flex;align-items: center;justify-content: center;margin: 10px 0 0 6px;}
.lb-member-box:hover{background: rgb(245 245 245 / 80%);}
.lb-member-box.active  .lb-member-intro p{font-weight:600;}
.rcp-body {padding: 0 8px 18px;height: calc(100% - 112px);overflow-y: auto;}
.chatbot-wrapper {height: calc(100vh - 200px);border-radius: 10px;box-shadow: 0 0 36px -2px rgb(0 0 0 / 6%);background: #fff;}
.chatbot-header {border-bottom: 1px solid #f5f5f5;padding: 14px 20px;height: 73px;}
.chatbot-member-box{gap:20px}.chatbot-member-img {width: 44px;height: 44px;border-radius: 50%;overflow: hidden;flex-shrink:0;}
.chatbot-member-img > img {width: 100%;height: 100%;object-fit: cover;}
.chatbot-member-content {flex-grow: 1;}
.chatbot-member-content h5 {font-weight: 600;font-size: 15px;line-height: 1.2;margin-bottom: 0;}
.chatbot-member-status {font-size: 13px;line-height: 1.3;font-family: "IBM Plex Sans", sans-serif;}
.chatbot-toggler-button {width: 24px;height: 24px;display: flex;align-items: center;justify-content: center;border: 0;background: transparent;}
.custom-input-group {width: 100%;padding: 5px 90px 5px 14px;background: #F5F5F5;border-radius: 10px;position: relative;}
.custom-input-group > input {width: 100%;background: transparent;border: 0;height: 100%;font-family: "IBM Plex Sans", sans-serif;font-size: 13px;line-height: 1.4;height: 38px;letter-spacing:-0.02em;}
.custom-input-group > input::placeholder{color: rgb(26 26 26 / 50%);}
.input-group-hold {position: absolute;right: 8px;top: 0;bottom: 0;gap:20px}
.input-group-prepend .input-group-text {border: 0;background: transparent;width: 20px;height: 18px;display: flex;align-items: center;justify-content: center;background: transparent;border: 0;padding: 0;}
.input-group-append .input-group-text {background: #47AD1D;border: 0;width: 34px;height: 34px;padding: 0;display: flex;align-items: center;justify-content: center;}
.chatbot-body {height: calc(100% - 73px);}
.chatbot-chat-wrapper {padding: 26px;overflow-y: auto;}
.chatbot-chat-input-row {padding: 18px 20px;}
.chatbot-wrapper .message {position: relative;flex-flow: column;}
.chatbot-wrapper .message .content {font-size: 12px;line-height: 1.4;padding: 8px 16px;position: relative;margin-bottom: 0;display: flex;color: #1A1A1A;font-family: "IBM Plex Sans", sans-serif;}
.chatbot-wrapper .send .content {border-radius: 5px !important;}
.chatbot-wrapper .message.send .content {background: #47AD1D !important;color: #fff;border-radius: 10px 0 10px 10px;align-self: flex-end;}
.chatbot-wrapper .message.send .content {background: #47AD1D !important;}
.chatbot-wrapper .send + .received, .chatbot-wrapper .received + .send {margin-top: 20px;}
.chatbot-wrapper .message.received {width: 66% !important;}
.chatbot-wrapper .message.received {padding-left: 40px;}
.chatbot-wrapper .message.received+.message.received,.chatbot-wrapper .message.send+.message.send{margin-top:15px;}
.chatbot-wrapper .message .profile-icon {width: 30px;height: 30px;border-radius: 50%;overflow: hidden;}
.chatbot-wrapper .profile-icon {position: absolute;left: 0;top: 0;}
.chatbot-wrapper .message.received .content {background: #F5F5F5 !important;position: relative;border-radius: 10px !important;}
.chatbot-wrapper .content p {margin-bottom: 0;}
.chatbot-wrapper .text-xs {font-size: 10px;line-height: 19px;color: rgb(51 51 51 / 60%);width: 100%;margin-top: -5px;}
.chatbot-wrapper .message.send {padding-right:40px;width: 66%;-ms-flex-pack: end !important;justify-content: flex-end !important;align-self: flex-end !important;padding-left: 15px;}
.chatbot-wrapper .send .text-xs {text-align: right;}
.chatbot-wrapper .message.send .profile-icon {left: auto;right: 0;}
.input-group-append .input-group-text > img {margin-left: 4px;}
.page-dropdown-menu .dropdown-menu {box-shadow: 0 4px 30px -2px rgb(0 0 0 / 16%);border: 0;border-radius: 6px;min-width: 116px;}
.page-dropdown-menu .dropdown-item {padding: 3px 8px;font-size: 15px;line-height: 1.5;color: #050505;font-family: "IBM Plex Sans", sans-serif;}
.page-dropdown-menu .dropdown-item.delete {color: #FF4242;}
.page-dropdown-menu .dropdown-item.delete:hover{background:transparent;}
.panel-main-content .add-new-chat-intro {max-width: 100%;}
.add-new-chat-widget {border-radius: 10px;box-shadow: 0 0 36px -2px rgb(0 0 0 / 6%);padding: 24px;}
.add-new-chat-intro {font-size: 15px;max-width: 245px;padding: 12px 0 40px;}
.add-new-chat-intro h1 {font-size: 32px;line-height: 1.3;font-weight: 600;margin-bottom: 13px;}
.add-new-chat-btn .btn {padding: 11px 24px;}
/* .hide-scrollbar {-ms-overflow-style: none; scrollbar-width: none;}
.hide-scrollbar::-webkit-scrollbar {display: none;} */
.new-chat-modal .modal-dialog {max-width: 550px;}
.new-chat-modal .modal-content {background: transparent;box-shadow: none;border: 0;border-radius: 0;}
.new-chat-modal-left {flex-grow: 1;background:#fff;border-radius:10px;}
.new-chat-modal-upper{padding:40px 40px 15px;}
.new-chat-modal-lower{padding:0 40px 30px;height:250px;overflow-y:auto;}
.close-modal {width: 28px;height: 28px;display: flex;align-items: center;justify-content: center;border: 0;background: transparent;padding: 4px;border-radius: 50%;}
.close-modal:hover {background: #000;}
.new-chat-modal-right {width: 32px;flex-shrink: 0;}
.search-member-img {width: 44px;height: 44px;border-radius: 50%;overflow: hidden;}
.search-member-img img {width: 100%;height: 100%;object-fit: cover;}
.search-member-box {padding: 10px 0;align-items: center;gap: 20px;cursor:pointer}
.search-member-content {font-size: 16px;line-height: 1.3;font-weight: 500;}
.search-member-item:not(:last-child) {border-bottom: 1px solid rgb(228 228 228 / 40%);}
.new-chat-modal-left h4 {font-size: 24px;line-height: 1.6;font-weight: 600;margin-bottom: 9px;}
.new-chat-modal-left .chat-search-pannel {margin-bottom: 20px;}
.search-label {font-size: 14px;color: #0F1419;margin-bottom: 10px;}
.search-member-item+.search-member-item {margin-top: 3px;}
@media (max-width:1199.98px){
.rcp-heading-row .rcp-heading-label {padding-left: 32px;}
.chatbot-wrapper {height: calc(100vh - 240px);}
}
@media (max-width:991.98px){}
@media (max-width:767.98px){}
@media (max-width:575.98px){
.new-chat-modal-upper {padding: 20px 20px 10px;}
.new-chat-modal-lower{padding:0 20px 20px;;}
.new-chat-modal-right {position: absolute;right: 0;top: -40px;height: 32px;display: flex;align-items: center;justify-content: center;}
}
/* CHAT END */


/* Education Page CSS Start */
.program-container { padding: 50px 0; background-color: var(--white); }
.program-heading-main h1 { color: var(--dark-green-color); font-size: 40px; font-family: "IBM Plex Sans", sans-serif; text-transform: uppercase; line-height: 1; margin-bottom: 0; }
.program-heading-main h1 span { font-size: 64px; display: block; }
.program-heading-main { padding-left: 23px; border-left: 3px solid var(--dark-green-color); width: 57%; height: 100%;}
.program-heading-text { width: 43%; font-size: 18px; text-align: end; line-height: 1.5; padding: 10px 0; }
.program-heading-text strong { display: block; font-weight: 600; }

.program-list { width: calc(100% + 30px); margin-left: -15px; margin-bottom: -30px; }
.program-item { padding: 0 15px; width: 50%; margin-bottom: 30px;}
* + .program-list { margin-top: 62px; }
.program-box-bg { position: absolute; left: 0; top: 0; height: 100%; width: 100%; }
.program-box { min-height: 258px; border-radius: 20px; overflow: hidden; }
.program-box-bg>img { width: 100%; height: 100%; object-fit: cover; }
.program-box-bg::after { content: ''; width: 100%; height: 100%; position: absolute; left: 0; top: 0; background: linear-gradient(90deg, rgba(71, 173, 29, 1) 25%, rgba(0, 0, 0, 0) 50%); }
.program-box-text { z-index: 1; width: 100%; padding: 20px 40px; }
.program-box-text h2 { font-size: 32px; font-weight: normal; color: var(--white); font-family: "IBM Plex Sans", sans-serif; line-height: 1.3; }
.program-box-text h2 strong { font-weight: 600; display: block; }
.program-box-text .btn-xxl { text-transform: uppercase; font-size: 14px; }

.species-description-box { background-color: var(--white); padding: 30px; }
.sd-box-image { width: 34.75%; height: 100%; border-radius: 9px; overflow: hidden; }
.sd-box-text { width: 65.25%; padding-left: 60px; }
.sd-box-image>img { width: 100%; height: 100%; object-fit: cover; } 
.sd-box-text h5, .sd-box-text h6, .sd-box-text h3 { font-family: "IBM Plex Sans", sans-serif; line-height: 1.3; }
.sd-box-text h5 { margin-bottom: 10px; font-weight: 600; }
.sd-box-text h3 { font-size: 32px; margin-bottom: 21px; }
.sd-name span { font-size: 20px; display: block; font-weight: 500; margin-bottom: 5px; }
.sd-name { font-size: 15px; }
.text-red { color: #FF4242; }
.sd-list { margin-top: 27px; }
.sd-item { width: 50%; font-size: 18px; margin-bottom: 21px; }
.sd-item h6 { margin-bottom: 0; }

.activity-instructions-box { padding: 30px; background: #fff; font-size: 18px; line-height: 1.5; }
.activity-instructions-box h4 { font-weight: 600; margin-bottom: 18px; }
.activity-instructions-box ol { padding-left: 27px; }
.activity-instructions-box ol li>ul { margin-left: -16px; margin-top: 4px; }
.activity-instructions-box ol li>ul li:not(:last-child) { margin-bottom: 5px; }
.btn-dark-green,.btn-dark-green:focus{color:var(--white);background-color:var(--dark-green-color);border:solid 1px var(--dark-green-color);}
.btn-dark-green:hover,.btn-dark-green:active{color:var(--white)!important;background-color:var(--green-color) !important;border-color:var(--green-color) !important;}
.aib-cta {margin-top: 40px;}

/* Sponsor Page */

.be-sponcer-content { display: flex; flex-flow: column; gap: 40px; }
.be-sponcer-container { padding: 44px 0 66px; background-color: #FFFFFF; }
.be-sponcer-header h4 { font-size: 24px; font-weight: 600; line-height: 1.4; margin-bottom: 20px; }
.be-sponcer-header { font-size: 18px; font-weight: 400; line-height: 1.5; }
.be-sponcer-body h4 {font-size: 24px;font-weight: 600;line-height: 1.4;margin-bottom: 32px;}
.bsb-box { font-size: 18px; font-weight: 400; line-height: 1.5; }
.bsb-box h5 { font-size: 18px; font-weight: 600; line-height: 1.4; margin-bottom: 10px; }
.bsb-btn{ margin-top: 40px; }
.bsb-btn .btn { min-width: 130px; padding: 14px 17px; height: auto; font-size: 16px; font-weight: 500; }
.psb-item {position: relative;padding-left: 31px;margin-bottom: 32px;}
.psb-item:after { content: ''; position: absolute; top: 4px; left: 0; width: 17px; height: 17px; background-color: #47AD1D; border-radius: 4px; }
.sponcer-species-select { padding: 24px; border-radius: 10px; background-color: #F5F5F5; margin-bottom: 20px; }
.sss-upper {display: flex;flex-wrap: wrap;align-items: center;justify-content: space-between;margin-bottom: 15px;gap: 10px;}
.sss-upper h5 { font-size: 18px; font-weight: 600; line-height: 1.4; margin-bottom: 0; }
.sss-upper.btn { min-width: 83px; padding: 9px 16px; height: auto; font-size: 16px; font-weight: 500; }
.sponcer-page-example-content { padding: 24px; border-radius: 10px; background-color: #f5f5f5; }
.sponcer-page-example-content + .sponcer-page-example-content { margin-top: 20px;}
.spec-img img { width: 100%; height: 100%; object-fit: cover; }
.spec-img { margin-bottom: 30px; }
.spec-btn .btn { min-width: 140px; padding: 9px 24px; height: auto; font-size: 16px; font-weight: 500; }


.corporate-sponcers-container { padding: 80px 0; }
.individual-sponcers-container { padding: 80px 0; }
.sponcer-box { padding: 20px; display: flex; align-items: center; background-color: #FFFFFF; border-radius: 10px; }
.sponcer-box-left { display: flex; align-items: center; gap: 30px; width: 60%; }
.sbl-img { width: 120px; height: 120px; flex-shrink: 0; border: 1px solid #E4E4E4; border-radius: 10px; overflow: hidden; }
.sbl-img img { width: 100%; height: 100%; object-fit: cover; }
.sponcer-list { display: flex; flex-flow: column; gap: 20px; }
.sbl-text h6 { font-size: 20px; font-weight: 600; line-height: 1.5; margin-bottom: 10px; text-transform: uppercase; }
.sbl-text { font-size: 15px; font-weight: 400; line-height: 1.6; padding-right: 60px; }
.sponcer-box-right {display: flex;justify-content: space-between;width: 40%;gap: 30px;}
.sbr-species-head h6 { font-size: 16px; font-weight: 600; line-height: 1.2; margin-bottom: 20px; }
.sbr-species-img-list { display: flex; align-items: center; }
.sbr-species-img-list h5 { font-size: 16px; font-weight: 600; line-height: 1.3; margin-bottom: 0; color: #47AD1D; margin-left: 19px; }
.sbr-species-img-list h6 {cursor: pointer;font-size: 24px; font-weight: 600; line-height: 1.3; margin-bottom: 0; color: #47AD1D; border: 5px solid #fff; border-radius: 50%; background-color: #fff; }
.sbr-species-img-box {cursor: pointer; width: 70px; height: 70px; border-radius: 50%; border: 5px solid #FFFFFF; overflow: hidden;}
.sbr-species-img-box img { width: 100%; height: 100%; object-fit: cover; }
.sbr-species-img-list li + li { margin-left: -22px; }
/* .sbr-species-img-list li  h6 { margin-left: -15px; } */
.sbr-cta {display: flex;justify-content: center;flex-flow: column;gap: 10px;max-width: 170px;}
.sbr-cta .btn { font-family: 'IBM Plex Sans'; min-width: 170px; padding: 6px 14px; height: auto; font-size: 16px; font-weight: 400; }
.sbr-cta .btn + .btn { margin: 0; }

.be-sponcer-detail { padding: 24px; background-color: #F5F5F5; border-radius: 10px; gap: 40px; }
.bsd-left-img { width: 175px; height: 175px; border-radius: 10px; overflow: hidden; }
.bsd-left-img img { width: 100%; height: 100%; object-fit: cover; }
.bsd-text h6 { font-size: 16px; font-weight: 600; line-height: 1.4; color: #FF4242; margin-bottom: 15px; }
.bsd-text h4 { font-size: 32px; font-weight: 600; line-height: 1.4; margin-bottom: 5px; }
.bsd-origin-list { margin-top: auto; gap: 6px; }
.bsd-center { display: flex; flex-flow: column; }
.bsd-right-img {width: 36px;height: 30px;margin-top: -55px;}
.bsd-right-img img { width: 100%; height: 100%; object-fit: cover; }
.bsd-right.ms-auto { display: flex; align-items: center; }
.bsd-origin-box { position: relative; overflow: hidden; padding: 8px 20px; border-radius: 6px; color: currentColor; font-size: 16px; font-weight: 500; line-height: 1.1; }
.bsd-origin-box:before { content: ''; width: 100%; height: 100%; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background: currentColor; opacity: 0.1; }

.sss-upper .btn {min-width: 84px !important;padding: 9px 16px;}
.custom-popover.popover{background-color:#1E1E1E!important;border-radius:9px!important;border:0!important;    font-family: "IBM Plex Sans", sans-serif;}
.custom-popover .popover-body {color: #fff !important;font-size: 16px;line-height: 1;padding: 7px 17px 10px;text-transform: capitalize;}
.custom-popover-title+.custom-popover-title {margin-top: 5px;}
.custom-popover.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,.custom-popover.bs-popover-top>.popover-arrow::after {border-top-color: #1E1E1E;}


.form-floating.custom-form-floating.has-select-button > .form-control{padding-right:60px;}
button.select-button-box {border: 0;position: absolute;right: 0;top: 0;width: 50px;height: 60px;background: transparent;font-size: 16px;font-weight: 600;display: flex;align-items: flex-end;padding-bottom: 8px;color: #47AD1D;}
.custom-form-floating.form-floating > .form-control{height:60px;line-height:1;background-color:transparent;border-radius:0;border-top:none;border-left:none;border-right:none;border-color: #E4E4E4;padding-left:0;font-size:14px;color: #000;box-shadow: none;}
.custom-form-floating.form-floating > label{font-size: 16px;padding: 27px 0 0 0;border: 0;color: rgb(0 0 0 / 60%);font-weight: 400;opacity: 1!important;}
.custom-form-floating.form-floating > .form-control:focus~label,
.custom-form-floating.form-floating > .form-control:not(:placeholder-shown)~label,
.custom-form-floating.form-floating >.form-select~label{opacity:1;transform: scale(.85) translateY(-1.7rem) translateX(0rem);}
.form-floating.custom-form-floating{margin-bottom: 0;}
.form-floating:not(.form-control:disabled)::before{background-color:transparent;}
.page-title1 {margin-bottom: 35px;}
.page-title1 h2 {font-size: 36px;line-height: 1.2;font-weight: 600;margin-bottom:0;}
.btn-dark-green-outline,.btn-dark-green-outline:focus{background-color:transparent;border:1px solid var(--dark-green-color);color:var(--dark-green-color);}
.btn-dark-green-outline:hover,.btn-dark-green-outline:active{color:var(--white)!important;background-color:var(--dark-green-color)!important;border-color:var(--dark-green-color)!important;}

.sustainability-content-container.large-padd{padding:65px 0;}
.post-button-box {cursor: pointer;}
.btn.btn-link { font-size: 14px; font-weight: 500; line-height: 18.2px; padding: 0; min-width: auto; height: auto; color:var(--green-color); }
.individual-hero-container {color: var(--white);padding: 215px 0 0px;position: relative;z-index: 1;}
.individual-hero-bg {position: absolute;width: 100%;height: 100%;object-fit: cover;top: 0;left: 0;background-position: center;background-repeat: no-repeat;background-size: cover;z-index: -1;max-height: 316px;}
.individual-hero-bg:after {content: '';position: absolute;top: 0;left: 0;width: 100%;height: 100%;background: linear-gradient(90deg, rgba(26, 26, 26, 1) 0%, rgba(26, 26, 26, 1) 65%);opacity: 0.49;z-index:2;}
.individual-hero-bg:before {content: '';position: absolute;top: 0;left: 0;width: 100%;height: 100%;background: rgba(0,0,0,0.2);z-index:1;}

.individual-hero-content {gap: 42px;}
.ihc-left-img { width: 200px; height: 200px; box-shadow: 0px 4px 8.8px -1px #00000024; border-radius: 16px; overflow: hidden; }
.ihc-left-img img { width: 100%; height: 100%; object-fit: cover; }
.ihc-left { flex-shrink: 0; }
.ihc-body-btn { gap: 0px; flex-wrap: wrap; }
.ihc-right { padding-top: 17px; width: 100%; }
.ihc-head h3 { font-size: 40px; font-weight: 600; line-height: 1.5;  margin-bottom: 57px;color: var(--white);}
.ihc-body { display: flex; color: var(--black); width: 100%; justify-content: space-between; gap: 20px; flex-wrap: wrap;}
.ihc-body-left {font-size: 16px; font-weight: 400; line-height: 1.5; max-width: 700px; }
.ihc-body-right { display: flex; gap: 8px; flex-wrap: wrap;}
.ihc-body-right-img { width: 46px; height: 46px; border-radius: 50%; overflow: hidden; margin-bottom: 8px; }
.ihc-body-right-img img { width: 100%; height: 100%; object-fit: cover; }
.ihc-ihc-body-right-text {font-size: 12px; font-weight: 500; line-height: 14px; text-align: center; }
.ihc-body-right-box { max-width: 72px; display: flex; flex-direction: column; align-items: center; }
.species-content1 { margin-bottom: 20px; }
.swc-list { width: calc(100% + 20px); margin-left: -10px; margin-bottom: -20px; display: flex; flex-wrap: wrap; }
.swc-item { width: 33.33%; padding: 0 10px; margin-bottom: 20px; }
.swc-box { background-color: #fff; padding: 24px; border-radius: 10px;  font-size: 15px; font-weight: 400; line-height: 1.5; height: 100%;}
.swc-box-header {position: relative;margin-bottom: 12px; padding-right:45px;}
.dropend {position: absolute;right: 0;top: 0;width: 30px;height: 30px;display: flex;align-items: center;justify-content: center;}
.swc-box-header h4 {  font-size: 18px; font-weight: 600; line-height: 1.38; margin-bottom:0; }
.dropend button {width:100%;height:100%; background: transparent; border: 0;display:block; }
.dropend button:after{content:none!important;}
.sc-left-img { width: 120px; height: 120px; border-radius: 10px; overflow: hidden; box-shadow: -20px 4px 90px -2px #0000000F; }
.sc-left-img img { width: 100%; height: 100%; object-fit: cover; }
.species-content1{ padding: 20px; background-color: #fff; gap: 30px; border-radius: 10px; }
.sc-center { padding: 13px 0; }
.sc-right-text h4 {font-size: 24px; font-weight: 600; line-height: 31.2px; margin-bottom: 4px; }
.sc-origin-list { gap: 6px; margin-top: 10px;}
.sc-origin-box {position:relative;overflow:hidden; padding: 8px; border-radius: 10px; color:currentColor;  font-size: 12px; font-weight: 500; line-height: 1.1; }
.sc-origin-box:before {content: '';width: 100%;height: 100%;position: absolute;left: 0;right: 0;top: 0;bottom: 0;background: currentColor;opacity:0.1;}
.sc-origin-box>p{position:relative;z-index:1;margin-bottom: 0;}

.sc-text h4 { font-size: 24px; font-weight: 600; line-height: 1.3; letter-spacing: -0.02em; margin-bottom: 4px; }
.sc-text {  font-size: 14px; font-weight: 400; line-height: 21px; }
.sc-right-img { width: 36px; height: 30px; flex-shrink: 0; margin-bottom: 16px; }
.sc-right { display: flex; flex-direction: column; align-items: center; text-align: center;  font-size: 14px; font-weight: 500; line-height: 1.5; color: #FF4242; }
.swc-box-body {display:flex;flex-flow:column;}
.swc-box-body p {display: -webkit-box;-webkit-line-clamp: 4;-webkit-box-orient: vertical;overflow: hidden;}
.swc-box-body p:not(:last-child) {margin-bottom: 10px;}
.swc-btn { margin-top: 30px; }
.swc-btn .btn { font-size: 16px; padding: 15px 15px; height: 48px; }
.species-content-addposts { display: flex; align-items: center; gap: 30px; padding: 20px 24px; border-radius: 10px; background-color: var(--white); }
.sc-addpost-left-img { width: 68px; height: 68px; }
.sc-addpost-left-img img { width: 100%; height: 100%; object-fit: cover; }
.sc-addpost-right { display: flex; justify-content: space-between; width: 100%; align-items: center; gap: 20px;}
.sc-addpost-right h4 { font-family: IBM Plex Sans; font-size: 20px; font-weight: 500; line-height: 1.5; margin-bottom: 0; }
.spacies-widget-row+.spacies-widget-row {margin-top: 30px;border-top: 1px solid rgb(0 0 0 / 20%);padding-top: 30px;}
.post-page { padding: 91px; box-shadow: 0px 0px 36px -2px #0000000F; background-color: var(--white); display: flex; align-items: center; justify-content: center; flex-flow: column; }
.post-page-img { width: 105px; height: 105px; margin-bottom: 25px; }
.post-page-img img { width: 100%; height: 100%; object-fit: cover; }
.post-page-content {font-size: 18px; font-weight: 400; line-height: 1.5; max-width: 225px; text-align: center; }
.ihc-body-btn .btn {min-width: 130px;padding: 14px 24px;height: auto;font-size: 16px;font-weight: 500;}
.light-grey-bg{background:#F4F5F7;}
.page-title {margin-bottom: 35px;}
.page-title h2 {font-size: 36px;line-height: 1.2;font-weight: 600;margin-bottom:0;}
.masonry {width: calc(100% + 20px);margin-left: -10px;display: flex;flex-wrap: wrap;}
.masonry-item {width: 33.33%;padding: 10px;}
.masonry-item img {width: 100%;height: auto;display: block;}
.post-img-box {flex-shrink: 0;width: 48px;}
.post-content-box {width: calc(100% - 48px);flex-grow: 1;padding-left: 12px;font-size:14px;line-height:1.5;}
.post-img {width: 48px;height: 48px;border: 1px solid rgb(228 228 228 / 60%);border-radius: 6px;overflow: hidden;}
.post-box {background: #fff;padding: 16px;border-radius: 10px;overflow: hidden;}
.post-tags span {color: var(--green-color);}
.post-name h5 {font-size: 16px;font-weight: 600;margin-bottom: 0;}
.post-dot {width: 3px;height: 3px;background: currentColor;border-radius: 50rem;opacity: 0.5;}
.link-btn-link:hover {text-decoration: underline;}
.link-btn-link {color: var(--green-color);}
.post-tags {margin-top: 24px;}
.post-bottom-img {position: relative;padding-bottom: 129%;border-radius: 10px;overflow: hidden;margin-top: 10px;margin-bottom: 24px;}
.post-bottom-img img {width: 100%;height: 100%;position: absolute;left: 0;right: 0;top: 0;bottom: 0;object-fit: cover;}


.content-container.sponsor-form-container {padding: 60px 0;}
.sf-info-content {font-size: 18px;line-height: 1.5;}
.sf-form-widget-row+.sf-form-widget-row {margin-top: 40px;padding-top:40px;border-top:1px solid rgba(0,0,0,0.1);}

.sf-raio-group{gap:60px;}

.fc-title-box {font-size: 22px;color: #1A1A1A;}
.fc-subtitle {font-size: 18px;color: #8D8D8D;margin-top: 12px;}

.form-check.rounded-form-check {padding-left: 35px;}
.form-check.rounded-form-check .form-check-input {margin-left: -35px;}
.rounded-form-check .form-check-input {width: 21px;height: 21px;border-color: #E4E4E4;background-color: #fff;margin:0}
.rounded-form-check .form-check-input:checked{border-color: #47AD1D;background-color:#47AD1D;}
.form-check-input:focus{box-shadow:none!important}

.file-upload-left {width: 215px;}
.file-upload-right {width: calc(100% - 215px);padding-left: 17%;}
.file-upload {width: 40px;height: 40px;background: #fff;border-radius: 50%;box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%);position: absolute;top: -16px;right: -10px;z-index: 2;overflow: hidden;}
.file-upload-box,.color-picker-wrapper {gap: 35px;}
.file-upload-label,.color-picker-label{font-size: 20px;}
.file-upload-right .file-uploader-button{width:100%;}
.file-uploader-button {width: 208px;position: relative;height: 208px;border-radius: 26px;border: 3px solid #E4E4E4;}
.fu-img-box {width: 100%;height: 100%;position: absolute;left: 0;right: 0;top: 0;bottom: 0;border-radius: 24px;overflow: hidden;}
.fu-img-box>img {width: 100%;height: 100%;object-fit: cover;}
#fileInput{width: 100%;height: 100%;position: absolute;left: 0;right: 0;top: 0;bottom: 0;opacity:0;}

.gap-40{gap:40px;}
.color-picker-row {gap: 30px;}
.picker {border: 1px solid #C4C4C4;border-radius: 9px;background: #fff;height: 40px;align-items: center;padding: 10px 5px 10px 14px;}
.picker-input>input {height: 100%;border: 0;border-radius: 8px 0 0 8px;background: #fff;padding: 0;}
.picker-trigger .form-control-color {border: 0;padding: 0;border-radius: 7px;width: 30px;height: 32px;}
.sf-w-form-list {width: calc(100% + 40px);margin-left: -20px;margin-bottom:-30px;}
.sf-w-form-item {width: 33.33%;padding: 0 20px;flex-grow:1;margin-bottom:30px;}
.custom-form-floating.form-floating>.form-select {border-top: none;border-left: none;border-right: none;border-radius: 0;padding-left: 0;background-color:transparent;}
.custom-form-floating.form-floating textarea{height:100px!important;}
.selected-animals-list {gap: 10px;}
.selected-animals-item {width: auto;flex-grow: 1;}
.pick-animals-select {width: 100%;max-width: 388px;}
.pick-animals-wrapper {background-color: #F5F5F5;border-radius: 10px;overflow: hidden;padding:30px 60px 30px 30px;}
.selected-animals-box {background-color: #47AD1D;border-radius: 10px;overflow: hidden;font-family: 'Poppins', sans-serif;font-size: 18px;font-weight: 500;color: #fff;position: relative;}
.selected-animals-img {width: 60px;height: 60px;border-radius: 4px;overflow: hidden;position: relative;}
.selected-animals-img img {width: 100%;height: 100%;position: absolute;left: 0;right: 0;top: 0;bottom: 0;object-fit: cover;}
.close-sf-btn{width: 24px;height: 24px;font-size: 24px;border: 0;background: transparent;align-self: center;position: absolute;right: 18px;top: 50%;transform: translateY(-50%);}
.selected-animals-name {display: flex;align-items: center;padding: 10px 50px 10px 20px;}
.pick-animals-select {width: 100%;max-width: 388px;}
.selected-animals-block {margin-top: 30px;}
.selected-label {margin-bottom: 16px;font-size: 16px;font-weight: 500;font-family: 'Poppins', sans-serif;}
.sf-btn-wrap {margin-top: 60px;}
.btn.large-btn {min-width: 288px;font-size: 24px;font-weight: 500;padding: 20px 53px;}


.sponsor-form-container .custom-form-floating.form-floating.xl-size > .form-control,
.sponsor-form-container .custom-form-floating.form-floating.xl-size>.form-select{font-size:20px;}
.sponsor-form-container .custom-form-floating.form-floating.xl-size > label{font-size:15px;font-weight:500;}
.sponsor-form-container .form-select:focus {box-shadow:none!important;border-color:#E4E4E4!important;}
@media (max-width:1199.98px){
.program-heading-main h1 { font-size: 34px; }
.program-heading-main { padding-right: 30px; }
.program-heading-main h1 span { font-size: 54px; }
.program-heading-text { font-size: 16px; }
.sbl-text { padding-right: 30px; }
.sf-info-content {font-size: 16px;}
}
@media (max-width:991.98px){
.corporate-sponcers-container {padding: 40px 0;}
.sponcer-box { flex-flow: column; align-items: normal; padding: 15px; gap: 15px; }
.sponcer-box-right { width: 100%; }
.sponcer-box-left { gap: 20px; width: 100%; }
.program-heading-main { width: 100%; padding-right: 0; }
.program-heading-text { padding: 0; text-align: start; width: 100%; margin-top: 20px; }
.program-box-text { padding: 20px; }
.program-box-text h2 { font-size: 28px; }
.sd-box-image { width: 100%; max-width: 410px; }
.sd-box-text { width: 100%; padding: 0; margin-top: 30px; }

.individual-hero-content { gap: 20px; }
.ihc-head h3 { font-size: 32px; margin-bottom: 25px; }
.ihc-right { padding-top: 37px; }
.ihc-left-img { width: 170px; height: 170px; }
.sc-text h4 { font-size: 20px; }
.swc-box-header h4 { font-size: 16px; margin-bottom: 5px; }
.swc-box-body { font-size: 14px; }
.swc-box { padding: 15px; }
.page-title h2 {font-size: 26px;}
.swc-item {width: 50%;}

.be-sponcer-detail { gap: 20px; }
.be-sponcer-header h4 { font-size: 20px; margin-bottom: 10px; }
.be-sponcer-header { font-size: 16px; }
.be-sponcer-body h4 { font-size: 20px; margin-bottom: 20px; }
.bsd-left-img { width: 110px; height: 110px; }
.sbl-text { font-size: 13px; padding-right: 0; }
.sbl-text h6 {font-size: 18px; }
.sbr-species-head h6 { font-size: 14px; margin-bottom: 10px; }
.sbr-species-img-box { width: 55px; height: 55px; }
.sbr-species-img-list h6 { font-size: 20px; }
.sbr-species-img-list h5 { font-size: 14px; margin-left: 10px; }
.sbr-species-img-list li + li {margin-left: -15px;}
.bsd-text h6 { font-size: 14px; margin-bottom: 8px; }
.bsd-text h4 { font-size: 20px; }
.bsd-origin-box { font-size: 14px; padding: 6px 12px; }
.psb-item { margin-bottom: 20px; }
.bsb-box h5 { font-size: 16px; margin-bottom: 5px; }
.bsb-box { font-size: 16px; }
.sss-upper h5 { font-size: 16px; }
.selected-animals-item {width: 100%;flex-grow: 1;}
.pick-animals-wrapper {padding: 30px;}
.sf-raio-group {gap: 30px;}
.sf-w-form-item {width: 100%;}
.sf-btn-wrap {margin-top: 40px;display: flex;flex-flow: column;gap: 24px;}
.sf-btn-wrap .btn+.btn{margin-left:0;}
.file-upload-right {padding-left: 10%;}
.sponsor-form-container .custom-form-floating.form-floating.xl-size > .form-control, .sponsor-form-container.custom-form-floating.form-floating.xl-size>.form-select {font-size: 16px;}
}
@media (max-width:767.98px){
* + .program-list {margin-top: 32px;}
.program-heading-main h1 { font-size: 28px; }
.program-heading-main h1 span { font-size: 44px; }
.program-item { width: 100%; margin-bottom: 20px; }
.program-list { margin-bottom: -20px; }
.aib-cta { flex-flow: column; gap: 20px; }
.aib-cta .btn { margin: 0 !important; width: 100%; }

.ihc-left-img { width: 150px; height: 150px; }
.masonry-item {width: 50%;}
.swc-item {width: 100%;}
.content-container.large-padd {padding: 45px 0;}
.species-content-addposts { gap: 20px; }
.sbr-cta .btn { font-size: 14px; padding: 6px 10px; }
.be-sponcer-header h4 { font-size: 18px; }
.be-sponcer-header { font-size: 14px; }
.be-sponcer-body h4 { font-size: 18px; }
.psb-item:after { width: 13px; height: 13px; }
.bsb-box h5 { font-size: 14px; }
.bsb-box { font-size: 14px; }
.bsb-btn .btn { font-size: 14px; }
.sponcer-species-select { margin-top: 25px; }
.spec-btn .btn { font-size: 14px; }
.file-upload-right {padding-left: 0;width: 100%;margin-top: 24px;}
.file-upload-box, .color-picker-wrapper {gap: 24px;}
.sf-form-widget-row+.sf-form-widget-row {margin-top: 30px;padding-top: 30px;}
}
@media (max-width:575.98px){
.species-description-box, .activity-instructions-box { padding: 20px; }
.individual-hero-container { padding: 240px 0 0px; }
.individual-hero-content{ flex-flow: column; gap: 10px; }
.ihc-right { padding-top: 0; }
.ihc-head h3 { font-size: 26px; margin-bottom: 10px; color: var(--black); }
.ihc-body-left { font-size: 14px; }
.species-content-addposts { flex-flow: column; gap: 10px; }
.sc-addpost-right { flex-direction: column; gap: 10px; }
.ac-addpost-btn { width: 100%; }
.ac-addpost-btn a { width: 100%; }
.post-page { padding: 70px; }
.ihc-left-img { width: 130px; height: 130px; }
.be-sponcer-detail { flex-flow: column; }
.bsd-right { margin-left: 0 !important; }
.bsd-right-img { margin: 0; }
.species-content1 { flex-flow: column; align-items: start !important; gap: 10px; }
.sc-right { margin: 0 !important; }
.masonry-item {width: 100%;}
.sponcer-box-left { flex-flow: column; align-items: start; }
.sponcer-box-right { gap: 15px; flex-wrap: wrap; }
.sbr-cta {max-width: 100%;width: 100%;}
}
